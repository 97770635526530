import { v4 as uuidv4 } from "uuid";
import { TweenLite } from "gsap/all";

import PixiTools from "../../../../helpers/pixi-tools";
import LoadManager from "../api/load-manager";

// This class is a logical tile that manages that tile's visuals in a level.

const Tile = function () {
  // Note: We use a unique ID for the texture to avoid cache reuse issues when
  // multiple instances of TiledMap with the same tileset(s) exist on the same page.
  this.id = uuidv4();
  this.isVisible = false;
  this.sprite = null;
  this.state = Tile.State.UNLOADED;
  this.loadManager = new LoadManager(this.performLoad.bind(this), this.performUnload.bind(this));
};

Tile.State = {
  UNLOADED: 0,
  LOADED_INVISIBLE: 1,
  LOADED_VISIBLE: 2,
};

Tile.prototype.performLoad = function (done) {
  if (this.state === Tile.State.UNLOADED && this.imageUrl) {
    PixiTools.createSprite(this.imageUrl).then((sprite) => this.setSprite(sprite, done).bind(this));
  }
};

Tile.prototype.setSprite = function (sprite, done) {
  sprite.position.set(this.position.x, this.position.y);
  sprite.visible = this.isVisible;

  this.sprite = sprite;

  this.sprite.alpha = 0;
  this.destinationContainer.addChild(sprite);
  this.state = Tile.State.LOADED_VISIBLE;

  TweenLite.to(this.sprite, 0.5, { alpha: 1, onComplete: done });
};

Tile.prototype.performUnload = function (done) {
  this.unload();
  done();
};

Tile.prototype.unload = function () {
  if (!this.sprite) return;
  TweenLite.killTweensOf(this.sprite, false);

  this.destinationContainer.removeChild(this.sprite);
  this.sprite.destroy(true);
  this.state = Tile.State.UNLOADED;
};

Tile.prototype.setVisible = function (visible) {
  this.isVisible = visible; // Store the fact for future reference
  if (this.sprite) this.sprite.visible = this.isVisible; // Do it now if possible
};

// Expose a function to control the tile's visibility.
// Setting the visibility will automatically load or unload the tile's visuals.
// This function is idempotent. `load()` and `unload()` should never be called externally.
Tile.prototype.refresh = function (wantedState) {
  switch (wantedState) {
    case Tile.State.LOADED_INVISIBLE:
      this.setVisible(false);
      this.loadManager.load();
      break;
    case Tile.State.LOADED_VISIBLE:
      this.setVisible(true);
      this.loadManager.load();
      break;
    case Tile.State.UNLOADED:
      this.setVisible(false);
      this.loadManager.unload();
      break;
  }
};

export default Tile;
