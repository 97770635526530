import _ from "lodash";
import * as PIXI from "pixi.js";

import Point from "../../../../../../types/point";
import PixiTools from "../../../../../../helpers/pixi-tools";
import TiledMap from "../../../index";
import Size from "../../../../../../types/size";
import Clipboard from "../../../../../../helpers/clipboard";
import Toast from "../../../../../../helpers/toast";
import Audio from "../../../../../../helpers/audio";
import Env from "../../../../../../helpers/env";
import resource from "../../../../../../helpers/resource";
import { TimelineLite, TimelineMax, Bounce } from "gsap/all";

export default class Pin {
  constructor(id, imageUrl, position = null, options = {}) {
    this.id = id;
    this.imageUrl = imageUrl;
    this.debugTitle = id.substring(0, 8);
    this.debug = false;
    this.editing = false;
    this.selected = null;
    this.sound = null;
    this.muted = null;
    this.scale = 1;

    // External API (can also be set through constructor options)
    this.anchor = new Point(0.5, 0.5);
    this.interactive = true;
    this.position = position || new Point(0.5, 0.5);
    this.scaleMultiplier = 1.0; // Set this to alter the scale of the pin by a fixed amount
    this.animation = null; // When null, inherits the PinsDecoration pinAnimation
    this.clickAnimation = null; // When null, inherits the PinsDecoration pinClickAnimation
    this.dynamicScale = null; // When null, inherits the PinsDecoration pinDynamicScale
    this.dynamicAlpha = null; // When null, inherits the PinsDecoration pinDynamicAlpha
    this.hidden = false;

    // Applies constructor options directly on this instance (after defaults)
    _.assign(this, options);
  }

  setup(info, options, onPinClick) {
    this.info = info;
    this.onPinClick = onPinClick;

    const absolutePosition = TiledMap.DecorationHelper.toAbsolutePosition(this.position, info.containerSize);

    // Create a container to contain all of the pin's visuals
    const displayObject = new PIXI.Container();
    displayObject.position.set(absolutePosition.x, absolutePosition.y);
    displayObject.scale.set(this.scale, this.scale);

    // Make non-interactive if requested
    if (!this.interactive) displayObject.forceNonInteractive = true;

    // A visual indicator of the pin's anchor in debug mode
    this.setupPinAnchorDebugVisualIndicator(displayObject);

    // A title to distinguish the node
    this.setupPinDebugTitle(displayObject);

    // Load and configure the pin's sprite
    if (this.imageUrl) {
      PixiTools.createSprite(this.imageUrl).then((sprite) => {
        this.pinSprite = sprite;

        sprite.anchor.set(this.anchor.x, this.anchor.y);
        displayObject.addChildAt(sprite, 0);

        this.setupAnimation(sprite, this.animation || options.pinAnimation);

        // Setup a click handler matching the sprite's bounds
        const clickAction = () => {
          const sound = this.sound || (Env.isDesktop && resource("audio/ripple-button.mp3"));
          if (sound) Audio.discrete("buttons").play(sound, { muted: this.muted });
          this.animatePinClick(this.clickAnimation || options.pinClickAnimation);
          this.onPinClick(this);
        };
        const altClickAction = () => {
          const absolutePinPosition = new Point(displayObject.position.x, displayObject.position.y);
          const relativePinPosition = {
            x: absolutePinPosition.x / info.containerSize.width + 0.5,
            y: absolutePinPosition.y / info.containerSize.height + 0.5,
          };
          Clipboard.copy(JSON.stringify(relativePinPosition));
          Toast.info("Pin position copied to clipboard!");
        };
        TiledMap.DecorationHelper.configureClickHandler(
          sprite,
          () => clickAction(),
          () => altClickAction()
        );

        // Setup dragging (for data entry purposes)
        if (Env.isDesktop) this.setupDragging(displayObject);
      });
    }

    this.displayObject = displayObject;
  }

  setupPinAnchorDebugVisualIndicator(pinContainer) {
    PixiTools.createRectangle(new Size(10, 10), 0xffffff).then((sprite) => {
      this.pinAnchorBorderSprite = sprite;
      sprite.alpha = this.debug ? 1 : 0;
      pinContainer.addChild(sprite);
    });
    PixiTools.createRectangle(new Size(5, 5), 0x111111).then((sprite) => {
      this.pinAnchorCenterSprite = sprite;
      sprite.alpha = this.debug ? 1 : 0;
      pinContainer.addChild(sprite);
    });
  }

  setupPinDebugTitle(pinContainer) {
    const text = new PIXI.Text(this.debugTitle, {
      fontSize: 15,
      fill: "white",
      align: "center",
      dropShadow: true,
      dropShadowColor: "black",
      dropShadowDistance: 1,
      dropShadowBlur: 5,
    });

    text.forceNonInteractive = true;
    text.anchor.set(0.5, -0.6);
    text.alpha = this.editing ? 1 : 0;

    this.pinDebugTitleText = text;
    pinContainer.addChild(text);
  }

  setupDragging(pinContainer) {
    pinContainer.interactive = true;

    let drag = false;
    let dragStartPoint = null;
    let dragOffset = { x: 0, y: 0 };

    const onDown = (event) => {
      if (!event.data.originalEvent.altKey) return;
      dragStartPoint = pinContainer.position;
      dragOffset = event.data.getLocalPosition(pinContainer);
      drag = true;
    };
    const onMove = (event) => {
      if (!drag) return;
      const newPosition = event.data.getLocalPosition(pinContainer.parent);
      const zoomCompensationRatio = this.info.maxZoom / this.info.currentZoom;
      const newX = dragStartPoint.x + (newPosition.x - dragStartPoint.x) - dragOffset.x * zoomCompensationRatio;
      const newY = dragStartPoint.y + (newPosition.y - dragStartPoint.y) - dragOffset.y * zoomCompensationRatio;
      pinContainer.position.set(newX, newY);

      this.position = TiledMap.DecorationHelper.toNormalizedPosition(new Point(newX, newY), this.info.containerSize);

      this.positionChanged();
    };
    const onUp = (event) => (drag = false);

    pinContainer.on("pointerdown", onDown);
    pinContainer.on("pointermove", onMove);
    pinContainer.on("pointerup", onUp);
    pinContainer.on("pointerupoutside", onUp);
  }

  setupAnimation(sprite, animationName) {
    switch (animationName) {
      case "none":
        break;
      case "pulse": {
        const timeline = new TimelineMax({ repeat: -1 });
        timeline.to(sprite, 0.5, { alpha: 0.5 });
        timeline.to(sprite, 0.5, { alpha: 1.0 });
        break;
      }
      default:
        throw new Error(`Unsupported pin animation '${animationName}'`);
    }
  }

  refresh(info, options) {
    if (!this.displayObject || !info) return;
    this.info = info;

    const baseScale = this.getBaseScale(info, options);
    const { scaleMultiplier, alpha } = this.updateForSelected(this.selected, options);
    const actualScale = baseScale * scaleMultiplier * this.scaleMultiplier;

    const absolutePosition = TiledMap.DecorationHelper.toAbsolutePosition(this.position, info.containerSize);
    this.displayObject.position.set(absolutePosition.x, absolutePosition.y);
    this.displayObject.scale.set(actualScale, actualScale);
    this.displayObject.alpha = this.hidden ? 0 : alpha * this.getDynamicAlpha(info, options);

    if (this.pinAnchorBorderSprite) this.pinAnchorBorderSprite.alpha = this.debug ? 1 : 0;
    if (this.pinAnchorCenterSprite) this.pinAnchorCenterSprite.alpha = this.debug ? 1 : 0;
    if (this.pinDebugTitleText) this.pinDebugTitleText.alpha = this.editing ? 1 : 0;
  }

  getBaseScale(info, options) {
    return this.dynamicScale
      ? this.dynamicScale(info) // Use our own pin scale by default
      : options.pinDynamicScale(info); // Fallback to the PinsDecoration option
  }

  getDynamicAlpha(info, options) {
    return this.dynamicAlpha ? this.dynamicAlpha(info) : options.pinDynamicAlpha(info);
  }

  updateForSelected(selected, options) {
    const neutralAlpha = options.pinNeutralAlpha;
    const selectedAlpha = options.pinSelectedAlpha;
    const unselectedAlpha = options.pinUnselectedAlpha;
    return {
      scaleMultiplier: 1,
      alpha: selected === null ? neutralAlpha : selected ? selectedAlpha || neutralAlpha : unselectedAlpha,
    };
  }

  animatePinClick(animationName) {
    switch (animationName) {
      case "none":
        break;
      case "scale-up": {
        const timeline = new TimelineLite();
        timeline.to(this.pinSprite.scale, 0.2, { x: 1.5, y: 1.5 });
        timeline.to(this.pinSprite.scale, 0.2, { x: 1.0, y: 1.0 });
        break;
      }
      case "jump": {
        const timeline = new TimelineLite();
        timeline.to(this.pinSprite.position, 0.2, { y: -20 });
        timeline.to(this.pinSprite.position, 0.2, { y: 0, ease: Bounce.easeOut });
        break;
      }
      default:
        throw new Error(`Unsupported pin click animation '${animationName}'`);
    }
  }

  positionChanged() {
    // Overridden in subclass(es)
  }

  unload() {
    TimelineLite.killTweensOf(this.pinSprite, false);

    // Destroy sprites and containers
    this.pinAnchorBorderSprite.destroy(true);
    this.pinAnchorCenterSprite.destroy(true);
    this.pinDebugTitleText.destroy(true);
    this.pinSprite.destroy(true);

    this.displayObject.destroy(true);
  }
}

/* Pin Scaling Functions */

export const sourcePinScale = function (info) {
  return 1;
};

export const sourceFixedPinScale = function (info) {
  return 1 / info.currentZoom;
};

export const screenPinScale = function (info) {
  return info.maxZoom;
};

export const screenFixedPinScale = function (info) {
  return 1 / (info.currentZoom / info.maxZoom);
};

/* Pin Alpha Functions */

export const defaultPinAlpha = function (info) {
  return 1;
};
