import DecorationInternal from "./decoration-internal.js";

/** Base class for TiledMap decoration */
export default class DecorationBase extends DecorationInternal {
  // [Overridable]
  // This is the entry point for adding content to the root decoration display object.
  // Adds elements in `this.displayObject` based on instance properties and the provided info.
  // Keep references to the elements you create to alter them on refresh.
  // Inspect `this.info` at runtime to see what's available for setup.
  setup() {}

  // [Overridable]
  // [Can be called externally to force a refresh]
  // Refreshes the elements in `this.displayObject` based on instance properties and the provided info.
  // The general pattern is to cascade refreshes to children objects who are each responsible for updating their `DisplayObjects`.
  // Inspect `this.info` at runtime to see what's available for refresh.
  refresh() {}

  // [Overridable]
  willAppear(done) {
    done(); // MUST be called if overridden
  }

  // [Overridable]
  didAppear(done) {
    done(); // MUST be called if overridden
  }

  // [Overridable]
  willDisappear(done) {
    done(); // MUST be called if overridden
  }

  // [Overridable]
  didDisappear(done) {
    done(); // MUST be called if overridden
  }
}
