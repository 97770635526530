import { useState } from "react";

import Localization from "../../helpers/localization";
import { useInterval } from "./use-interval";

/**
 * Switches the current language at regular intervals, but also keeps track of the most recent language set by the
 * alternator. By using the frozen language instead of the "real" current language  in the callee, we can "freeze" a
 * component's UI in the last active language in the event that a language switch is performed from outside the
 * alternator. This prevents UI from flashing briefly to another language when switching language and navigating
 * immediately after.
 *
 * This is a stopgap solution until something that handles all use cases
 * (specifically language auto-switch at timeout) is implemented.
 */

export const useLanguageAlternator = (alternationInterval) => {
  const [currentInterval, setCurrentInterval] = useState(alternationInterval);
  const [frozenLanguage, setFrozenLanguage] = useState(Localization.getCurrentLanguage());

  useInterval(() => {
    const nextLanguage = Localization.getNextLanguage();
    setFrozenLanguage(nextLanguage);
    Localization.switchToLanguage(nextLanguage);
  }, currentInterval);

  const stopAlternatingLanguage = () => setCurrentInterval(null);

  return [frozenLanguage, stopAlternatingLanguage];
};
