import _ from "lodash";
import Point from "../../../../../../types/point";
import Pin from "./pin";
import Config from "../../../../../../helpers/config";

const pinPositionTextSemantic = "PinPosition";

// Stores altered positions to preserve them if navigating away and back
const alteredPositions = {};

function getKey(nodeId) {
  return `${nodeId}-${pinPositionTextSemantic}`;
}

export default class NodePin extends Pin {
  constructor(node, options = {}) {
    const imageUrl = node.wantedMedia("PinImage", "PinImage").url;
    const initialPosition =
      alteredPositions[getKey(node.id)] || Point.parse(node.wantedText(pinPositionTextSemantic).value);

    const finalOptions = { ...options };

    const actualAnchor = options.anchor || Point.parse(node.optionalText("PinAnchor").value);
    if (actualAnchor) finalOptions.anchor = actualAnchor;

    const customSound = node.optionalMedia("PinClickSound", "Audio");
    if (customSound.exists) finalOptions.sound = customSound.url;

    super(node.id, imageUrl, initialPosition, finalOptions);

    this.node = node;

    // Override the default debug title
    const title = node.optionalText("Title").value;
    const plainTextTitle = title ? title.replace(/<[^>]+>/g, "") : null;
    this.debugTitle = plainTextTitle || node.name || node.id.substring(0, 8);
  }

  positionChanged() {
    const key = getKey(this.node.id);
    alteredPositions[key] = this.position;

    _.each(Config.languages.supported, (language) => {
      this.node.optionalText(pinPositionTextSemantic, language).value = JSON.stringify(this.position);
    });
  }

  unload() {
    super.unload();
  }
}
