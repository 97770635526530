import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Log from "../helpers/log";

/**
 * PersistentQueue stores operations in persistent storage to ensure that they eventually succeed.
 * For example, it is used in the Mail helper to store a queue of emails to send. When the network
 * is available, items are dequeued almost instantly and sent, but when the network is down (or send
 * fails for some other reason), emails stay in the queue until the network comes back or the server
 * succeeds in sending the email. Then the queue is processed gradually until empty.
 */
export default class PersistentQueue {
  constructor(name) {
    if (!name) throw new Error("PersistentQueue cannot be used without a queue name!");
    this.name = name;
  }

  saveToStorage(id, entry) {
    return localStorage.setItem(`${this.name}-${id}`, JSON.stringify(entry));
  }

  loadFromStorage(id) {
    return JSON.parse(localStorage.getItem(`${this.name}-${id}`));
  }

  deleteFromStorage(id) {
    localStorage.removeItem(`${this.name}-${id}`);
  }

  loadQueue() {
    const rawQueue = localStorage.getItem(`${this.name}-queue`);
    return rawQueue ? JSON.parse(rawQueue) : [];
  }

  saveQueue(queue) {
    localStorage.setItem(`${this.name}-queue`, JSON.stringify(queue));
  }

  removeFromQueue(id) {
    this.saveQueue(this.loadQueue().filter((i) => i !== id));
  }

  requeue(id) {
    this.removeFromQueue(id);
    const queue = this.loadQueue();
    queue.push(id);
    this.saveQueue(queue);
  }

  enqueue(entry) {
    const id = uuidv4();

    // 1. Store the actual entry as a separate value
    this.saveToStorage(id, entry);

    // 2. Store the ID of this entry in the queue
    const queue = this.loadQueue();
    queue.push(id);
    this.saveQueue(queue);
  }

  startProcessing(operation) {
    let processingEntry = false;
    const persistentQueue = this; // Fixes scoping issue
    setInterval(() => {
      if (processingEntry) return;

      const queue = persistentQueue.loadQueue();
      const id = _.first(queue);
      if (!id) return;

      Log.info(`Queue '${persistentQueue.name}': Processing ${id} (${queue.length} remaining)...`);
      const entry = persistentQueue.loadFromStorage(id);

      if (!entry) {
        persistentQueue.removeFromQueue(id);
        return;
      }

      processingEntry = true;
      operation(
        entry,
        () => {
          processingEntry = false;
          persistentQueue.deleteFromStorage(id);
          persistentQueue.removeFromQueue(id);
          Log.info(`Queue '${persistentQueue.name}': Processed ${id} successfully`);
        },
        (error) => {
          processingEntry = false;
          persistentQueue.requeue(id); // We retry failed entries last
          Log.info(`Queue '${persistentQueue.name}': Failed to process ${id} (${error.message})`);
        }
      );
    }, 1000);
  }
}
