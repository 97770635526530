import _ from "lodash";
import YamlJs from "yamljs";
import { v4 as uuidv4 } from "uuid";
import Sequencer from "sequencer.js";
import { ModelBuilder } from "mediaclient.js";
import { XmlParser } from "mediaclient.js";
import Load from "../../../helpers/load";
import Log from "../../../helpers/log";
import Env from "../../../helpers/env";
import ProgressActions from "./progress-actions";
import resource from "../../../helpers/resource";
import { Config } from "../../../ripple";

/* eslint-disable no-use-before-define */

function dataRequested() {
  return { type: DataActions.DATA_REQUEST };
}

function dataReceived(nodes, clientId) {
  return {
    type: DataActions.DATA_RECEIVE,
    data: nodes,
    clientId: clientId,
    description: `ClientID: ${clientId}`,
  };
}

function fetchingMedias() {
  return { type: DataActions.FETCHING_MEDIAS };
}

function loadLocal(nodes, clientId) {
  return {
    type: DataActions.DATA_LOAD_LOCAL,
    data: nodes,
    clientId: clientId,
    description: `ClientID: ${clientId}`,
  };
}

function loadStatic(data) {
  return { type: DataActions.DATA_LOAD_STATIC, data: data };
}

function loadEmpty() {
  return { type: DataActions.DATA_LOAD_NONE };
}

function dataLoadError(error) {
  Log.error(error);
  return { type: DataActions.DATA_ERROR, error: error, description: error.message };
}

// DataActions Helpers

/* eslint-enable no-use-before-define */

function loadAndBuildLocalData() {
  return (Env.isRCC
    ? Env.readFile(resource("media/client.xml"))
    : // Because the ancestor directory in which the medias have been written is served as
      // a root by REC, we load the files as if they were in the web app root as usual.
      Load.text(resource(`media/client-instance-${Config.instanceNumber}.xml`))
  )
    .then(XmlParser.parse)
    .then(ModelBuilder.buildFromRaw);
}

function fetchAndBuildServerData(apiClient, clientId) {
  return apiClient
    .fetchClientXml(clientId)
    .then((xml) => Promise.all([Promise.resolve(xml), XmlParser.parse(xml).then(ModelBuilder.buildFromRaw)]));
}

const makeServerBuildMediaUrl = (apiClient, formatMetadatas, storage) => (...args) =>
  apiClient.serverInfo.buildMediaUrl(formatMetadatas, storage, ...args);

const makeLocalBuildMediaUrl = (built) => (media, format) => {
  const formatMetadata = _.find(built.formatMetadatas, (fm) => fm.name === format);
  const extension = formatMetadata ? formatMetadata.extension : "unk";
  const prefix = Env.isREC ? "../../" : ""; // Go up to a directory outside of the ASAR archive
  const filePath = prefix + `resources/media/medias/${media.id}/${media.revision}/${formatMetadata.id}.${extension}`;

  // In RCC, we convert the file:// path to a special URL which cordova-plugin-ionic-webview interprets
  // as an absolute path to files outside of the app's bundle.
  return Env.isRCC ? window.Ionic.WebView.convertFileSrc(`${Env.storageDirectory}/${filePath}`) : `/${filePath}`;
};

const makeLocalDestination = (built) => (media, format) => {
  const formatMetadata = _.find(built.formatMetadatas, (fm) => fm.name === format);
  const extension = formatMetadata ? formatMetadata.extension : "unk";
  return `resources/media/medias/${media.id}/${media.revision}/${formatMetadata.id}.${extension}`;
};

function modifyNodesToLoadMediasFromServer(built, apiClient) {
  _.each(
    built.nodes,
    (n) => (n.addons.buildMediaUrl = makeServerBuildMediaUrl(apiClient, built.formatMetadatas, built.storage))
  );
  return built;
}

function modifyNodesToLoadMediasFromLocal(built) {
  _.each(built.nodes, (n) => (n.addons.buildMediaUrl = makeLocalBuildMediaUrl(built)));
  return built;
}

class DownloadEntry {
  constructor(source, destination, done) {
    this.id = uuidv4();
    this.source = source;
    this.destination = destination;
    this.done = done;
  }
}

class DownloadManager {
  // This is an informed magic number!
  // See `doc/concurrent-fetch-speed-tests.md`
  static MAX_CONCURRENT_DOWNLOADS = 10;

  queue = [];
  ongoing = {};

  enqueue(source, destination, done) {
    const entry = new DownloadEntry(source, destination, done);
    this.queue.push(entry);
  }

  download(allDownloadsCompleted) {
    this.undownloadedCount = this.queue.length;
    this.process(allDownloadsCompleted);
  }

  process(allDownloadsCompleted) {
    if (this.queue.length === 0) return;

    const entry = this.queue.shift();
    this.ongoing[entry.id] = entry;

    const downloaded = (result) => {
      delete this.ongoing[entry.id];
      entry.done(result);
      this.process(allDownloadsCompleted);

      this.undownloadedCount -= 1;
      if (this.undownloadedCount === 0) {
        allDownloadsCompleted();
      }
    };

    if (Env.isContained) Env.downloadFile(entry.source, entry.destination).then(downloaded);
    else throw new Error("Can't download files outside of a container! This state should never be reached.");

    // If there can be more concurrent downloads, start another one immediately
    if (_.keys(this.ongoing).length < DownloadManager.MAX_CONCURRENT_DOWNLOADS) this.process(allDownloadsCompleted);
  }
}

const DataActions = {
  DATA_REQUEST: "@@local/DATA_REQUEST",
  DATA_RECEIVE: "@@local/DATA_RECEIVE",
  DATA_ERROR: "@@local/DATA_ERROR",
  FETCHING_MEDIAS: "@@local/FETCHING_MEDIAS",

  DATA_UPDATE_SERVERINFO: "@@local/DATA_UPDATE_SERVERINFO",
  updateServerInfo: function (serverInfo) {
    return { type: DataActions.DATA_UPDATE_SERVERINFO, serverInfo: serverInfo };
  },

  DATA_SERVER_UNREACHABLE: "@@local/DATA_SERVER_UNREACHABLE",
  serverUnreachable: function () {
    return { type: DataActions.DATA_SERVER_UNREACHABLE };
  },

  loadServerData: function (apiClient, clientId) {
    return function (dispatch) {
      dispatch(dataRequested());
      return fetchAndBuildServerData(apiClient, clientId)
        .then(([_xml, built]) => modifyNodesToLoadMediasFromServer(built, apiClient))
        .then((built) => dispatch(dataReceived(built.nodes, built.rootId)))
        .catch((e) => dispatch(dataLoadError(e)));
    };
  },

  loadFetchedData: function (apiClient, clientId, done) {
    return function (dispatch) {
      dispatch(dataRequested());

      const downloadSequencer = new Sequencer();
      fetchAndBuildServerData(apiClient, clientId)
        .then(([xml, built]) => {
          dispatch(fetchingMedias());

          // Create the functions that we need to build media URLs
          const serverBuildMediaUrl = makeServerBuildMediaUrl(apiClient);
          const localBuildMediaUrl = makeLocalBuildMediaUrl(built);

          // Prepare a list of downloads by inspecting built data
          const allMedias = _.flatMap(built.nodes, (node) => node.medias);
          const downloads = _.flatMap(allMedias, (media) => {
            const clientFormats = _.filter(media.formats, (format) => format.deviceType !== "server");
            const formatNames = _.map(
              clientFormats,
              (format) => _.find(built.formatMetadatas, (fm) => fm.id === format.id).name
            );
            return _.map(formatNames, (formatName) => ({ media, formatName }));
          });

          // Delegate downloads to the container and track progress

          const results = { success: 0, failure: 0, skipped: 0 };

          Log.info(`Fetch: Downloading ${downloads.length} files...`);
          dispatch(ProgressActions.updateProgress(0));

          downloadSequencer.doWaitForRelease((release) => {
            const downloadManager = new DownloadManager();
            let downloadCount = 0;

            _.each(downloads, (download, index) => {
              const media = download.media;
              const formatName = download.formatName;
              const source = serverBuildMediaUrl(media, formatName);

              const localDestination = makeLocalDestination(built);
              const destination = Env.isRCC
                ? localDestination(media, formatName)
                : localBuildMediaUrl(media, formatName);

              downloadManager.enqueue(source, destination, (info) => {
                downloadCount += 1;

                const progress = downloadCount / downloads.length;
                const percentage = Math.ceil(progress * 100);

                dispatch(ProgressActions.updateProgress(progress));

                if (info.result === "success") {
                  Log.info(`Fetch: [${percentage}%] Downloaded '${source}' (R${media.revision})`);
                  results.success += 1;
                } else if (info.result === "failure") {
                  Log.info(`Fetch: [${percentage}%] Error: ${info.error}`);
                  results.failure += 1;
                } else if (info.result === "skipped") {
                  results.skipped += 1;
                }
              });
            });

            downloadManager.download(release);
          });

          downloadSequencer.do(() => {
            Log.info(
              `Fetch: [100%] Done (${results.success} downloaded, ${results.skipped} skipped, ${results.failure} errored, ${downloads.length} total)`
            );
          });

          // Write the XML data to disk
          downloadSequencer.doWaitForRelease((release) => {
            if (results.failure === 0) {
              Log.info("Fetch: All media accounted for, writing new data file to disk");
              if (Env.isContained)
                Env.writeFile(
                  Env.isRCC
                    ? resource("media/client.xml")
                    : // Because we're using Env to write to disk, we can write outside of the `www` root
                      // and into an ancestor directory outside of the ASAR archive.
                      "../../" + resource(`media/client-instance-${Config.instanceNumber}.xml`),
                  xml
                ).then(release);
            } else {
              Log.warn("Fetch: Not overwriting data file because some media couldn't be downloaded");
            }
          });

          downloadSequencer.do(done);

          downloadSequencer.do(() => dispatch(DataActions.loadLocalData(apiClient)));
        })
        .catch(() => {
          // This can occur at least in the following scenario:
          // 1. The server responds to the ping request properly, leading to the fetch being performed
          // 2. The server throws when data is requested
          // This results in a CORS error because the error page returned from the server does not send CORS headers.
          // This clause catches those errors and tries to load local data as a last resort.
          // This is necessary because fecthing (this function) is an async dispatch and the bootstrap logic cannot catch it.
          Log.info("Trying to load local data assuming we might already have a previous version");
          downloadSequencer.clear();
          dispatch(DataActions.loadLocalData(apiClient));
        });
    };
  },

  DATA_LOAD_LOCAL: "@@local/DATA_LOAD_LOCAL",
  loadLocalData: function (apiClient, errorAction = null) {
    return function (dispatch) {
      loadAndBuildLocalData()
        .then((built) => modifyNodesToLoadMediasFromLocal(built))
        .then((built) => dispatch(loadLocal(built.nodes, built.rootId)))
        .catch((e) => dispatch(errorAction || dataLoadError(e)));
    };
  },

  DATA_LOAD_STATIC: "@@local/DATA_LOAD_STATIC",
  loadStaticData: function (url) {
    return function (dispatch) {
      Load.text(url, { credentials: "include" })
        .then((text) => {
          if (url.endsWith(".json")) return JSON.parse(text) || {};
          if (url.endsWith(".yml") || url.endsWith(".yaml")) return YamlJs.parse(text) || {};
          throw new Error(`Unsupported static data type at '${url}'`);
        })
        .then((data) => dispatch(loadStatic(data)))
        .catch((e) => dispatch(dataLoadError(e)));
    };
  },

  DATA_LOAD_NONE: "@@local/DATA_LOAD_NONE",
  loadEmptyData: function (url) {
    return loadEmpty();
  },
};

export default DataActions;
