import _ from "lodash";

import Maths from "../../../../helpers/maths";

import Tile from "./tile";

function isInViewport(column, row, visibilityDescriptor) {
  return (
    column >= visibilityDescriptor.minColumn &&
    column <= visibilityDescriptor.maxColumn &&
    row >= visibilityDescriptor.minRow &&
    row <= visibilityDescriptor.maxRow
  );
}

// This class is a logical level that manages its own set of logical tiles.
const Level = function (metaLevel, displayObject, columns, rows) {
  this.metaLevel = metaLevel;
  this.displayObject = displayObject;
  this.columns = columns;
  this.rows = rows;
  this.tiles = new Array(columns * rows); // The tiles, organized in a virtual grid (use the index transformation function to access individual elements)
};

Level.prototype.getIndex = function (column, row) {
  return row * this.columns + column;
};

Level.prototype.portionVisibleInViewport = function (viewportBounds, levelBounds) {
  const horizontalSubdivisions = this.metaLevel.tile.subdivisions.horizontal;
  const verticalSubdivisions = this.metaLevel.tile.subdivisions.vertical;

  // We return an object that describes the portion of the level's tiles that are visible in the viewport
  return {
    minColumn:
      Math.floor(Maths.ratio(viewportBounds.minX, levelBounds.minX, levelBounds.maxX) * horizontalSubdivisions) - 1,
    minRow: Math.floor(Maths.ratio(viewportBounds.minY, levelBounds.minY, levelBounds.maxY) * verticalSubdivisions) - 1,
    maxColumn:
      Math.ceil(Maths.ratio(viewportBounds.maxX, levelBounds.minX, levelBounds.maxX) * horizontalSubdivisions) - 1,
    maxRow: Math.ceil(Maths.ratio(viewportBounds.maxY, levelBounds.minY, levelBounds.maxY) * verticalSubdivisions) - 1,
  };
};

// ##### Public API #####

Level.prototype.addTile = function (tile, column, row) {
  tile.destinationContainer = this.displayObject; // So the tile knows about its parent
  this.tiles[this.getIndex(column, row)] = tile;
};

Level.prototype.refresh = function (viewportBounds, stateOverride = (state) => state) {
  const levelRect = this.displayObject.getBounds();
  const levelBounds = {
    minX: levelRect.x,
    minY: levelRect.y,
    maxX: levelRect.x + levelRect.width,
    maxY: levelRect.y + levelRect.height,
  };

  const visibilityDescriptor = this.portionVisibleInViewport(viewportBounds, levelBounds);

  for (let row = 0; row < this.metaLevel.tile.subdivisions.vertical; row++) {
    for (let column = 0; column < this.metaLevel.tile.subdivisions.horizontal; column++) {
      const tileIsInViewport = isInViewport(column, row, visibilityDescriptor);
      const calculatedState = tileIsInViewport ? Tile.State.LOADED_VISIBLE : Tile.State.UNLOADED;

      // Provide the opportunity to override the tile's calculated state from the
      // caller, for example to force the tiles of a specific level to be shown,
      // be loaded but invisible, etc..
      const actualState = stateOverride(calculatedState);

      this.tiles[this.getIndex(column, row)].refresh(actualState);
    }
  }
};

Level.prototype.unload = function () {
  _.each(this.tiles, (tile) => tile.refresh(Tile.State.UNLOADED));
  this.displayObject.destroy(true);
};

export default Level;
