import * as PIXI from "pixi.js";

// This is a reference implementation of the TiledMap decoration API.
// It also acts as a "null object" (http://www.oodesign.com/null-object-pattern.html)
export default class DecorationInternal {
  setupInternal(info) {
    this.info = info;
    this.displayObject = new PIXI.Container();
    this.initialized = true;
    this.setup();
  }

  refreshInternal(info) {
    this.info = info;
    this.refresh();
  }
}
