import { memo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Page from "../../../components/page";
import Button from "../../../components/button";
import TiledMap from "../../../components/tiled-map";

import Render from "../../../../helpers/internal/render";
import Env from "../../../../helpers/env";
import Rect from "../../../../types/rect";
import resource from "../../../../helpers/resource";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import { useConstant } from "../../../hooks/use-constant";
import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import { useNodePinsDecoration } from "../../../hooks/specialized/use-node-pins-decoration";
import Text from "../../../components/text";
import Classes from "../../../../helpers/classes";
import { screenFixedPinScale, sourcePinScale } from "../../../components/tiled-map/api/decorations/pins-decoration/pin";
import Point from "../../../../types/point";

const ImageSource = TiledMap.ImageSource;
const TilesetSource = TiledMap.TilesetSource;

const PageComponent = memo(({ data }) => {
  useTimeoutPause("Demo", "reset");

  const apiRef = useRef(null);
  const onGetApi = useCallback((api) => (apiRef.current = api), []);

  const leftDecoration = useNodePinsDecoration(
    data && data.requiredNode && data.wantedNode("458a4b0b-cc1f-4c62-927f-1b2af8bba88f"),
    {
      pinDynamicScale: screenFixedPinScale,
      pinNeutralAlpha: 0.8,
      pinSelectedAlpha: 1,
      pinUnselectedAlpha: 0.4,
      onPinClick: (decoration, pin) => {
        decoration.selectedPin = pin;
        decoration.refresh();
      },
    },
    {
      anchor: new Point(0.5, 1.0),
    }
  );
  const leftSource = useConstant(() => new TilesetSource("tileset", resource("images/demo/tileset"), leftDecoration));

  const rightDecoration = useNodePinsDecoration(
    data && data.requiredNode && data.wantedNode("458a4b0b-cc1f-4c62-927f-1b2af8bba88f"),
    {
      pinDynamicScale: sourcePinScale,
      pinNeutralAlpha: 0.8,
      pinSelectedAlpha: 1,
      pinUnselectedAlpha: 0.4,
      onPinClick: (decoration, pin) => {
        decoration.selectedPin = pin;
        decoration.refresh();
      },
    },
    {
      anchor: new Point(0.5, 1.0),
    }
  );
  const rightSource = useConstant(
    () => new ImageSource("image", resource("images/demo/single-tile.jpg"), rightDecoration),
    []
  );

  const onDeselect = useCallback(() => {
    rightDecoration.selectedPin = null;
    rightDecoration.refresh();
  }, [rightDecoration]);

  const onFrame1 = useCallback(() => apiRef.current.animateViewportToFit(new Rect(0.5, 0.3, 0.4, 0.4), 2000), []);
  const onFrame2 = useCallback(() => apiRef.current.animateViewportToFit(new Rect(0.2, 0.5, 0.3, 0.3), 500), []);

  return (
    <Page className="demo tiled-map ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Tiled Map</Text>
          <p className="standard description">
            The tiled map is a customizable component which displays and allows zooming into an arbitrarily large image.
          </p>
          <p className="standard description">
            The minimum zoom (1x) scales the source to aspect-fill the specified viewport area, as specified during
            tileset generation (<i>not</i> at runtime!) The maximum zoom level is inferred from the source image&#39;s
            resolution relative to the viewport resolution so that the maximum zoom level displays the source image at
            1:1 pixel ratio. The larger the image relative to the viewport, the greater the maximum zoom. For more
            details regarding the tiling algorithm, read{" "}
            <a href="https://bitbucket.org/Simbioz/tyler/src/master/README.md">this</a>.
          </p>
          <div className={Classes.build("maps", { mobile: Env.isMobile })}>
            <div className="map">
              <h2 className="standard">Tileset Source (Generated Tileset)</h2>
              <TiledMap
                sources={[leftSource]}
                currentSource={leftSource}
                backgroundColor={0xdddddd}
                getApi={onGetApi}
              />
              <div className="row">
                <Button className="standard" onClick={onFrame1}>
                  Frame 1
                </Button>
                <Button className="standard" onClick={onFrame2}>
                  Frame 2
                </Button>
                <Button className="standard red" onClick={onDeselect}>
                  Deselect
                </Button>
              </div>
            </div>
            <div className="map">
              <h2 className="standard">Image Source (Single Image File)</h2>
              <TiledMap sources={[rightSource]} currentSource={rightSource} backgroundColor={0xdddddd} />
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

const protectedPage = Render.protect((c) => c.props.data)(PageComponent);
export default connect((state) => ({ language: state.language, data: state.data }))(protectedPage);
