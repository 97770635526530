import YamlResource from "../logic/yaml-resource";
import Localization from "./localization";
import StringInfo from "../logic/info/string-info";

const resource = new YamlResource();

export const loadStrings = (...args) => resource.load(...args);

export default class Strings {
  /** Returns the localized string that matches the provided resource key */
  static localized(resourceKey, language) {
    const actualLanguage = language || Localization.getCurrentLanguage();
    return new StringInfo(resource, resourceKey, actualLanguage);
  }
}
