/**
 * This layout displays items in a square grid (or as square as it can be)
 */
export const squareGridLayout = () => {
  return (count) => {
    const size = Math.ceil(Math.sqrt(count));

    const rows = [];
    for (let i = 0; i < size; i++) {
      const spentItems = i * size;
      if (count - spentItems > 0) rows.push(size);
    }

    return rows;
  };
};
