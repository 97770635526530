import { useEffect, useContext } from "react";
import { useLatestRef } from "./internal/use-latest-ref";
import RippleContext from "../../core/ripple-context";

/**
 * Runs a callback each time a redux action is dispatched.
 * Use this to react in a discrete manner to redux actions.
 * */
export const useAction = (type = null, callback) => {
  const context = useContext(RippleContext);
  const mostRecentCallbackRef = useLatestRef(callback);

  useEffect(() => {
    const handler = ({ action }) => {
      if (action.type !== type) return;
      const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
      mostRecentCallback(action);
    };

    window.listenStore.addListener(type, handler);

    return () => window.listenStore.removeListeners({ type, fn: handler });
  }, [type, context, mostRecentCallbackRef]);
};
