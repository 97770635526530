import _ from "lodash";
import yuri from "yuri";

let ServerInfo = function (url, apiVersion) {
  this.url = url;
  this.apiVersion = apiVersion === "latest" ? 0 : apiVersion;

  let that = this;

  function buildApiPath(path) {
    return ["api", `v${that.apiVersion}`].concat(path);
  }

  function buildApiUrl(path) {
    return yuri(that.url).pathname(buildApiPath(path)).format();
  }

  this.buildPingUrl = function () {
    return buildApiUrl(["ping"]);
  };

  this.buildClientUrl = function (id) {
    return buildApiUrl(["client", id]);
  };

  this.buildMediaUrl = function (formatMetadatas, storage, media, format) {
    // Backcompat: Storage can be falsy when accessing an old media server that doesn't provide that information
    if (!storage || storage.type === "local") return buildApiUrl(["file", media.id, media.revision, format]);

    if (storage.type === "s3" || storage.type === "s3b2") {
      // Find the ID corresponding to the named format, as our S3 storage stores files by ID (as on the server)
      // and does not support fetching medias by format name like our Blur API does.
      const formatMetadata = _.find(formatMetadatas, (fm) => fm.name === format);
      const formatId = formatMetadata.id;
      const formatExtension = formatMetadata.extension;

      return `https://${storage.bucketname}.${storage.endpoint}/${storage.namespace}/${media.id}/${media.revision}/${formatId}.${formatExtension}`;
    }
  };

  this.buildUpdateTextsUrl = function () {
    return buildApiUrl(["update", "texts"]);
  };
};

export default ServerInfo;
