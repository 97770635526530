import _ from "lodash";
import YamlJs from "yamljs";
import { Promise } from "es6-promise";
import Log from "../helpers/log";

export default class YamlResource {
  values = {};

  fetchLocal(url) {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(new TypeError(`Failed to fetch local file from ${url}`));
      xhr.open("GET", url);
      xhr.send(null);
    });
  }

  loadLayer(url, optional) {
    return this.fetchLocal(url)
      .then((text) => {
        const layer = YamlJs.parse(text);
        return { url: url, optional: optional, layer: layer };
      })
      .catch((error) => {
        if (!optional) {
          Log.error(`Could not load resource file '${url}'`);
          throw new Error(`Could not load '${url}' (${error})`);
        }
        return { url: url, optional: optional, layer: null };
      });
  }

  load(...urls) {
    const loads = _.map(urls, (url) => {
      const optionalToken = "[optional]";
      const optional = url.endsWith(optionalToken);
      const cleanUrl = url.replace(optionalToken, "").trim();
      return this.loadLayer(cleanUrl, optional);
    });

    return Promise.all(loads).then((infos) => {
      _.each(infos, (info) => {
        if (info.layer !== null) {
          const optionalSuffix = info.optional ? " [optional]" : "";
          const resourceName = `${info.url}${optionalSuffix}`;
          this.merge(info.layer);
          // eslint-disable-next-line no-console
          console.info(`Loaded resource '${resourceName}'`);
        } else {
          // eslint-disable-next-line no-console
          console.info(`Skipped optional resource '${info.url}' (not present)`);
        }
      });
    });
  }

  merge(obj) {
    _.mergeWith(this.values, obj, (objValue, srcValue) => {
      // Just replace arrays instead of trying to merge them!
      if (objValue instanceof Array) return srcValue;
    });
  }
}
