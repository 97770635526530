import _ from "lodash";
import Config from "./config";
import Analytics from "./analytics";

export default class Upload {
  static file(path, data, options = {}) {
    Analytics.track("upload-file");

    const actualOptions = _.merge({}, Config.upload, options);
    if (!actualOptions.id || !actualOptions.key) return Promise.reject(new Error("Mail: Missing app ID and/or key"));

    const formData = new FormData();
    formData.append("id", actualOptions.id);
    formData.append("key", actualOptions.key);
    formData.append("file", data, path);

    return fetch(`${Config.upload.server}/upload`, {
      method: "POST",
      body: formData,
    }).then((response) => response.text());
  }
}
