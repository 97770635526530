import _ from "lodash";
import * as PIXI from "pixi.js";

import Point from "../../../../types/point";
import Size from "../../../../types/size";
import Log from "../../../../helpers/log";
import Toast from "../../../../helpers/toast";
import Clipboard from "../../../../helpers/clipboard";

import Level from "./level";
import Tile from "./tile";

function getMetaLevelFor(metaLevels, zoom, levelSwitchingRatio) {
  // Special-case a one level tiled map to simplify the logic below
  if (metaLevels.length === 1) return metaLevels[0];

  const found = metaLevels[0];

  for (let i = 0; i < metaLevels.length; i++) {
    const previousLevel = metaLevels[i - 1];
    const testedLevel = metaLevels[i];
    const nextLevel = metaLevels[i + 1];

    // We define functions to extract distances but only call them if the
    // values they use are defined, based on the conditions below.

    const getLeftBound = () => {
      const scaleDistance = testedLevel.viewportScale - previousLevel.viewportScale;
      return previousLevel.viewportScale + scaleDistance * levelSwitchingRatio;
    };

    const getRightBound = () => {
      const scaleDistance = nextLevel.viewportScale - testedLevel.viewportScale;
      return nextLevel.viewportScale - scaleDistance * levelSwitchingRatio;
    };

    if (i === 0) {
      // Testing the first level
      if (zoom < getRightBound()) return metaLevels[i];
    } else if (i === metaLevels.length - 1) {
      // Testing the last level
      if (zoom >= getLeftBound()) return metaLevels[i];
    } else {
      // Testing intermediate levels
      if (zoom >= getLeftBound() && zoom < getRightBound()) return metaLevels[i];
    }
  }
  return found;
}

const defaultOptions = {
  // Determines when the tileset performs a level switch (which is performed on refresh if applicable).
  // A value of zero means that the tileset will switch to a level when the zoom is exactly equal to
  // that level's meta viewportScale. A value of (say) 0.5 means that the level will be loaded when
  // the zoom is halfway there (halfway between a level's viewportScale and the previous level's viewportScale).
  // In practice, a higher value will result in a sharper map that loads tiles earlier. With a lower value
  // the map might be a bit blurry at times when zooming-in.
  levelSwitchingRatio: 0.5,

  // To prevent the map from flashing to blurred tiles when switching levels and to smoothen the transition
  // visually, you can specify whether to preload the previous and next level's tiles in advance. The higher
  // the number, the worse the performance.
  preloadPreviousLevels: 1,
  preloadNextLevels: 1,
};

// A tileset is a logical construct that manages a set of tiles (grouped in levels) and knows how and when
// to show or hide them based on zoom level and location of the tileset in a world-level viewport.
// Everything that the tileset manages is contained in a single PIXI container.
// It is the responsibility of the caller to insert that container in a PIXI stage and
// manage its position and scale, as well as refresh the tileset so that tiles are shown and hidden appropriately.
const Tileset = function (source, options = defaultOptions) {
  this.source = source;
  this.options = options;

  this.levels = [];

  // Contains the tileset's metadata (`meta.json` loaded as an object)
  this.meta = null;

  // Root container for the tileset
  this.displayObject = new PIXI.Container();

  // Contains all of the individual levels. Each level
  // is scaled so that it fits flush with level 0.
  this.levelsContainer = new PIXI.Container();
  this.displayObject.addChild(this.levelsContainer);
};

Tileset.prototype.load = function (stageSize, tilesetReady) {
  this.source
    .getMeta(stageSize)
    .then((meta) => {
      this.meta = meta;

      _.each(meta.levels, (metaLevel, levelNumber) => {
        const levelWidth = metaLevel.size.width;
        const levelHeight = metaLevel.size.height;
        const horizontalSubdivisions = metaLevel.tile.subdivisions.horizontal;
        const verticalSubdivisions = metaLevel.tile.subdivisions.vertical;

        Log.info(
          `Preparing level ${metaLevel.number} (${horizontalSubdivisions}x${verticalSubdivisions} tiles, each tile is ${metaLevel.tile.size.width}x${metaLevel.tile.size.height})`
        );

        const levelContainer = new PIXI.Container();
        levelContainer.scale.set(this.getScale(levelNumber)); // Scale the level so that it fits flush with level 0
        levelContainer._calculateBounds = function () {
          // By overriding _calculateBounds (as suggested in the PIXI.js documentation), we can
          // force the level container to have fixed dimensions, which allows accessing the level's
          // frame at world level with ease (which we do for viewport tile visibility calculations).
          // Without this override, the container calculates its bounds from its children, which
          // means the bounds would vary wildly depending on the presence or absence of tiles.
          const minX = -(levelWidth / 2.0);
          const minY = -(levelHeight / 2.0);
          const maxX = minX + levelWidth;
          const maxY = minY + levelHeight;

          // We provide the frame in local coordinates and addFrame() uses our
          // transform to translate those to world coordinates.
          this._bounds.addFrame(this.transform, minX, minY, maxX, maxY);
        };

        // Coordinate picking (for data entry)
        levelContainer.interactive = true;
        const tapAction = (event) => {
          if (!event.data.originalEvent.altKey) return; // Alt key required
          const size = levelContainer.getLocalBounds();
          const absolutePosition = event.data.getLocalPosition(event.target);
          const relativePosition = {
            x: absolutePosition.x / size.width + 0.5,
            y: absolutePosition.y / size.height + 0.5,
          };
          const positionJSON = JSON.stringify(relativePosition);
          Clipboard.copy(positionJSON);
          Toast.info("Position copied to clipboard!");
        };
        levelContainer.on("tap", tapAction);
        levelContainer.on("click", tapAction);

        this.levelsContainer.addChild(levelContainer);

        // Prepare a level that will serve as our entry point for loading and unloading tiles
        const level = new Level(metaLevel, levelContainer, horizontalSubdivisions, verticalSubdivisions);

        // Layout each level within its own local coordinate system
        // (the whole level is scaled to proper dimensions to fit flush with level 0)
        for (let row = 0; row < verticalSubdivisions; row++) {
          for (let column = 0; column < horizontalSubdivisions; column++) {
            // Determine the theoretical dimensions of the tiles. These values *may* not exactly
            // match the tile dimensions in the metadata (depending on the calculated error displayed
            // when generating the tiles) but this is what we want; if we used the rounded (px)
            // tile dimensions for layout, layout error would accumulate for each successive tile.
            const tileLayoutWidth = levelWidth / horizontalSubdivisions;
            const tileLayoutHeight = levelHeight / verticalSubdivisions;

            // Calculate the current tile's position
            const x = -levelWidth / 2.0 + column * tileLayoutWidth;
            const y = -levelHeight / 2.0 + row * tileLayoutHeight;

            // Create a tile manager to represent the not-yet-loaded tile
            const imageUrl = this.source.getTileUrl(metaLevel.number, row, column);

            const tile = new Tile();
            tile.imageUrl = imageUrl;
            tile.column = column;
            tile.row = row;
            tile.position = new Point(x, y);
            tile.size = metaLevel.tile.size;

            level.addTile(tile, column, row);
          }
        }

        this.levels.push(level);
      });

      // Indicate that we finished preparing the tileset
      tilesetReady(this);
    })
    .catch((error) => {
      const url = this.source.tilesetUrl;
      const message = `Could not load tileset${url ? ` at URL '${url}'` : ""} (${error})`;
      Toast.error(message, 10000);
      throw new Error(message);
    });
};

// Because subsequent levels are scaled down to the size
// of the first level, we can guarantee that the tileset's size
// is equal to the first level's size.
Tileset.prototype.getInitialSize = function () {
  return this.getSize(0);
};

Tileset.prototype.getSize = function (levelNumber) {
  const rawSize = this.meta.levels[levelNumber].size;
  return new Size(rawSize.width, rawSize.height);
};

Tileset.prototype.getScale = function (levelNumber) {
  return 1.0 / this.meta.levels[levelNumber].viewportScale;
};

Tileset.prototype.getMaxZoom = function () {
  return this.meta.maxZoom;
};

Tileset.prototype.getMaxLevelNumber = function () {
  return this.meta.levels.length - 1;
};

// Show or hide tiles based on zoom, visibility in viewport, and more
Tileset.prototype.refresh = function (zoom, worldViewportBounds) {
  const currentLevel = getMetaLevelFor(this.meta.levels, zoom, this.options.levelSwitchingRatio);

  // Update Levels
  _.each(this.levels, (level) => {
    const overrideState = (state) => {
      const testedLevelNumber = level.metaLevel.number;
      const currentLevelNumber = currentLevel.number;

      // Given an initial tile state as calculated by a level, override the calculated
      // state for performance and usability reasons. In practice, the calculated state
      // represents the visibility of a tile in the viewport.

      // Level zero is always fully visible to avoid gaps in the map when zooming and panning quickly
      if (testedLevelNumber === 0) return Tile.State.LOADED_VISIBLE;

      // For the following rules, we're only interested in tiles that are marked
      // as visible in the viewport. We keep already-unloaded tiles as-is.

      if (state === Tile.State.LOADED_VISIBLE) {
        // Preload previous and next levels based on the provided options

        const preloadPreviousLevels = this.options.preloadPreviousLevels;
        if (preloadPreviousLevels !== 0 && testedLevelNumber === currentLevelNumber - preloadPreviousLevels) {
          // Special-case the immediate previous level (the most precise apart from the current one) to make it
          // visible (if preloaded) to avoid  "flash to level zero" on level switches (makes for a more seamless transition).
          const immediatePreviousLevel = testedLevelNumber === currentLevelNumber - 1;
          return immediatePreviousLevel ? Tile.State.LOADED_VISIBLE : Tile.State.LOADED_INVISIBLE;
        }

        const preloadNextLevels = this.options.preloadNextLevels;
        if (preloadNextLevels !== 0 && testedLevelNumber === currentLevelNumber + preloadNextLevels)
          return Tile.State.LOADED_INVISIBLE;

        // Unload the tile if it's not in the current level
        // and does not match previous conditions
        if (testedLevelNumber !== currentLevelNumber) return Tile.State.UNLOADED;
      }

      return state; // Else, keep the loaded state as determined by the level refresh logic
    };

    // The level calculates whether its tiles should be visible based on the provided viewport,
    // then uses overrideState to allow us to override the calculated visibility (see the rules above).
    level.refresh(worldViewportBounds, overrideState);
  });
};

// Performs a single unload of all tiles in all levels of the tileset.
// If refresh() is called afterwards, the tiles will be reloaded as expected,
// unless the tileset is disabled.
Tileset.prototype.unload = function () {
  _.each(this.levels, (level) => level.unload());
};

export default Tileset;
