/* global SafariViewController */
import Env from "./env";
import Log from "./log";
import Analytics from "./analytics";

export default class Link {
  /** Opens an external URL in a device-appropriate manner */
  static open(url) {
    Log.info(`Opening external URL: ${url}`);
    Analytics.track("external-link", { url });

    // Potentially powered by InAppBrowser because that (currently) clobbers window.open
    const windowOpen = () => window.open(url, "_blank", "location=yes");

    if (Env.isIos && typeof SafariViewController !== "undefined") {
      // On iOS, use SFSafariViewController as an in-app browser for maximum performance and notch-compatibility
      // Reference: https://github.com/EddyVerbruggen/cordova-plugin-safariviewcontroller
      SafariViewController.isAvailable((available) => {
        if (available) {
          SafariViewController.show({ url: url });
        } else {
          windowOpen();
        }
      });
    } else if (Env.isAndroid) {
      // On Android, use InAppBrowser as an in-app browser
      // Reference: https://github.com/apache/cordova-plugin-inappbrowser
      cordova.InAppBrowser.open(url, "_blank", "location=no,toolbar=no,hardwareback=no");
    } else {
      windowOpen();
    }
  }
}
