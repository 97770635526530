import { useEffect } from "react";
import GPIO from "../../helpers/gpio";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Register a GPIO push button to run some code.
 */
export const usePushButton = (description, pinNumber, onPress) => {
  const mostRecentCallbackRef = useLatestRef(onPress);

  useEffect(() => {
    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    const subscription = GPIO.pushButtonSubscribe(pinNumber, GPIO.Pin.NORMALLY_HIGH, mostRecentCallback);
    return () => GPIO.pushButtonUnsubscribe(subscription);
  }, [description, pinNumber, mostRecentCallbackRef]);
};
