import _ from "lodash";
import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Classes from "../../helpers/classes";

class Toasts extends PureComponent {
  static propTypes = {
    toasts: PropTypes.array.isRequired,
  };

  render() {
    return <TransitionGroup className="toasts">{_.map(this.props.toasts, (t) => this.renderToast(t))}</TransitionGroup>;
  }

  renderToast = (toast) => {
    return (
      <CSSTransition key={toast.id} classNames="fade-out-fade-in" timeout={500}>
        <div className={Classes.build("toast", toast.level)}>
          <code>{toast.message}</code>
        </div>
      </CSSTransition>
    );
  };
}

const mapStateToProps = (state) => ({ toasts: state.toasts });

export default connect(mapStateToProps)(Toasts);
