import { PureComponent } from "react";
import PropTypes from "prop-types";
import Button from "../components/button";

/**
 * A button that performs its action only when it's clicked
 * a certain number of times in the specified timeout duration.
 * */
class MultiClickButton extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    clicks: PropTypes.number.isRequired,
    timeout: PropTypes.number.isRequired,
    onMultiClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.clickCount = 0;
  }

  render() {
    return <Button {...this.props} onClick={this.onClick} muted />;
  }

  onClick = () => {
    if (this.clickCount === 0) this.startTimeout();
    this.clickCount += 1;
  };

  startTimeout = () => {
    this.interval = setTimeout(this.validate, this.props.timeout);
  };

  validate = () => {
    if (this.clickCount >= this.props.clicks && this.props.onMultiClick) this.props.onMultiClick();
    this.clickCount = 0;
  };
}

export default MultiClickButton;
