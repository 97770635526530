import _ from "lodash";
import { memo, useState, useImperativeHandle, forwardRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { animated, config, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";

import Classes from "../../../helpers/classes";
import Maths from "../../../helpers/maths";
import Visible from "../visible";
import { useMeasure } from "../../hooks/use-measure";
import { scaleUnfocused } from "./scale-functions";
import { rotationAscending } from "./rotation-functions";
import { opacityOff } from "./opacity-functions";

class SelectorAPI {}

/* Disable the props validation rule because `forwardRef` mixes it up, causing an error for each prop. */
/* eslint-disable react/prop-types */

/**
 * A component that allows swiping between children as
 * distict "pages" in a performant an nice-looking manner.
 */
const Selector = memo(
  forwardRef(
    (
      {
        className,
        children,
        orientation = "horizontal", // Either "horizontal" or "vertical"
        items = [], // The data to render (the selector requests UI by calling its children function with the current item and its index)
        initialIndex = 0, // The item number to start on when the Selector is initially displayed
        wrap = false, // Enable to wrap back to the first item when reaching past the last one, and vice versa
        springConfig = config.stiff, // Either the name of a `react-spring` config preset or a `react-spring` config object
        swipeThreshold = 100, // The distance in pixels below which no swipe is registered (we stay on the same index)
        sideItemCount = 1, // The number of items to render at each side of the current one (useful with overflow visible)
        preloadSideItems = false, // Whether to preload the content of side items (when disabled, the content of side items not intersecting the page's viewport is not rendered)
        disableGestures = false, // Set to true to disable manually swiping between items using gestures (only allow programmatic controls)
        itemDynamicScale = scaleUnfocused(0.5), // A function to control the item's scale (see `scaling-functions.js`)
        itemDynamicRotation = rotationAscending(0, 1, 0, 90), // A function to control the item rotation in all three axes (see `rotation-functions.js`)
        itemDynamicOpacity = opacityOff(), // A function to control the item opacity (see `opacity-functions.js`)
        onIndexChange = (_index) => {},
        ...rest
      },
      ref
    ) => {
      const minIndex = wrap ? Number.NEGATIVE_INFINITY : 0;
      const maxIndex = wrap ? Number.POSITIVE_INFINITY : Math.max(items.length - 1, 0);
      const [index, setIndex] = useState(initialIndex);
      const [measureRef, { width: viewWidth, height: viewHeight }] = useMeasure();

      const getActualSpringConfig = useCallback(
        () => (typeof springConfig === "string" ? config[springConfig] : springConfig),
        [springConfig]
      );

      const [spring, setSpring] = useSpring(() => ({
        config: getActualSpringConfig(),
        floatIndex: index,
      }));

      const updateIndexAndSpring = useCallback(
        (newIndex, animate = true) => {
          setIndex(newIndex); // Update our integer index in the state
          setSpring({ floatIndex: newIndex, reset: !animate }); // Animate the spring's float index
          onIndexChange(Maths.wrap(newIndex, 0, Math.max(items.length - 1, 0))); // We return the "fake" index even if wrapping
        },
        [items.length, onIndexChange, setSpring]
      );

      const bindSwipeGesture = useGesture({
        onDrag: ({ down, movement: [xMovement, yMovement], direction: [xDir, yDir] }) => {
          const horizontal = orientation === "horizontal";
          const movement = horizontal ? xMovement : yMovement;
          const viewLength = horizontal ? viewWidth : viewHeight;
          const dir = horizontal ? xDir : yDir;

          if (!down) {
            if (Math.abs(movement) > swipeThreshold) {
              // If we have moved enough when releasing, change the index and snap to it
              const newIndex = Maths.clamp(index + (dir > 0 ? -1 : 1), minIndex, maxIndex);
              updateIndexAndSpring(newIndex);
            } else {
              // Else snap to the current index
              setSpring({ floatIndex: index });
            }
            return;
          }

          // Follow the cursor if active, but do not start dragging instantly
          // to avoid conflicts with embedded Scrollers or Selectors
          if (down && Math.abs(movement) >= 50) {
            // While dragging, follow the pointer
            const calculated = index - (1 - (viewLength - movement) / viewLength);
            setSpring({ floatIndex: calculated });
          }
        },
      });

      // Update the spring config if the prop changes
      useEffect(() => {
        setSpring({ config: getActualSpringConfig(springConfig) });
      }, [getActualSpringConfig, setSpring, springConfig]);

      // Reset the index when toggling wrapping while outside of the "normal" range
      useEffect(() => {
        updateIndexAndSpring(Maths.clamp(index, minIndex, maxIndex));
      }, [index, maxIndex, minIndex, updateIndexAndSpring, wrap]);

      // Setup an external API to control the Selector
      // (use through `selectorRef.current.next()`, for example)
      useImperativeHandle(
        ref,
        () => {
          const api = new SelectorAPI();
          api.previous = () => updateIndexAndSpring(Math.max(index - 1, minIndex));
          api.next = () => updateIndexAndSpring(Math.min(index + 1, maxIndex));
          api.jumpTo = (index, animate = false) => updateIndexAndSpring(index, animate);
          return api;
        },
        [index, maxIndex, minIndex, updateIndexAndSpring]
      );

      return (
        <div
          {...rest}
          {...(!disableGestures && bindSwipeGesture())}
          ref={measureRef}
          className={Classes.build("ripple-selector", className, orientation)}
        >
          {items.length > 0 &&
            (() => {
              const clampedIndex = Maths.clamp(index, minIndex, maxIndex);
              const min = Math.max(clampedIndex - sideItemCount, minIndex);
              const max = Math.min(clampedIndex + sideItemCount, maxIndex);
              return _.map(_.range(min, max + 1), realIndex => {
                const actualIndex = Maths.wrap(realIndex, 0, Math.max(items.length - 1, 0));
                return (
                  <animated.div
                    key={actualIndex}
                    className={Classes.build("debug-show-bounds", `item-${actualIndex}`)}
                    style={{
                      // Each selector item fills the selector fully (for simpler animation)
                      // and we can position our "real" item within it.
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      opacity: spring.floatIndex.interpolate(floatIndex => itemDynamicOpacity(floatIndex, realIndex)),
                      transform: spring.floatIndex.interpolate(floatIndex => {
                        // Translation
                        const viewLength = orientation === "horizontal" ? viewWidth : viewHeight;
                        const offset = viewLength * realIndex - floatIndex * viewLength;
                        const translationString =
                          orientation === "horizontal"
                            ? `translate3d(${offset}px, 0, 0)`
                            : `translate3d(0, ${offset}px, 0)`;

                        // Scale
                        const scale = itemDynamicScale(floatIndex, realIndex);
                        const scaleString = `scale3d(${scale}, ${scale}, 1)`;

                        // Rotation
                        const rotation = itemDynamicRotation(floatIndex, realIndex);
                        const rotationString = `rotate3d(${rotation.x}, ${rotation.y}, ${rotation.z}, ${rotation.angle}deg)`;

                        return `${translationString} ${rotationString} ${scaleString}`;
                      })
                    }}
                  >
                    <Visible alwaysVisible={preloadSideItems} className="selector-item">
                      {children(items[actualIndex], actualIndex)}
                    </Visible>
                  </animated.div>
                );
              });
            })()}
        </div>
      );
    }
  )
);

Selector.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  initialIndex: PropTypes.number,
  wrap: PropTypes.bool,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  springConfig: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  swipeThreshold: PropTypes.number,
  sideItemCount: PropTypes.number,
  preloadSideItems: PropTypes.bool,
  itemDynamicScale: PropTypes.func,
  itemDynamicRotation: PropTypes.func,
  itemDynamicOpacity: PropTypes.func,
  onIndexChange: PropTypes.func,
};

export default Selector;
