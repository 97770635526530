import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Strings from "../../../helpers/strings";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import resource from "../../../helpers/resource";
import Scroller from "../../../react/components/scroller";
import Env from "../../../helpers/env";
import Timeout from "../../../helpers/timeout";
import Button from "../button";

class ErrorBoundary extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
    renderError: PropTypes.func,
  };

  state = {
    error: null,
  };

  render() {
    return (
      <div className={Classes.build("ripple-error-boundary", this.props.className)} style={this.props.style}>
        {this.state.error ? this.renderError() : this.props.children}
      </div>
    );
  }

  renderError() {
    return <div className="error">{this.renderActualError()}</div>;
  }

  renderActualError() {
    if (this.props.renderError) return this.props.renderError(this.state.error.message);
    return this.renderDefaultError(this.state.error.message);
  }

  renderDefaultError = (message) => {
    return (
      <Fragment>
        <img src={resource("images/error.png")} />
      </Fragment>
    );
  };

  componentDidCatch = (error, info) => {
    this.setState({ error: error });
  };
}

export class CoreErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    return (
      <ErrorBoundary
        {...this.props}
        className={Classes.build("core", this.props.className)}
        renderError={this.renderError}
      >
        {this.props.children}
      </ErrorBoundary>
    );
  }

  renderError = (message) => {
    return __DEV__ || Config.testing ? this.renderDevError(message) : this.renderUserFriendlyError();
  };

  renderDevError = (message) => {
    return (
      <div className="dev-error">
        <div className="title-bar">Uncaught Render Exception</div>
        <Scroller fadeRatio={0.02} scrollbarSideInset={6} scrollbarStartInset={20} scrollbarEndInset={20}>
          <p className="message">
            <code>{message}</code>
          </p>
        </Scroller>
      </div>
    );
  };

  renderUserFriendlyError = () => {
    return (
      <>
        <h1>{Strings.localized("PageErrorTitle").value}</h1>
        <h2>{Strings.localized("PageErrorSubtitle").value}</h2>
        {Env.isREC && <p>{Strings.localized("PageErrorTip").value}</p>}
        <Button className="restart" onClick={this.onRestartClick} href="#">
          {Strings.localized("PageErrorRestart")}
        </Button>
      </>
    );
  };

  onRestartClick = () => {
    Timeout.force();
    window.location.reload();
  };
}

export default ErrorBoundary;
