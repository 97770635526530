import { memo, forwardRef } from "react";
import PropTypes from "prop-types";

import { MediaSrcPropType } from "../../../logic/prop-types";
import ThreeFiberCanvas from "../three-fiber-canvas";
import ThreeFiberOrbitControls from "../three-fiber-orbit-controls";
import ThreeFiberModel from "../three-fiber-model";
import Classes from "../../../helpers/classes";

/** A simple 3D model viewer */
const Model = memo(
  forwardRef(
    (
      {
        className,
        src, // The path to a glTF 2.0 scene (either a GLB or a `.gltf` with relative files)
        disableGestures = false,
        initialDistance = 2, // The distance from the objet in units
        minDistance = 1,
        maxDistance = 3,
        ...rest
      },
      ref
    ) => {
      return (
        <div ref={ref} {...rest} className={Classes.build("ripple-model", className)}>
          <ThreeFiberCanvas camera={{ position: [0, 0, initialDistance] }} style={{ backgroundColor: "white" }}>
            {!disableGestures && <ThreeFiberOrbitControls minDistance={minDistance} maxDistance={maxDistance} />}
            <ambientLight intensity={0.8} />
            <pointLight position={[10, 10, 10]} intensity={0.5} />
            <ThreeFiberModel src={src} showLoadingIndicator />
          </ThreeFiberCanvas>
        </div>
      );
    }
  )
);

Model.propTypes = {
  className: PropTypes.string,
  src: MediaSrcPropType,
  initialDistance: PropTypes.number,
  minDistance: PropTypes.number,
  maxDistance: PropTypes.number,
  disableGestures: PropTypes.bool
};

export default Model;
