// Rotation Functions : The item's scale can be controlled by providing one
// of the scaling functions below to `itemDynamicScale` or creating your own in a custom app.

/** Perform no scaling */
export const scaleOff = () => (scrollIndex, focusedIndex) => {
  return 1;
};

/** Scale the unfocused (non-current) items */
export const scaleUnfocused = (factor) => (scrollIndex, focusedIndex) => {
  return 1 - (1 - factor) * Math.min(1, Math.abs(scrollIndex - focusedIndex));
};

/* Scale up the previous items and scale down the next items */
export const scaleDescending = (factor) => (scrollIndex, focusedIndex) => {
  return 1 - (focusedIndex - scrollIndex) * factor;
};

/* Scale down the previous items and scale up the next items */
export const scaleAscending = (factor) => (scrollIndex, focusedIndex) => {
  return 1 - (scrollIndex - focusedIndex) * factor;
};
