import "regenerator-runtime/runtime";
import _ from "lodash";
import { Promise } from "es6-promise";
import { Observable, Subject, empty } from "rxjs";
import { distinctUntilChanged, tap, pairwise } from "rxjs/operators";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import WebFont from "webfontloader";

import IpcClient from "ripple.ipc-client";

import LocationHelper from "../helpers/internal/location-helper";

import Log from "../helpers/log";
import Config, { loadConfig, mergeConfig } from "../helpers/config";
import Strings, { loadStrings } from "../helpers/strings";
import Env from "../helpers/env";
import GPIO from "../helpers/gpio";
import Keyboard from "../helpers/keyboard";

import RemoteStateActions from "../redux/actions/local/remote-state-actions";
import PropsForwardingRoute from "../react/components/props-forwarding-route";

import Root from "../react/root";
import MasterActions from "../redux/actions/master/master-actions";
import FingerprintActions from "../redux/actions/master/fingerprint-actions";
import TimeoutActions from "../redux/actions/local/timeout-actions";
import resource from "../helpers/resource";
import API from "../helpers/api";
import Localization from "../helpers/localization";
import AnalyticsUploader from "../logic/analytics/analytics-uploader";
import Mail from "../helpers/mail";
import Signal from "../helpers/signal";

function showBootstrapError(error) {
  Log.error("Failed to start app!");
  Log.error(error.stack);

  Env.isRCC
    ? navigator.notification.alert(
        `Failed to start app: ${error.message}`,
        () => navigator.app.exitApp(),
        "Error",
        "OK"
      )
    : alert(`Failed to start app: ${error.message}`);
}

function tryParseConfigOverrides() {
  try {
    const string = LocationHelper.getValue("config");
    if (!string) return; // No overrides specified
    return JSON.parse(string);
  } catch (error) {
    showBootstrapError("Invalid config overrides format! Please provide a valid JSON object.");
  }
}

// Technically all Android phones have the Android System WebView installed
// but when it's disabled it will default to the version shipped with the phone,
// which might be too old for our app.
// Reference: https://github.com/NoNameProvided/cordova-plugin-webview-checker
function checkWebViewVersion(proceed) {
  // eslint-disable-next-line no-undef
  plugins.webViewChecker
    .getCurrentWebViewPackageInfo()
    .then((packageInfo) => {
      if (packageInfo.versionCode >= 349710065 /* WebView Version 69 */) {
        proceed();
        return;
      }

      navigator.notification.confirm(
        Strings.localized("AndroidWebViewCheckerMessage", Localization.getDefaultLanguage()).value,
        (result) => {
          switch (result) {
            case 1:
              // eslint-disable-next-line no-undef
              plugins.webViewChecker
                .openGooglePlayPage("com.google.android.webview")
                .then(function () {
                  navigator.app.exitApp();
                })
                .catch(function (error) {
                  showBootstrapError(error);
                });
              break;
            default:
              navigator.app.exitApp();
              break;
          }
        },
        "Attention",
        [
          Strings.localized("AndroidWebViewCheckerUpdate", Localization.getDefaultLanguage()).value,
          Strings.localized("AndroidWebViewCheckerCancel", Localization.getDefaultLanguage()).value,
        ]
      );
    })
    .catch(function (error) {
      showBootstrapError(error);
    });
}

function initializePushNotificationService() {
  if (__DEV__) window.plugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

  const onNotificationOpen = (jsonData) => {
    Log.info("OneSignal: Notification opened!", JSON.stringify(jsonData));
  };

  window.plugins.OneSignal.startInit(Config.push.appId)
    .handleNotificationOpened(onNotificationOpen)
    .iOSSettings({ kOSSettingsKeyAutoPrompt: false, kOSSettingsKeyInAppLaunchURL: false })
    .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
    .endInit();

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
    Log.info(`OneSignal: ${accepted ? "User accepted notifications" : "User rejected notifications"}`);
  });
}

function initializeApp(options) {
  Log.info("Starting up Ripple..."); // The first call to `Log.xyz()` must occur after the config is loaded

  // Note: We require most Ripple code in here because this allows use to assume that `Config`
  // is fully loaded (including value overrides) within the various source files, simplifying setup a great deal.

  const buildIdentifier = Config.buildIdentifier;
  Log.info(`App build is ${buildIdentifier ? buildIdentifier : "undefined (not built by CI)"}`);

  // Apply dynamic config overrides.
  // It is possible to override any config values by providing JSON through the `config` query string key:
  // http://myserver.com/somepage?config={"timeout":0}
  // The navigation logic automatically preserves the config overrides on URL changes.
  const overrides = tryParseConfigOverrides();
  if (overrides) mergeConfig(overrides);

  // Log the final merged config values for easy diagnosis from the console
  /* eslint-disable no-console */
  if (console) console.log("Final config: ", Config);
  /* eslint-enable no-console */

  // Load web fonts synchronously using webfontloader, which ensures that
  // fonts are preloaded when performing the occasional UI measurement in components.
  // https://github.com/typekit/webfontloader

  // Core fonts
  WebFont.load({
    custom: {
      families: ["open_sansregular"],
      urls: [resource("fonts/opensans/stylesheet.css"), resource("fonts/font-awesome-5.1.1/css/all.min.css")],
    },
  });

  // Custom fonts
  const fonts = options.fonts;
  if (!_.isUndefined(fonts) && !_.isNull(fonts) && !_.isEmpty(fonts)) WebFont.load(fonts);

  // Media Client
  const MediaClient = require("mediaclient.js");
  require("../extensions/node-extensions"); // Add useful capabilities to all Node objects

  // Analytics

  const AnalyticsObservables = require("../rx/analytics-observables").default;
  const analyticsObservables = AnalyticsObservables.initialize();

  const AnalyticsDb = require("../logic/analytics/analytics-db").default;
  AnalyticsDb.initialize((db) => {
    analyticsObservables.eventStream$.subscribe(db.put); // Save all events to the local analytics DB
    analyticsObservables.start(); // See comments in AnalyticsObservables
  });

  const Analytics = require("../helpers/analytics").default;
  Analytics._setup(analyticsObservables.emit);
  Analytics.track("startup", { platform: Env.platform, fingerprint: Analytics._fingerprint });

  if (options.stats) Analytics._customStats = options.stats;

  const host = window.location.host;
  const hostShouldBeExcluded =
    _.filter(Config.analytics.upload.excludedHosts, (exclusion) => host.includes(exclusion)).length > 0;

  if (Config.dev.forceAnalyticsUpload || (Config.analytics.upload.enabled && !__DEV__ && !hostShouldBeExcluded)) {
    const uploader = new AnalyticsUploader(
      Config.analytics.appId,
      Config.analytics.server.url,
      Config.analytics.server.username,
      Config.analytics.server.password
    );

    setInterval(uploader.sync.bind(uploader), Config.analytics.upload.interval);

    // Sync analytics at startup
    // - To instantly track unique users and platform distribution on launch
    // - In case there were unsent events from a previous run
    uploader.sync();

    // Sync events when the system puts the app into the background
    if (Env.isRCC) document.addEventListener(Env.isIos ? "resign" : "pause", () => uploader.sync(), false);
  }

  // Local Store
  const localReducer = options.localReducer ? options.localReducer() : () => null; // Represents the app-specific local state
  const localEpic = options.localEpic ? options.localEpic() : () => empty(); // The root epic that reacts to local actions
  const LocalStore = require("../redux/local-store").default;
  const { localStore, history } = LocalStore.createStore(localReducer, localEpic);

  // Master Store (dormant on IPC slaves)
  const masterReducer = options.masterReducer ? options.masterReducer() : () => null; // Represents the state shared by all IPC clients
  const masterEpic = options.masterEpic ? options.masterEpic() : () => empty(); // The root epic that reacts to IPC actions
  const MasterStore = require("../redux/master-store").default;
  const masterStore = MasterStore.createStore(masterReducer, masterEpic, localStore);

  const masterStoreState$ = Observable.create((observer) => {
    masterStore.subscribe(() => observer.next(masterStore.getState()));
  });
  masterStoreState$
    .pipe(
      distinctUntilChanged((s1, s2) => s1.fingerprint === s2.fingerprint),
      pairwise(), // Consider the previous state
      tap(() => Log.info("Master: Broadcasting new remote state"))
    )
    .subscribe((states) => IpcClient.updateRemoteState(states[1].shared));

  // Provide the store dispatch function to interested parties
  // (so that helpers can send Redux actions on behalf of components)
  const Localization = require("../helpers/localization").default;
  Localization._store = localStore;
  const Toast = require("../helpers/toast").default;
  Toast._store = localStore;

  // Routes
  const checkIfRootPage = (fullUrl) => fullUrl === "/" || fullUrl === Config.home;

  // Configure the page mapping (page mappings are optional)
  const NavConfigurator = require("./nav-configurator").default;
  NavConfigurator.configure(options.nav);

  // Navigator
  const Navigator = require("../helpers/navigator").default;
  Navigator._store = localStore;
  Navigator._checkIfRootPage = checkIfRootPage;
  const navigateToHome = () => Navigator.navigate({ path: Config.home });
  Env._subscribeToReceiveFromContainer("navigate", (payload) => Navigator.navigate(payload));
  Env._subscribeToReceiveFromContainer("go-back", Navigator.goBack);
  Env._subscribeToReceiveFromContainer("go-forward", Navigator.goForward);
  Env.addContainerNavigationItem("Go to Home", { path: "/" });
  Env.addContainerNavigationItem("Go to Config", { path: "/config" });
  Env.addContainerNavigationItem("Go to Analytics", { path: "/analytics" });
  Env.addContainerNavigationItem("Go to Demos", { path: "/demos" });
  if (Env.isAndroid) {
    document.addEventListener("backbutton", (event) => {
      event.preventDefault();
      Navigator.goBack();
    });
  }

  // Interactions
  const interactionObservables = require("../rx/interaction-observables").default.initialize();
  const Interaction = require("../helpers/interaction").default;
  Interaction._store = localStore;
  Interaction._interactions$ = interactionObservables.interactions$;
  window.addEventListener("message", (m) => {
    if (m.data === "interaction") Interaction.emit(m.data);
  }); // Emit an interaction on global interaction message (`postMessage()` call)
  _.each([...("PointerEvent" in window ? ["pointerup"] : ["mouseup", "touchend"]), "keydown", "drop"], (name) =>
    document.addEventListener(name, (e) => Interaction.emit(e.type))
  ); // Emit on direct interaction

  // Store observations
  let autoReloadIntervalToken;
  const storeObservables = require("../rx/store-observables").default.initialize(localStore);
  storeObservables.dataUpdateWhileOnStartupPage$.subscribe(() => setTimeout(() => navigateToHome(), 1000));
  storeObservables.navigationToStartupPageAfterAppStart$.subscribe(navigateToHome);
  storeObservables.dataAutoReloadFlagChanged$.subscribe((flag) => {
    if (flag) {
      // eslint-disable-next-line no-use-before-define
      autoReloadIntervalToken = setInterval(() => loadData(), Config.data.autoReloadInterval);
    } else {
      clearInterval(autoReloadIntervalToken);
    }
  });

  // Timeout
  const timeoutObservables = require("../rx/timeout-observables").default.initialize(
    interactionObservables.interactions$
  );
  timeoutObservables.grace$.subscribe((remaining) => localStore.dispatch(TimeoutActions.updateGrace(remaining)));
  timeoutObservables.timeout$.subscribe((info) => {
    const location = localStore.getState().router.location;
    const fullPath = LocationHelper.urlFromLocation(location);
    const shouldTimeout = options.timeoutOverride?.shouldTimeout || (() => true);

    // Do not timeout if we're on the startup page or home page, or if the timeout
    // override says we shoudn't. We run shouldTimeout before the "root page" check
    // so that it's always called regardless of the page we're on.
    if (!shouldTimeout(localStore) || checkIfRootPage(fullPath)) return;

    Log.info(`Timed out`);
    Analytics.track("timeout", { forced: info.forced }); // Important: Before navigation, for analytics event sequence coherence!

    // What happens by default when timing out
    const defaultTimeoutActions = () => {
      if (Config.language.resetOnTimeout) setTimeout(() => Localization.switchToDefaultLanguage(), 250);
      navigateToHome();
    };

    const overrideTimeoutActions = options.timeoutOverride?.timeoutActions;
    if (overrideTimeoutActions) {
      // Provide the default actions so the override can optionally run them
      overrideTimeoutActions(defaultTimeoutActions);
    } else {
      defaultTimeoutActions();
    }

    // Force-unblock interaction as a safety measure,
    // in case it got stuck due to incorrect custom app logic.
    // Do it after a delay to avoid cancelling out the timeout
    // navigation interaction block itself.
    setTimeout(() => Interaction.unblock("timeout"), 500);

    // Start a new session last, after other actions have had the
    // opportunity to add final analytics events in the current session
    Analytics._newSession();
  });
  const Timeout = require("../helpers/timeout").default;
  Timeout._observables = timeoutObservables;

  // Perform the initial data fetch
  const url = Config.server.url;
  const apiVersion = Config.server.apiVersion;
  const username = Config.server.username;
  const password = Config.server.password;
  const clientId = Config.server.clientId;

  const apiClient = new MediaClient.ApiClient(url, apiVersion, username, password);

  // Update client connection info in the store
  const DataActions = require("../redux/actions/local/data-actions").default;
  localStore.dispatch(DataActions.updateServerInfo(apiClient.serverInfo));

  // Log media client requests
  const mediaClientObservables = require("../rx/mediaclient-observables").default.initialize(apiClient);
  mediaClientObservables.request$.subscribe((m) => Log.info("MediaClient: " + m));

  // Load data

  function throwIfServerUnreachable(displayError = false) {
    const pingTimeout = 30000;
    return apiClient.ping(pingTimeout).catch(() => {
      if (displayError) localStore.dispatch(DataActions.serverUnreachable());
      throw new Error("Server is unreachable");
    });
  }

  function loadData(performFetch, fetchDone = () => {}) {
    const dataSource = Config.data.source;
    if (dataSource === "server" && !performFetch) {
      throwIfServerUnreachable(/* Display Error */ true).then(() =>
        localStore.dispatch(DataActions.loadServerData(apiClient, clientId))
      );
    } else if (dataSource === "server" && performFetch) {
      // If this throws, the fetch is skipped and we go straight to local data load
      throwIfServerUnreachable()
        // However, if *this* throws, it won't be caught because it's an async dispatch!
        .then(() => localStore.dispatch(DataActions.loadFetchedData(apiClient, clientId, fetchDone)))
        // We only get here if the server is unreachable, thus we can consider that the "real" cause for
        // local data load failure is a failure to fetch the data from the server a first time.
        .catch(() => localStore.dispatch(DataActions.loadLocalData(apiClient, DataActions.serverUnreachable())));
    } else if (dataSource === "local") {
      localStore.dispatch(DataActions.loadLocalData(apiClient));
    } else if (dataSource === "none") {
      localStore.dispatch(DataActions.loadEmptyData());
    } else {
      // Assumes that the dataSource is the url to a static JSON file
      localStore.dispatch(DataActions.loadStaticData(dataSource));
    }
  }

  let fetchMode = Config.data.fetch;

  if (!Env.isRCC && fetchMode === "ask") {
    fetchMode = "always";
    Log.info("Fetch: Falling back to 'always' fetch mode because 'ask' can't be used outside of RCC");
  }

  if (fetchMode === "always" && !Env.isContained) {
    fetchMode = "never";
    Log.info("Fetch: Falling back to 'never' fetch mode because 'always' can't be used outside of a container");
  }

  if ((Env.isREC || Env.isRCC) && (fetchMode === "always" || fetchMode === "ask") && __DEV__) {
    // The "fetch" data source can't be used in REC dev mode because `webpack-dev-server` hosts everything in memory,
    // which causes the fetched data not to be picked up because it's created after the server is launched.
    // Plus it would fill our dev machines with data pretty fast, which is bad!

    // The "fetch" data source also can't be used in RCC in dev mode, but for another reason: when the app is
    // packaged,both the app itself and the special paths to access OS directories (`window.Ionic.WebView.convertFileSrc(...)`)
    // have the same "ionic:" protocol and are considered as being on the same origin, which works perfectly fine.
    // However, when the app is loaded from a dev server, the app's origin is "http://dev_machine_ip:port" and the
    // special "ionic:" paths are considered cross-origin, which awakens CORS the Destroyer of Worlds and breaks
    // everything!

    fetchMode = "never";
    Log.info("Fetch: Falling back to 'never' fetch mode because 'always' can't be used in dev mode");
  }

  if (fetchMode === "always") {
    loadData(true);
  } else if (fetchMode === "never") {
    loadData(false);
  } else if (fetchMode === "ask") {
    const fetchPerformedAtLeastOnceKey = "fetch-performed-at-least-once";
    const alreadyFetchedAtLeastOnce = JSON.parse(localStorage.getItem(fetchPerformedAtLeastOnceKey)) || false;

    if (alreadyFetchedAtLeastOnce) {
      // Workaround: If we already fetched once, always fetch from now on, assuming that subsequent fetches will be quick.
      // This is to prevent the user from choosing "no" which puts the app in "server" mode and always makes requests to
      // the server, ignoring local data. This is necessary for now because we don't have a way to download, cache and use
      // files locally on-the-fly (on request) yet, like we do in Muzeus.
      loadData(true);
    } else {
      navigator.notification.confirm(
        Strings.localized("FetchAskMessage").value,
        (result) => {
          switch (result) {
            case 1:
              loadData(true, () => localStorage.setItem(fetchPerformedAtLeastOnceKey, JSON.stringify(true)));
              break;
            default:
              loadData(false);
              break;
          }
        },
        Strings.localized("FetchAskTitle").value,
        [Strings.localized("FetchAskYes").value, Strings.localized("FetchAskNo").value]
      );
    }
  }

  // In-App Data Edits

  const Edits = require("../logic/edits").default;
  Edits._store = localStore;
  Edits._apiClient = apiClient;

  // Global keyboard shortcuts
  const FlagsActions = require("../redux/actions/local/flags-actions").default;
  Keyboard._store = localStore;

  if (!Config.disableAdminShortcuts) {
    Keyboard.shortcutSubscribe("Navigate to Config Page", "ctrl|cmd+c", () => Navigator.navigate({ path: "/config" }), {
      type: "builtin",
    });

    Keyboard.shortcutSubscribe("Save To Server", "ctrl|cmd+s", Edits.save, { type: "builtin" });
  }

  Keyboard.shortcutSubscribe(
    "Toggle Debug Mode",
    "ctrl|cmd+d",
    () => localStore.dispatch(FlagsActions.toggleDebugMode()),
    { type: "hidden" }
  );

  Keyboard.shortcutSubscribe(
    "Toggle Bounds Mode",
    "ctrl|cmd+b",
    () => localStore.dispatch(FlagsActions.toggleBoundsMode()),
    { type: "builtin" }
  );

  Keyboard.shortcutSubscribe("Switch to Next Language", "ctrl|cmd+l", () => Localization.switchToNextLanguage(), {
    type: "builtin",
  });

  Keyboard.shortcutSubscribe(
    "Force Timeout",
    ["ctrl|cmd+backspace", "ctrl|cmd+0"],
    () => timeoutObservables.forceTimeout(),
    {
      type: "builtin",
    }
  );

  Keyboard.shortcutSubscribe(
    "Reload App",
    ["ctrl|cmd+r"],
    // eslint-disable-next-line no-self-assign
    () => (window.location.href = window.location.href),
    {
      type: "builtin",
    }
  );

  Keyboard.shortcutSubscribe("Go Back", ["ctrl|cmd+left"], Navigator.goBack);

  Keyboard.shortcutSubscribe("Go Forward", ["ctrl|cmd+right"], Navigator.goForward);

  Keyboard.shortcutSubscribe(
    "Reload Data",
    "ctrl|cmd+enter",
    () => {
      Toast.info("Reloading Data...");
      loadData();
    },
    {
      type: "builtin",
    }
  );

  Keyboard.shortcutSubscribe(
    "Toggle Data Auto Reload",
    "ctrl|cmd+shift+enter",
    () => localStore.dispatch(FlagsActions.toggleDataAutoReload()),
    { type: "builtin" }
  );

  Keyboard.shortcutSubscribe("Toggle Path bar", "ctrl|cmd+p", () => localStore.dispatch(FlagsActions.togglePathBar()), {
    type: "builtin",
  });

  Keyboard.shortcutSubscribe("Undo Remote State Update", "ctrl|cmd+z", () => IpcClient.undoLastRemoteStateUpdate(), {
    type: "builtin",
  });

  // Enable or disable socket.io logging
  if (Config.dev.debugSocketIO) {
    localStorage["debug"] = "*";
  } else {
    delete localStorage["debug"];
  }

  // Setup an Observable for IPC actions
  const ipcActions$ = new Subject();

  // Connect to IPC server if applicable
  if (Config.ipc.enabled) {
    const url = Config.ipc.url;
    const isMaster = Config.ipc.role === "master";

    const connectionOptions = {
      onConnect: () => {
        if (!isMaster) return;

        // Will automatically be rejected by the server if the initial
        // state has already been received once.
        Log.info("Master: Sending initial remote state (will be ignored by the server if already initialized)");
        IpcClient.sendInitialRemoteState(masterStore.getState().shared);
      },
      onRestoreSharedState: (state) => {
        if (!isMaster) return;

        Log.info("Master: Restoring shared state");
        masterStore.dispatch(MasterActions.restoreSharedState(state));
      },
      onAction: (data) => {
        const dispatchAction = (action) => {
          // Allow the master store to update the shared state (master only).
          if (isMaster) masterStore.dispatch(action);

          // Allow the local store to respond to IPC actions
          localStore.dispatch(action);

          // Let interested parties observe incoming IPC actions
          ipcActions$.next(action);
        };

        // Dispatching an action will trigger a remote state update to be
        // broadcast to all IPC clients. We support dispatching single
        // actions or multiple actions at once.
        if (Array.isArray(data.payload)) {
          _.each(data.payload, dispatchAction);
        } else {
          dispatchAction(data.payload);
        }

        // The fingerprint update is what triggers the actual IPC shared state update.
        // Waiting for the fingerprint to change has the effect of batching all
        // previous dispatches into a single remote state update.
        if (isMaster) masterStore.dispatch(FingerprintActions.updateSharedFingerprint());
      },
      onUpdateRemoteState: (state) => {
        // This handles updating `state.remote` with new shared state
        // provided by the server. Here we send the action to the local
        // store because we want to update our local copy of the remote
        // state (`localReducer.remote`), not the master's shared state
        // store (if we're the master).
        localStore.dispatch(RemoteStateActions.update(state));
      },
    };

    IpcClient.connect(url, Config.id, connectionOptions);
  }

  // Connect to the GPIO device if provided
  const gpioPortName = Config.gpio.portName;
  if (gpioPortName) GPIO._connect(gpioPortName, options.onGPIOConnect);

  // Start the HTTP API
  const httpApiPortNumber = Config.api.port;
  if (httpApiPortNumber) API._start(httpApiPortNumber);

  // Connect to the signaling server
  if (Config.signaling.enabled) Signal._connect(Config.signaling.server.url);

  // Start the email processing queue
  Mail._startProcessing();

  // Keep the device awake in DEV mode on mobile devices
  if (Env.isRCC && __DEV__) window.plugins.insomnia.keepAwake();

  if (Env.isRCC && Config.push.enabled) initializePushNotificationService();

  // Define context
  const rippleContext = {
    store: localStore,
    apiClient,
    observables: {
      timeout: timeoutObservables,
      interaction: interactionObservables,
      store: storeObservables,
      ipc: { actions$: ipcActions$ },
    },
  };

  // Setup the app root
  const RippleContext = require("./ripple-context").default;
  const app = (
    <ReduxProvider store={localStore}>
      <RippleContext.Provider value={rippleContext}>
        <ConnectedRouter history={history}>
          <PropsForwardingRoute path="/" component={Root} appRoot={options.appRoot} />
        </ConnectedRouter>
      </RippleContext.Provider>
    </ReduxProvider>
  );

  // Render the React DOM
  ReactDOM.render(app, options.element);

  // Call back when the Ripple boostrap is done
  options.onBootstrap?.(rippleContext);

  // Call back when the data has been fetched and the app is fully ready
  storeObservables.dataUpdateWhileOnStartupPage$.subscribe(() => options.onStart?.(rippleContext));
}

const App = {
  bootstrap: function (options) {
    const loadConfigPromise = loadConfig(
      resource("data/core-config.yml"),
      resource("data/custom-config.yml"),
      resource("data/local-config.yml[optional]")
    );

    const loadStringsPromise = loadStrings(
      resource("data/core-strings.yml"),
      resource("data/custom-strings.yml"),
      resource("data/local-strings.yml[optional]")
    );

    Promise.all([loadConfigPromise, loadStringsPromise])
      .then(() => {
        if (Env.isRCC) {
          // Wait until Cordova is ready (plugins ready to use) before initializing the app,
          // which simplifies usage of Cordova plugins in the codebase.
          document.addEventListener(
            "deviceready",
            () => {
              if (Env.isAndroid) {
                checkWebViewVersion(() => initializeApp(options));
              } else {
                initializeApp(options);
              }
            },
            false
          );
        } else {
          // Initialize straight away
          initializeApp(options);
        }
      })
      .catch((error) => showBootstrapError(error));
  },
};

export default App;
