import { memo, useEffect, useCallback, useRef, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { usePrevious } from "ripple";
import Classes from "../../../helpers/classes";

const Maximizer = memo(({ className, children, maximized, onMaximize, onMinimize, ...rest }) => {
  const transitionDuration = 300;

  const previousMaximized = usePrevious(maximized);
  const maximizedContentRef = useRef();
  const rootRef = useRef();

  const [showMaximized, setShowMaximized] = useState(false);

  const portalElement = document.getElementById("maximizer-portal");

  const getRect = (div) => {
    const divRect = div.getBoundingClientRect();
    return {
      top: divRect.top + window.pageYOffset,
      left: divRect.left + window.pageXOffset,
      width: divRect.width,
      height: divRect.height,
    };
  };

  const maximize = useCallback(() => {
    const maximizedContent = maximizedContentRef.current;
    if (!maximizedContent) return;

    // Switch to fixed display with the appropriate absolute positioning to remain stationary (for now)
    const rootRect = getRect(rootRef.current); // Capture the display position of the maximizer root (to animate from)
    maximizedContent.style.position = "fixed";
    maximizedContent.style.top = rootRect.top + "px";
    maximizedContent.style.left = rootRect.left + "px";
    maximizedContent.style.width = rootRect.width + "px";
    maximizedContent.style.height = rootRect.height + "px";

    // Show the element (after it's been laid out at the proper location)
    setShowMaximized(true);

    // Animate to fill the maximizer portal
    const maximizerPortalRect = getRect(portalElement);
    setTimeout(() => {
      maximizedContent.style.transition = `all ${transitionDuration}ms ease-in-out`;
      maximizedContent.style.top = maximizerPortalRect.top + "px";
      maximizedContent.style.left = maximizerPortalRect.left + "px";
      maximizedContent.style.width = maximizerPortalRect.width + "px";
      maximizedContent.style.height = maximizerPortalRect.height + "px";
    }, 25);

    if (onMaximize) onMaximize();
  }, [onMaximize, portalElement]);

  const minimize = useCallback(() => {
    const maximizedContent = maximizedContentRef.current;

    const rootRect = getRect(rootRef.current); // Capture the display position of the maximizer root (to animate to)

    maximizedContent.style.top = rootRect.top + "px";
    maximizedContent.style.left = rootRect.left + "px";
    maximizedContent.style.width = rootRect.width + "px";
    maximizedContent.style.height = rootRect.height + "px";

    setTimeout(() => {
      setShowMaximized(false);
      maximizedContent.style = null;
    }, transitionDuration);

    if (onMinimize) onMinimize();
  }, [onMinimize]);

  useEffect(() => {
    if (!previousMaximized && maximized) {
      maximize();
    } else if (previousMaximized && !maximized) {
      minimize();
    }
  }, [maximize, maximized, minimize, previousMaximized]);

  return (
    <div {...rest} ref={rootRef} className={Classes.build("ripple-maximizer", className)}>
      {/* The maximized / showMaximized dance handles animations and momentary overlaps to provide a perfectly smooth animation */}
      {!showMaximized && children && <div className="maximizer-content">{children(false)}</div>}
      {(maximized || showMaximized) &&
        children &&
        ReactDOM.createPortal(
          <div
            ref={maximizedContentRef}
            className="maximizer-content"
            style={{ visibility: showMaximized ? "visible" : "hidden" }}
          >
            {children(true)}
          </div>,
          portalElement
        )}
    </div>
  );
});

Maximizer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func,
  maximized: PropTypes.bool,
  onMaximize: PropTypes.func,
  onMinimize: PropTypes.func,
};

export default Maximizer;
