import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import * as PIXI from "pixi.js";

import Log from "../../../helpers/log";
import Classes from "../../../helpers/classes";
import Styles from "../../../helpers/styles";
import PixiTools from "../../../helpers/pixi-tools";

// This override allows disabing the interactivity of specific displayobjects inside an interactive parent.
// To disable interaction on a specific element, do this: `myDisplayObject.forceNonInteractive = true`.
// Reference: http://pixijs.download/release/docs/PIXI.InteractionManager.html#processInteractive
// Suggested override example: https://github.com/pixijs/pixi.js/wiki/v4-Gotchas#css-transforms-on-canvas
const previous = PIXI.InteractionManager.prototype.processInteractive;
PIXI.InteractionManager.prototype.processInteractive = function (
  interactionEvent,
  displayObject,
  func,
  hitTest,
  interactive
) {
  if (displayObject.forceNonInteractive) return false;
  return previous.bind(this)(interactionEvent, displayObject, func, hitTest, interactive);
};

export default class PixiStage extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    preloadResources: PropTypes.func,
    transparent: PropTypes.bool,
    backgroundColor: PropTypes.number,
    anchor: PropTypes.oneOf(["center", "top-left"]), // Whether to work with "centered everything" or with a more traditional "top-left corner" setup
    autoPreventDefault: PropTypes.bool, // Setting this to false will prevent PIXI from consuming pointer events (useful for external event handlers)
    onStageReady: PropTypes.func,
    onStageFrame: PropTypes.func,
  };

  static defaultProps = {
    transparent: false,
    backgroundColor: 0x000000,
    anchor: "center",
    autoPreventDefault: null, // Defaults to PIXI.js default value
  };

  constructor(props) {
    super(props);

    this.stageDiv = createRef();
  }

  render() {
    return (
      <div
        ref={this.stageDiv}
        className={Classes.build("ripple-pixi-stage design", this.props.className)}
        style={Styles.merge(this.props.style)}
      />
    );
  }

  componentDidMount() {
    this.setupStage();
  }

  componentWillUnmount() {
    this.stop();
    this.destroyApplication();
  }

  setupStage = () => {
    PIXI.utils.skipHello(); // Do not log the PIXI banner

    this.setupApplication(this.stageDiv.current);
    this.loadImageResources(this.start.bind(this));
  };

  setupApplication = () => {
    const width = this.stageDiv.current.clientWidth;
    const height = this.stageDiv.current.clientHeight;
    const options = {
      width,
      height,
      transparent: this.props.transparent,
      backgroundColor: this.props.backgroundColor,
      antialias: true,

      // Retina support
      resolution: window.devicePixelRatio,
      autoDensity: true,
    };

    Log.info(`Initializing PIXI with dimensions ${width} x ${height}`);

    this.app = new PIXI.Application(options);

    if (this.props.autoPreventDefault !== null)
      this.app.renderer.plugins.interaction.autoPreventDefault = this.props.autoPreventDefault;

    this.stageDiv.current.innerHTML = ""; // Empty the root div if it wasn't already empty
    this.stageDiv.current.appendChild(this.app.view);
  };

  destroyApplication = () => {
    if (!this.app) return;

    this.app.stage.destroy(true);
    this.app.destroy(true);

    this.root.destroy(true);

    this.stageDiv.current.innerHTML = "";
  };

  loadImageResources = (callback) => {
    this.app.loader.reset();

    const finishedLoading = () => {
      this.setupRoot();
      callback();
    };

    if (this.props.preloadResources) {
      this.props.preloadResources(this.getDelegateLoadHelper());
      this.app.loader.load(finishedLoading);
    } else {
      finishedLoading();
    }
  };

  loadImageResource = (name, url) => {
    if (this.app.loader.resources[name]) return; // Resource already loaded

    // Force loading as an image to handle extension-less URLs properly
    // http://stackoverflow.com/a/35747210
    const loaderOptions = {
      loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
      xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB,
    };

    this.app.loader.add(name, url, loaderOptions);
  };

  setupRoot = () => {
    this.root = new PIXI.Container();

    if (this.props.anchor === "center") {
      this.root.x = this.stageDiv.current.clientWidth / 2.0;
      this.root.y = this.stageDiv.current.clientHeight / 2.0;
    }

    this.app.stage.addChild(this.root);
  };

  createSprite = (resourceName) => {
    PixiTools.createSprite(resourceName).then((sprite) => {
      if (this.props.anchor === "center") sprite.anchor.set(0.5);
      return sprite;
    });
  };

  start = () => {
    if (this.props.onStageReady) this.props.onStageReady(this.getDelegateStageHelper());

    this.animate = true;
    this.renderStage();
  };

  stop = () => {
    this.animate = false;
  };

  renderStage = () => {
    if (!this.animate) return; // Cancel the animation loop

    requestAnimationFrame(this.renderStage);

    if (this.props.onStageFrame) this.props.onStageFrame(this.getDelegateStageHelper());
    this.app.renderer.render(this.app.stage);
  };

  // These are meant for use in delegate functions (passed as props)
  // to simplify interaction with the stage

  getDelegateLoadHelper = () => {
    return { imageResource: this.loadImageResource };
  };

  getDelegateStageHelper = () => {
    return {
      root: this.root,
      width: this.stageDiv.current.clientWidth,
      height: this.stageDiv.current.clientHeight,
      createSprite: this.createSprite,
    };
  };
}
