import {
  createContext,
  memo,
  Children,
  cloneElement,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Classes } from "../../../ripple";
import Log from "../../../helpers/log";
import { usePrevious } from "../../hooks/use-previous";
import { useStagger } from "../../hooks/specialized/use-stagger";

export const StaggerContext = createContext();
export const StepContext = createContext();

export const StaggerStep = memo(
  ({
    children,
    stagger,
    transition,
    interval,
    order,
    sort,
    delayBefore,
    delayAfter,
    enterTimeout,
    exitTimeout,
    onEnter,
    onExit,
    name,
  }) => {
    const actualTransition = transition || "fade-out-fade-in";
    const enterClassName = `${actualTransition}-enter`;
    const exitClassName = `${actualTransition}-exit`;

    const staggerContext = useContext(StaggerContext);
    const parentId = useContext(StepContext);

    const doneTimeoutRef = useRef();
    const [uniqueId] = useState(() => uuidv4());
    const [className, setClassName] = useState(enterClassName);
    const [transitionState, setTransitionState] = useState(null);
    const previousTransitionState = usePrevious(transitionState);

    // On mount, add ourselves as a step in the provided stagger
    useEffect(() => {
      const actualStagger = stagger || staggerContext;

      // If no stagger instance is provided, the step does not take part in the staggering animation.
      // Its animation is performed instantly so that it is at least visible in the app.
      if (!actualStagger) {
        Log.warn(
          "Step was performed instantly (not staggered) because it does not have access to the stagger " +
            "instance. Either pass the stagger instance through a `stagger` prop on each step or wrap the steps " +
            "in a `StaggerContext` provider."
        );
        return;
      }

      const stepProxy = {
        name,
        // `useStagger` uses the ids to make sure it doesn't register the same step twice
        // and to recreate the step tree to order embedded steps properly in more complex scenarios.
        id: uniqueId,
        parentId,
        delayBefore,
        delayAfter,
        interval,
        order,
        sort,
        enter: () => {
          setTransitionState("enter");
          clearTimeout(doneTimeoutRef.current);
          if (onEnter) onEnter();
          doneTimeoutRef.current = setTimeout(() => setTransitionState("enter-done"), enterTimeout);
        },
        exit: () => {
          setTransitionState("exit");
          clearTimeout(doneTimeoutRef.current);
          if (onExit) onExit();
          doneTimeoutRef.current = setTimeout(() => setTransitionState("exit-done"), exitTimeout);
        },
        resetToEnter: () => {
          setClassName(enterClassName);
          clearTimeout(doneTimeoutRef.current);
        },
        resetToExit: () => {
          setClassName(exitClassName);
          clearTimeout(doneTimeoutRef.current);
        },
      };

      actualStagger.add(stepProxy);
      return () => actualStagger.remove(stepProxy);
    }, [
      name,
      uniqueId,
      enterClassName,
      exitClassName,
      delayAfter,
      delayBefore,
      interval,
      order,
      sort,
      stagger,
      staggerContext,
      exitTimeout,
      enterTimeout,
      onEnter,
      onExit,
      parentId,
    ]);

    // When the transition state changes, react!
    useLayoutEffect(() => {
      const transition = actualTransition;

      // Set the classes in two distinct steps so that CSS transitions can fire properly, mimicking the way
      // `react-transition-group` works. Using `requestAnimationFrame`, we can make sure that the initial
      // class is fully applied before applying the `-active` one.

      if (transitionState === "enter") {
        requestAnimationFrame(() => {
          setClassName(`${transition}-enter`);
          setTransitionState("enter-active");
        });
      } else if (previousTransitionState === "enter" && transitionState === "enter-active") {
        requestAnimationFrame(() => setClassName(`${transition}-enter ${transition}-enter-active`));
      }

      if (transitionState === "enter-done") {
        requestAnimationFrame(() => setClassName(`${transition}-enter-done`));
      }

      if (transitionState === "exit") {
        requestAnimationFrame(() => {
          setClassName(`${transition}-exit`);
          setTransitionState("exit-active");
        });
      } else if (previousTransitionState === "exit" && transitionState === "exit-active") {
        requestAnimationFrame(() => setClassName(`${transition}-exit ${transition}-exit-active`));
      }

      if (transitionState === "exit-done") {
        requestAnimationFrame(() =>
          setClassName(`${transition}-exit ${transition}-exit-active ${transition}-exit-done`)
        );
      }
    }, [previousTransitionState, transitionState, actualTransition]);

    // Clear the "done" timeout on unmount
    useEffect(() => () => clearTimeout(doneTimeoutRef.current), []);

    // Alter the existing children instead of wrapping them in a container
    // to preserve normal layout for the staggered elements.
    // We also expose this step's context to children so that embedded
    // steps can know who their parent is. This allows fancier reordering
    // logic in `useStagger` to accomodate embedded steps.
    return (
      <StepContext.Provider value={uniqueId}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            className: Classes.build(child.props.className, "stagger-step", className),
          })
        )}
      </StepContext.Provider>
    );
  }
);

StaggerStep.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  stagger: PropTypes.object,
  transition: PropTypes.string,
  interval: PropTypes.number,
  order: PropTypes.number,
  sort: PropTypes.oneOf(["sequential", "random", "reverse"]),
  delayBefore: PropTypes.number,
  delayAfter: PropTypes.number,
  enterTimeout: PropTypes.number, // The amount of time after entering where the `-done` class will be set on the element
  exitTimeout: PropTypes.number, // The amount of time after exiting where the `-done` class will be set on the element
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

StaggerStep.defaultProps = {
  delayBefore: 0,
  delayAfter: 0,
  enterTimeout: 1000,
  exitTimeout: 1000,
};

export const Stagger = memo(({ children, options }) => {
  const stagger = useStagger(options);
  return <StaggerContext.Provider value={stagger}>{children}</StaggerContext.Provider>;
});

Stagger.propTypes = {
  children: PropTypes.node,
  options: PropTypes.object,
};

// This HOC-creator allows wrapping a component in a StaggerStep,
// which allows much cleaner usage with JSS libraries such as styled-components.
// The `stagger` prop must be provided on the component generated by this HOC for
// it to take part in the stagger animation.
export const staggerStep = (stepProps = {}) => (WrappedComponent) => {
  return forwardRef((wrappedProps, ref) => {
    return (
      <StaggerStep
        stagger={wrappedProps.stagger} // If provided forward the stagger instance to the step
        {...stepProps} // First, apply the props provided in styles (analog to CSS-provided styles)
        {...(wrappedProps.stepOptions || {})} // Then apply props passed directly to the wrapped component (analog to a `style` prop on a component)
      >
        <WrappedComponent ref={ref} {...wrappedProps} />
      </StaggerStep>
    );
  });
};
