import NullDecoration from "./decorations/null-decoration";

const TransparentSource = function (identifier, decoration = new NullDecoration()) {
  this.identifier = identifier;
  this.decoration = decoration; // An object that conforms to the TiledMap decoration API
};

// Here, we provide metadata with a single level configured for a single tile (with same schema as a generated tileset's
// meta.json file). Combined with `getTileUrl` (below), this allows the tileset to be comprised of a single image.
TransparentSource.prototype.getMeta = function (stageSize) {
  return Promise.resolve({
    maxZoom: 1,
    viewportSize: {
      width: stageSize.width,
      height: stageSize.height,
    },
    levels: [
      {
        size: {
          width: stageSize.width,
          height: stageSize.height,
        },
        number: 0,
        viewportScale: 1,
        tile: {
          size: {
            width: stageSize.width,
            height: stageSize.height,
          },
          subdivisions: {
            horizontal: 1,
            vertical: 1,
          },
        },
        sourceScale: 1,
      },
    ],
  });
};

// We ignore the level, row and column because we only support a single tile
TransparentSource.prototype.getTileUrl = function (level, row, column) {
  return null;
};

export default TransparentSource;
