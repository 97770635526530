// This class manages a "load state" and delegates the actual loading to the
// caller. Its internal load state is modified in a mutex-like manner, to
// "sequence" load and unload operations without stacking them up. The final
// load state is guaranteed to represent the last requested state. This means
// that `load()` and `unload()` can be called as frequently as required, in
// whatever order and the LoadManager will ensure that the proper final state is
// applied while avoiding invalid states in-between (such as unloading while a
// load is ongoing). This has a similar effect to sequencing the load and unload
// operations with `sequencer.js`, but without the performance hit of having one
// sequencer per loadable object.

class LoadManager {
  get loaded() {
    return this.internalState === LoadManager.State.LOADED;
  }
  set loaded(value) {
    value ? this.load() : this.unload();
  }

  constructor(performLoad, performUnload) {
    this.wantedState = LoadManager.State.UNLOADED;
    this.internalState = LoadManager.State.UNLOADED;
    this.performLoad = performLoad;
    this.performUnload = performUnload;
  }

  updateInternalState() {
    // After each async operation is finished, update the state again to ensure
    // that the final, actual state matches the last wanted state.
    if (this.wantedState === LoadManager.State.LOADED && this.internalState === LoadManager.State.UNLOADED) {
      this.enterLoadedState(this.updateInternalState.bind(this));
    } else if (this.wantedState === LoadManager.State.UNLOADED && this.internalState === LoadManager.State.LOADED) {
      this.enterUnloadedState(this.updateInternalState.bind(this));
    }
  }

  enterLoadedState(onComplete) {
    this.internalState = LoadManager.State.LOADING;
    this.performLoad(() => {
      this.internalState = LoadManager.State.LOADED;
      onComplete();
    });
  }

  enterUnloadedState(onComplete) {
    this.internalState = LoadManager.State.UNLOADING;
    this.performUnload(() => {
      this.internalState = LoadManager.State.UNLOADED;
      onComplete();
    });
  }

  // This function is idempotent and can be called as often as desired.
  load() {
    this.wantedState = LoadManager.State.LOADED;
    this.updateInternalState();
  }

  // This function is idempotent and can be called as often as desired.
  unload() {
    this.wantedState = LoadManager.State.UNLOADED;
    this.updateInternalState();
  }
}

LoadManager.State = {
  UNLOADED: 0,
  LOADING: 1, // Private
  LOADED: 2,
  UNLOADING: 3, // Private
};

export default LoadManager;
