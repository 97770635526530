import _ from "lodash";
import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Classes from "../../../helpers/classes";
import Image from "../image";
import Video from "../video";
import getSrcType from "../../../helpers/internal/get-src-type";
import { MediaSrcPropType } from "../../../logic/prop-types";

class Media extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    src: MediaSrcPropType,
    children: PropTypes.node,
    onLoad: PropTypes.func, // Common interface for media readiness
  };

  render() {
    const className = Classes.build("ripple-media", this.props.className);
    const props = _.assign({}, this.props, { className: className });
    const src = this.props.src;

    // Render the appropriate component based on media type
    const type = getSrcType(src);
    if (!type) return null;
    if (type === "image") return this.renderImage(src, props);
    if (type === "video") return this.renderVideo(src, props);
    throw new Error(`Unsupported media type '${type}'`);
  }

  renderImage = (src, props) => {
    return <Image {...props} src={src} />;
  };

  renderVideo = (src, props) => {
    return <Video {...props} src={src} />;
  };
}

export default connect((state) => ({ language: state.language }))(Media);
