import _ from "lodash";
import { PureComponent } from "react";
import { v4 as uuidv4 } from "uuid";
import Point from "../../types/point";
import Config from "../../helpers/config";
import Classes from "../../helpers/classes";
import Interaction from "../../helpers/interaction";
import Audio from "../../helpers/audio";
import resource from "../../helpers/resource";

function getNativeTouchEvent(syntheticTouchEvent) {
  return syntheticTouchEvent.changedTouches[0];
}

function getTouchNumber(syntheticTouchEvent) {
  return getNativeTouchEvent(syntheticTouchEvent).identifier;
}

function getTouchCoordinates(syntheticTouchEvent) {
  const nativeTouchEvent = getNativeTouchEvent(syntheticTouchEvent);
  return new Point(nativeTouchEvent.clientX, nativeTouchEvent.clientY);
}

function LogicalTouch(number, startPoint) {
  this.id = uuidv4();
  this.number = number;
  this.startPoint = startPoint;
}

function VisualTouch(logicalTouch) {
  this.logicalTouch = logicalTouch;
  this.state = null;
}

class TouchFeedback extends PureComponent {
  state = {
    visualTouches: [],
  };

  render() {
    return <div className="touch-feedback">{_.map(this.state.visualTouches, this.renderTouch)}</div>;
  }

  renderTouch = (visualTouch) => {
    const touchDiameter = Config.touchFeedback.diameter;
    const touchRadius = touchDiameter / 2.0;
    const style = {
      left: `${visualTouch.logicalTouch.startPoint.x - touchRadius}px`,
      top: `${visualTouch.logicalTouch.startPoint.y - touchRadius}px`,
      width: `${touchDiameter}px`,
      height: `${touchDiameter}px`,
      borderRadius: `${touchRadius}px`,
    };

    const className = Classes.build("touch", visualTouch.state);
    return <div key={visualTouch.logicalTouch.id} className={className} style={style} />;
  };

  componentDidMount() {
    this.logicalTouches = [];

    // Passing `true` as the third argument registers handlers in `Event.CAPTURING_PHASE`,
    // which allows the touch feedback to work even when we stop event propagation in our components.
    document.documentElement.addEventListener("touchstart", this.touchStart, true);
    document.documentElement.addEventListener("touchend", this.touchEnd, true);
    document.documentElement.addEventListener("touchcancel", this.touchCancel, true);
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener("touchstart", this.touchStart);
    document.documentElement.removeEventListener("touchend", this.touchEnd);
    document.documentElement.removeEventListener("touchcancel", this.touchCancel);
  }

  touchStart = (event) => {
    const number = getTouchNumber(event);
    const startPoint = getTouchCoordinates(event);

    this.logicalTouches.push(new LogicalTouch(number, startPoint));
  };

  touchEnd = (event) => {
    const number = getTouchNumber(event);
    const endPoint = getTouchCoordinates(event);

    const touch = _.find(this.logicalTouches, (t) => t.number === number);

    const clickThreshold = Config.touchFeedback.tapThreshold;
    const pastThreshold = Math.abs(endPoint.distanceTo(touch.startPoint)) > clickThreshold;
    if (!pastThreshold && !Interaction.blocked()) {
      this.showVisual(touch);
      this.playAudio();
    }

    _.remove(this.logicalTouches, (t) => t.number === number);
  };

  touchCancel = (event) => {
    this.touchEnd(event);
  };

  showVisual = (logicalTouch) => {
    if (!Config.touchFeedback.enabled) return;

    // Add the touch to the list of touches to render
    const newTouch = new VisualTouch(logicalTouch);
    this.setState({ visualTouches: this.updatedVisualTouches(logicalTouch.id, newTouch) });

    // Remove it after some delay
    setTimeout(() => this.setState({ visualTouches: this.updatedVisualTouches(logicalTouch.id, null) }), 500);
  };

  updatedVisualTouches = (id, visualTouch) => {
    const visualTouches = this.state.visualTouches.slice(0); // Make a copy
    _.remove(visualTouches, (t) => t.logicalTouch.id === id); // Remove the touch if it exists
    if (visualTouch) visualTouches.push(visualTouch); // Add the new touch
    return visualTouches;
  };

  playAudio = () => {
    Audio.discrete("touchFeedback").play(resource("audio/ripple-tap.mp3"));
  };
}

export default TouchFeedback;
