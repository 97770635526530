import _ from "lodash";
import { PureComponent } from "react";
import PropTypes from "prop-types";
import Hook from "../../../logic/hook";
import Classes from "../../../helpers/classes";
import Strings from "../../../helpers/strings";
import Navigator from "../../../helpers/navigator";
import Interaction from "../../../helpers/interaction";
import Timeout from "../../../helpers/timeout";
import Audio from "../../../helpers/audio";
import Keyboard from "../../../helpers/keyboard";
import GPIO from "../../../helpers/gpio";
import Point from "../../../types/point";
import Text from "../text";
import resource from "../../../helpers/resource";
import TextInfo from "../../../logic/info/text-info";
import StringInfo from "../../../logic/info/string-info";
import Env from "../../../helpers/env";

/**
 * A very fancy button that performs routing-aware
 * navigation and many other useful things.
 * */
class Button extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    disableAfterClick: PropTypes.bool, // Force-disable the button after it's clicked once (useful to prevent multiple clicks before navigating, among other things)
    disableAfterClickFor: PropTypes.number, // Force-disable the button for a certain duration after it's clicked, then enable it again
    hotspotMargin: PropTypes.string, // CSS margin to use for the hotspot (can also -- preferrably -- be specified in stylesheets)
    onClick: PropTypes.func, // Register a hook to be run when the button is clicked (if navigation props are specified, you can cancel navigation by returning false from the handler)
    delay: PropTypes.number, // Delay the click's action by a certain duration in milliseconds (for example, to wait for click feedback to finish before navigating)
    clickMode: PropTypes.oneOf(["down", "up"]), // When to trigger the click event (on pointer up or down)
    clickDuration: PropTypes.number, // The amount of time, in milliseconds, that the button will have the "clicked" class after being clicked
    clickCancelDragThreshold: PropTypes.number, // Distance (in pixels) after which the click will be cancelled when the pointer is moved between "down" and "up" events (cancels clicks in scrollers, among other uses)
    blockInteractionFor: PropTypes.number, // Blocks app-wide interaction for the speficied number of milliseconds after the button is clicked
    getApi: PropTypes.func, // If provided with a function, that function is called passing the class's public API object
    onClickStateChange: PropTypes.func, // Called when the button's click state changes
    onPressStateChange: PropTypes.func, // Called when the button's press state changes
    sound: PropTypes.string, // The path to a custom click sound (else falls back to the default click sound unless muted)
    muted: PropTypes.bool, // Set to true to prevent the "click" sound from being played (set the "buttons" context's "mute" setting to "true" to mute all buttons!)
    inputPriority: PropTypes.number, // If multiple buttons have the same key equivalent or gpio equivalent, specifying an input priority prevents buttons with lower priority from being clicked
    inputEquivalent: PropTypes.number, // A keyboard key number and gpio pin that will trigger this button. Equivalent to specifying `keyEquivalent` and `gpioEquivalent` separately.
    inputDescription: PropTypes.string, // A human-readable description for this input (will be displayed in the I/O debug panel)
    keyEquivalent: PropTypes.string, // A keyboard shortcut that will trigger the button (ex: "1", "a", "ctrl|cmd+g", "ctrl|cmd+shift+h", etc.). If an input equivalent is specified, this overrides the key equivalent.
    gpioEquivalent: PropTypes.number, // A pin to register as a GPIO push button equivalent (provide the GPIO pin number). If an input equivalent is specified, this overrides the GPIO equivalent.
    navigate: PropTypes.any, // Navigation options, as provided to `Navigator.navigate`. If provided, the button will perform navigation on click.
    action: PropTypes.oneOf(["go-back", "timeout"]), // An optional built-in action for the button to perform
    localized: PropTypes.string, // A shorthand for <Button>{Strings.localized("MyStringsKey")}</Button>
  };

  static defaultProps = {
    disabled: false,
    disableAfterClick: false,
    disableAfterClickFor: null,
    delay: 0,
    clickMode: "up",
    clickDuration: 100, // In practice, this allows 100ms for transition-in and 100ms for transition-out
    muted: false,
    clickCancelDragThreshold: 20,
    inputPriority: 0,
  };

  state = {
    disabled: false, // Whether the button has been internally disabled or not (see `disableAfterClick`)
    pressed: false, // A button has the "pressed" class while the mouse is down inside its bounds
    clicked: false, // A button has the "clicked" class for a short duration after it has been clicked
  };

  render() {
    // This pattern was suggested here: https://github.com/reactjs/react-redux/pull/270#issuecomment-175217424
    // Root cause: https://github.com/reactjs/react-redux/issues/267
    // We're calling this in `render` because the registration occurs in `render` at the call site,
    // leading to an expectation that the function should run for each render.
    if (this.props.getApi) this.props.getApi(this.createApi());

    const className = Classes.build(
      "ripple-button",
      { pressed: this.state.pressed },
      { clicked: this.state.clicked },
      { disabled: this.state.disabled || this.props.disabled },
      this.props.className
    );

    const hotspotStyle = {};
    if (this.props.hotspotMargin) hotspotStyle.margin = this.props.hotspotMargin;

    return (
      <div className={className} style={this.props.style}>
        {this.renderChildren()}
        <div
          className={Classes.build("hotspot", "debug-show-hotspot", { disabled: this.props.disabled })}
          style={hotspotStyle}
          onPointerDown={this.onPointerDown}
          onPointerUp={this.onPointerUp}
          onPointerOut={this.onPointerOut}
        />
        <div className={Classes.build("bounds", "debug-show-button", { disabled: this.props.disabled })}></div>
      </div>
    );
  }

  renderChildren = () => {
    const children = this.props.children;
    if (this.props.localized) return <Text>{Strings.localized(this.props.localized)}</Text>;
    if (typeof children === "undefined" || children === null) return null;
    if (typeof children === "string" || children instanceof TextInfo || children instanceof StringInfo)
      return <Text>{children}</Text>;
    return children;
  };

  componentDidMount() {
    const inputDescription = this.props.inputDescription || "[no description provided]";
    if (this.props.keyEquivalent || this.props.inputEquivalent) {
      const actualKeyEquivalent = this.props.keyEquivalent || this.props.inputEquivalent.toString();
      this.keyEquivalentSubscription = Keyboard.shortcutSubscribe(
        inputDescription,
        actualKeyEquivalent,
        () => this.performClick(),
        {
          priority: this.props.inputPriority,
        }
      );
    }

    if (this.props.gpioEquivalent || this.props.inputEquivalent) {
      const actualGpioEquivalent = this.props.gpioEquivalent || this.props.inputEquivalent;
      this.gpioEquivalentSubscription = GPIO.pushButtonSubscribe(
        actualGpioEquivalent,
        GPIO.Pin.NORMALLY_HIGH,
        () => this.performClick(),
        { priority: this.props.inputPriority }
      );
    }
  }

  componentWillUnmount() {
    Keyboard.shortcutUnsubscribe(this.keyEquivalentSubscription);
    GPIO.pushButtonUnsubscribe(this.gpioEquivalentSubscription);

    clearTimeout(this.clickTimeoutToken);
    clearTimeout(this.clickFeedbackTimeoutToken);
    clearTimeout(this.disableClickForTimeout);
  }

  onPointerDown = (event) => {
    this.handleDown(event);
    if (this.props.clickMode === "down") this.onPointerUp(event, /* Force */ true);
  };

  onPointerUp = (event, force = false) => {
    if (this.props.clickMode === "down" && !force) return;
    this.handleUp(event);
    this.click(event);
  };

  onPointerOut = (event) => {
    if (this.props.clickMode === "down") return;
    this.handleUp(event);
  };

  handleDown = (event) => {
    this.downScreenPosition = this.getScreenPosition(event);
    this.changePressedState(true);
  };

  handleUp = (event) => {
    this.changePressedState(false);
  };

  click = (event) => {
    event.preventDefault(); // Prevent multi-click caused by mouse click event being fired after a touch end

    const downScreenPosition = this.downScreenPosition;
    delete this.downScreenPosition; // Important to reset the "downOutsideButton" logic

    // When pressing down outside a button and releasing inside it, don't click
    if (typeof downScreenPosition === "undefined") return;

    // When exceeding the maximum allowed distance between the pointer down and up positions, don't click
    if (this.exceedsClickCancelDragThreshold(downScreenPosition, this.getScreenPosition(event))) return;

    this.performClick(event);
  };

  performClick = (event) => {
    if (this.isClicking) return; // Prevent multi-clicks when delay is non-zero
    if (this.state.disabled || this.props.disabled) return; // When called through the API, we must also respect the `enabled` prop
    if (Interaction.blocked()) return; // When called through the API, we must not perform if interaction is blocked

    if (this.props.blockInteractionFor) Interaction.blockFor("button click", this.props.blockInteractionFor);

    if (this.props.disableAfterClick || this.props.disableAfterClickFor) this.setState({ disabled: true });
    if (this.props.disableAfterClickFor) {
      this.disableClickForTimeout = setTimeout(
        () => this.setState({ disabled: false }),
        this.props.disableAfterClickFor
      );
    }

    const sound = this.props.sound || (Env.isDesktop && resource("audio/ripple-button.mp3"));
    if (sound) Audio.discrete("buttons").play(sound, { muted: this.props.muted });

    this.changeClickedState(true);
    this.clickFeedbackTimeoutToken = setTimeout(() => this.changeClickedState(false), this.props.clickDuration);

    if (this.props.delay === 0) {
      this.performAction(event);
      return;
    }

    this.isClicking = true;
    this.clickTimeoutToken = setTimeout(() => {
      this.performAction(event);
      this.isClicking = false;
    }, this.props.delay);
  };

  changeClickedState = (clicked) => {
    this.setState({ clicked: clicked });
    if (this.props.onClickStateChange) this.props.onClickStateChange(clicked);
  };

  changePressedState = (pressed) => {
    this.setState({ pressed: pressed });
    if (this.props.onPressStateChange) this.props.onPressStateChange(pressed);
  };

  performAction = (event) => {
    // The `onClick` handler can decide whether the navigation
    // proceeds or not by returning a boolean. It always runs,
    // regardless of if the action or navigate props are set or not.
    const hook = new Hook("navigate", this.props.onClick);

    if (hook.run(event)) {
      // First, run a built-in action if specified
      switch (this.props.action) {
        case "go-back":
          Navigator.goBack();
          break;
        case "timeout":
          Timeout.force();
          break;
        default: {
          // If no built-in action was specified, navigate
          if (this.props.navigate) Navigator.navigate(this.props.navigate);
        }
      }
    }
  };

  getScreenPosition = (event) => {
    const nativeEvent = event.nativeEvent;
    if (!_.isUndefined(nativeEvent.changedTouches)) {
      // Touch
      return new Point(nativeEvent.changedTouches[0].screenX, nativeEvent.changedTouches[0].screenY);
    } else {
      // Mouse
      return new Point(nativeEvent.screenX, nativeEvent.screenY);
    }
  };

  exceedsClickCancelDragThreshold(downPosition, upPosition) {
    return Math.abs(upPosition.distanceTo(downPosition)) > this.props.clickCancelDragThreshold;
  }

  createApi = () => {
    return {
      performClick: this.performClick, // Perform a click, including the button animation and delay if applicable
      performAction: this.performAction, // Perform the button's action, regardless of the click animation and delay, but still considering the button's hook (if applicable)
    };
  };
}

export default Button;
