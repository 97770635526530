import _ from "lodash";
import Env from "./env";
import Config from "./config";
import Analytics from "./analytics";
import PersistentQueue from "../logic/persistent-queue";

export default class Mail {
  static mailQueue = new PersistentQueue("email-send");
  static subscribeQueue = new PersistentQueue("email-subscribe");

  static send(message, options = {}) {
    Analytics.track("mail-send");
    Mail.mailQueue.enqueue({ message, options });
  }

  static subscribe(address, options = {}) {
    Analytics.track("mail-subscribe");
    Mail.subscribeQueue.enqueue({ address, options });
  }

  // Check the queue regularly for new emails to send.
  // Only dequeues another email while there is no ongoing send.
  static _startProcessing() {
    Mail.mailQueue.startProcessing((entry, resolve, reject) => {
      // The `options` argument overrides settings provided in `Config.mail`
      const actualOptions = _.merge({}, Config.mail, entry.options || {});
      if (!actualOptions.id || !actualOptions.key) reject(new Error("Mail: Missing app ID and/or key"));

      return fetch(`${actualOptions.server}/mail/send`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: actualOptions.id,
          key: actualOptions.key,
          subscribe: entry.options.subscribe,
          message: entry.message,
        }),
      })
        .then((result) => {
          if (result.status !== 200) throw new Error("Mail: Failed to send!");
          resolve();
        })
        .catch((error) => {
          reject(error);
          throw error;
        });
    });

    Mail.subscribeQueue.startProcessing((entry, resolve, reject) => {
      const mode = Config.mail.subscribe.mode;
      const actualOptions = _.merge({}, Config.mail, entry.options || {});

      const subscribeOnline = () =>
        fetch(`${actualOptions.server}/mail/subscribe`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            address: entry.address,
            id: actualOptions.id,
            key: actualOptions.key,
            listId: actualOptions.listId,
          }),
        })
          .then((result) => {
            if (result.status !== 200) throw new Error("Mail: Failed to subscribe!");
            resolve();
          })
          .catch((error) => {
            reject(error);
            throw error;
          });

      const subscribeLocal = () => {
        const data = actualOptions.data ? _.values(actualOptions.data) : [];
        if (!Env.isREC || __DEV__) return Promise.resolve();
        return Env.appendFile(Config.mail.subscribe.file, `${entry.address}${data ? `,${data.join(",")}` : ""}\n`);
      };

      if (mode === "online-and-local") {
        return Promise.all([subscribeOnline(), subscribeLocal()]).then(resolve);
      } else if (mode === "online") {
        return subscribeOnline().then(resolve);
      } else if (mode === "local") {
        return subscribeLocal().then(resolve);
      }
    });
  }
}
