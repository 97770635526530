import { memo } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import Classes from "../../../helpers/classes";

/**
 * This component prevents its contents from being rendered (or displayed)
 * based on the component's visibility in the screen.
 */
const Visible = memo(({ className, children, alwaysVisible, ...rest }) => {
  const [ref, inView] = useInView();

  return (
    <div {...rest} ref={ref} className={Classes.build("ripple-visible", className, { hidden: !inView })}>
      {(inView || alwaysVisible) && children}
    </div>
  );
});

Visible.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  alwaysVisible: PropTypes.bool, // Disables the Visible's behaviour, acting like a normal div
};

export default Visible;
