import Log from "../helpers/log";
import ToastActions from "../redux/actions/local/toast-actions";

const defaultDuration = 3000;

export default class Toast {
  static _store = null; // Must be set early on so that we can dispatch actions

  /**
   * Displays a toast-style message with "info" level at the top of the screen
   */
  static info(message, duration = defaultDuration) {
    Log.info(message);
    const notify = ToastActions.notify(message, "info", duration);
    Toast._store.dispatch(notify);
  }

  /**
   * Displays a toast-style message with "warn" level at the top of the screen
   */
  static warn(message, duration = defaultDuration) {
    Log.warn(message);
    const notify = ToastActions.notify(message, "warn", duration);
    Toast._store.dispatch(notify);
  }

  /**
   * Displays a toast-style message with "error" level at the top of the screen
   */
  static error(message, duration = defaultDuration) {
    Log.error(message);
    const notify = ToastActions.notify(message, "error", duration);
    Toast._store.dispatch(notify);
  }
}
