import Load from "../../../../helpers/load";
import NullDecoration from "./decorations/decoration-base";

const TilesetSource = function (identifier, tilesetUrl, decoration = new NullDecoration()) {
  if (!tilesetUrl) throw new Error("TilesetSource : Tileset URL not specified!");

  this.identifier = identifier;
  this.tilesetUrl = tilesetUrl;
  this.decoration = decoration; // An object that conforms to the TiledMap decoration API
};

TilesetSource.prototype.getMeta = function (stageSize) {
  return Load.json(`${this.tilesetUrl}/meta.json`);
};

TilesetSource.prototype.getTileUrl = function (level, row, column) {
  return `${this.tilesetUrl}/level${level}/row${row}/tile_${level}_${column}-${row}.jpg`;
};

export default TilesetSource;
