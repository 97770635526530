import { useState, useEffect } from "react";
import Config from "../../helpers/config";
import Env from "../../helpers/env";

export const useAppVersion = () => {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const buildIdentifier = Config.buildIdentifier;

    if (!Env.isRCC) {
      setVersion(buildIdentifier);
      return;
    }

    Promise.all([cordova.getAppVersion.getVersionNumber(), cordova.getAppVersion.getVersionCode()]).then(
      ([version, buildNumber]) => {
        let versionString = version.slice(0);
        if (buildNumber) versionString += `-${buildNumber}`;
        if (buildIdentifier) versionString += setVersion(` (build ${buildIdentifier})`);
        setVersion(versionString);
      }
    );
  }, []);

  return version;
};
