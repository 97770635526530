import _ from "lodash";
import TextInfo from "../logic/info/text-info";
import StringInfo from "../logic/info/string-info";

function deenrich(str, joinParagraphsString) {
  const splitted = str.split(/<p>|<\/p>/);
  const trimmed = _.map(splitted, (s) => s.trim());
  const filtered = _.filter(trimmed, (s) => s !== "");
  return filtered.join(joinParagraphsString);
}

function richtextImpl(strings, splitter, ...things) {
  return _.reduce(
    strings,
    (acc, string, index) => {
      const thing = things[index];
      const valueString = (() => {
        if (thing === null || typeof thing === "undefined") return "";
        if (typeof thing === "string") return thing;
        if (thing instanceof TextInfo || thing instanceof StringInfo) return thing.exists ? thing.value : "";
        return thing.toString();
      })();

      acc += string + deenrich(valueString, splitter);

      return acc;
    },
    ""
  );
}

function richtext(strings, ...things) {
  return richtextImpl(strings, "", ...things);
}

richtext.join = (paragraphsSplitter) => (strings, ...things) => richtextImpl(strings, paragraphsSplitter, ...things);

export default richtext;
