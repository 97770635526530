import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import Log from "./log";
import Env from "./env";
import Sort from "./internal/sort";

const MINIMUM_RSSI = -100;

export default class Beacons {
  static _beaconInfos = {};

  static _getLocationManager() {
    return cordova ? cordova.plugins.locationManager : null;
  }

  static _isNotContainedInRCC() {
    if (!Env.isRCC) return true;
    return false;
  }

  static subscribe = (uuid, major, minor, handler) => {
    if (Beacons._isNotContainedInRCC()) return;

    major = major || undefined;
    minor = minor || undefined;

    const subscriptionId = uuidv4();

    const requestedBeaconId = Beacons._getBeaconId(uuid, major, minor);
    const existingBeaconInfo = Beacons._beaconInfos[requestedBeaconId];

    if (!existingBeaconInfo) {
      const locationManager = Beacons._getLocationManager();

      const region = new locationManager.BeaconRegion(requestedBeaconId, uuid.toLowerCase(), major, minor);
      region.notifyEntryStateOnDisplay = true;

      Beacons._internalSubscribe(region, (result) => {
        const beaconInfo = Beacons._beaconInfos[result.region.identifier];
        if (!beaconInfo) return; // This can happen because the actual unsubscribe occurs asynchronously

        const beaconsClone = _.cloneDeep(result.beacons);

        const beacons = _.map(beaconsClone, (b) => {
          // Sort beacon keys and preserve stable order for easier debugging
          const altered = Sort.keys(b);

          // "Unknown Proximity" beacons are usually beacons that have recently gotten out of range.
          // Those beacons have an RSSI of 0 which corresponds to a very strong signal (in dBm).
          // To simplify caller logic, we apply a more representative (but fake) RSSI value of MINIMUM_RSSI
          // which should always be weaker than all currently detected beacons.
          // See this to better understand RSSI: https://stackoverflow.com/a/31869981/167983
          altered.rssi = altered.rssi === 0 ? MINIMUM_RSSI : altered.rssi;

          // Due to inconsistencies in `cordova-plugin-ibeacon`, the major and minor are returned as strings
          // on Android and as integers on iOS. We return a uniform representation across the two OS's to avoid
          // issues in custom apps when using beacons.
          altered.major = typeof altered.major === "string" ? parseInt(altered.major) : altered.major;
          altered.minor = typeof altered.minor === "string" ? parseInt(altered.minor) : altered.minor;

          return altered;
        });

        const orderedBeacons = _.orderBy(beacons, ["major", "minor"], "asc");

        _.each(beaconInfo.callbacks, (callback) => {
          // In the rare case where one callback unmounts a component that also
          // has a callback registered for the same beacon, the callbacks array can
          // be modified during iteration, resulting in an undefined callback here.
          // Just ignore the callback if it was unsubscribed while we were iterating.
          if (callback) callback(orderedBeacons);
        });
      });

      Beacons._beaconInfos[requestedBeaconId] = {
        region,
        callbacks: { [subscriptionId]: handler },
      };
    } else {
      existingBeaconInfo.callbacks[subscriptionId] = handler;
    }

    return { subscriptionId, beaconId: requestedBeaconId };
  };

  static unsubscribe = (subscription) => {
    if (Beacons._isNotContainedInRCC()) return;
    if (!subscription) return;

    const existingBeacon = Beacons._beaconInfos[subscription.beaconId];
    if (!existingBeacon) return;

    delete existingBeacon.callbacks[subscription.subscriptionId];

    if (Object.keys(existingBeacon.callbacks).length === 0) {
      Beacons._internalUnsubscribe(existingBeacon.region);
      delete Beacons._beaconInfos[subscription.beaconId];
    }
  };

  static _getBeaconId(uuid, major, minor) {
    return `${uuid}:${major}:${minor}`;
  }

  static _internalSubscribe(region, callback) {
    const locationManager = Beacons._getLocationManager();
    const delegate = new locationManager.Delegate();

    delegate.didStartMonitoringForRegion = function (pluginResult) {
      Log.info("Beacons: didStartMonitoringForRegion", pluginResult);
    };

    delegate.didRangeBeaconsInRegion = function (pluginResult) {
      callback(pluginResult);
    };

    locationManager.setDelegate(delegate);

    locationManager.requestAlwaysAuthorization();

    // Ranging requires more energy, but it has the advantage of being more responsive to
    // changes (enter, exit) so we start ranging instantly instead of waiting for the region state
    // to be modified by the system. We might want to optimize this sometime (at the cost of responsiveness).
    return locationManager
      .startRangingBeaconsInRegion(region)
      .fail(function (error) {
        Log.error("Beacons:", error);
      })
      .done();
  }

  static _internalUnsubscribe(region) {
    return cordova.plugins.locationManager
      .stopRangingBeaconsInRegion(region)
      .fail(function (error) {
        Log.error(error);
      })
      .done();
  }
}
