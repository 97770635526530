import { Handle } from "handles.js";
import Log from "./log";
import Load from "./load";
import InteractionActions from "../redux/actions/local/interaction-actions";
import resource from "./resource";

export default class Interaction {
  static _store = null;
  static _interactions$ = null;
  static _blockCount = 0; // Do not set this externally!

  static emit(type) {
    Interaction._interactions$.next(type);
  }

  static injectEmitterInIframe(iframe, elementSelector = "body") {
    Load.text(resource("injections/iframe-interaction-emitter.template.js"), { credentials: "include" })
      .then((template) => template.replace(/<%- elementSelector %>/, elementSelector))
      .then((js) => {
        iframe.contentWindow.eval(js);
      });
  }

  /**
   * Returns whether the interaction is currently blocked or not
   */
  static blocked() {
    return Interaction._blockCount > 0;
  }

  /**
   * Prevents interaction for the specified number of milliseconds
   * @param {string} requesterName - The name of the component that requested the pause. Will be logged to ease debugging.
   * @param {number} milliseconds - The number of milliseconds to block interaction for.
   */
  static blockFor(requesterName, milliseconds) {
    const handle = Interaction.block(`${requesterName} (${milliseconds} ms)`);
    setTimeout(handle.release, milliseconds);
  }

  /**
   * Prevents interaction indefinitely, until the handle that this function returns is released.
   * @param {string} requesterName - The name of the component that requested the pause. Will be logged to ease debugging.
   */
  static block(requesterName) {
    if (typeof requesterName === "undefined" || requesterName === null)
      throw new Error("A requester name *MUST* be provided when blocking interactivity!");

    Interaction._blockCount += 1;

    if (Interaction._blockCount === 1) {
      Log.info(`Blocking interactions`);
      Interaction._store.dispatch(InteractionActions.blockInteraction());
    }

    Log.debug(`(Interaction block requested by '${requesterName}', ${Interaction._blockCount} requesters total)`);

    return new Handle(() => {
      // If the interaction was already unblocked by someone else, do nothing.
      if (Interaction._blockCount === 0) return;

      Interaction._blockCount -= 1;

      Log.debug(`(Interaction block released by '${requesterName}', ${Interaction._blockCount} requesters remaining)`);

      if (Interaction._blockCount === 0) {
        Interaction._store.dispatch(InteractionActions.unblockInteraction());
        Log.info("Interactions unblocked");
      }
    });
  }

  /**
   * Unblocks interaction instantly, regardless of interaction blockers.
   */
  static unblock(requesterName) {
    if (typeof requesterName === "undefined" || requesterName === null)
      throw new Error("A requester name *MUST* be provided when force-unblocking interactivity!");

    Log.debug(`(Interaction force-unblocked by '${requesterName}')`);

    Interaction._blockCount = 0;
    Interaction._store.dispatch(InteractionActions.unblockInteraction());
    Log.info("Interactions unblocked");
  }
}
