import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router";

/**
 * Used in exacly the same way as react-router's `Route`, but any props that are
 * not defined on `Route` that you provide will be forwarded to the rendered
 * component. This component simply makes forwarding props less verbose and
 * error prone. Using a route and its `render` prop manually might be more
 * appropriate in some cases.
 * */
class PropsForwardingRoute extends PureComponent {
  static propTypes = {
    component: PropTypes.any.isRequired,
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute(Component, rest)} />;
  }

  renderRoute = (Component, rest) => (props) => <Component {...props} {...rest} />;
}

export default PropsForwardingRoute;
