import _ from "lodash";
import * as PIXI from "pixi.js";

import DecorationBase from "../decoration-base";
import { sourcePinScale, defaultPinAlpha } from "./pin";

export default class PinsDecoration extends DecorationBase {
  static Pin = require("./pin").default;
  static NodePin = require("./node-pin").default;

  pins = [];
  selectedPin = null;

  constructor(pins = [], options = {}) {
    super();

    this.pins.push(...pins);

    this.options = {
      ...{
        pinDynamicScale: sourcePinScale, // See `pin.js` for available modes and their descriptions
        pinDynamicAlpha: defaultPinAlpha, // See `pin.js` for available modes and their descriptions
        pinNeutralAlpha: 1, // Alpha when a pin is neither selected nor unselected
        pinSelectedAlpha: 1, // Alpha for the selected pin
        pinUnselectedAlpha: 0.5, // Alpha for all pins but the selected one
        pinAnimation: "none", // See `pin.js` for all supported animations
        pinClickAnimation: "scale-up", // See `pin.js` for all supported animations
        sound: null, // A custom sound to play (defaults to the usual Ripple button click sound)
        muted: false, // Set to true to mute the click sound
        onPinClick: (decoration, pin) => {}, // A callback for when a pin is clicked
      },
      ...options,
    };
  }

  // Override this to create and manage pins directly in a subclass
  makePins() {
    return [];
  }

  setup() {
    // Make custom pins here instead of the constructor so that
    // the makePins method in the base class can access stored options and more.
    this.pins.push(...this.makePins());

    const pinsContainer = new PIXI.Container();
    this.displayObject.addChild(pinsContainer);
    _.each(this.pins, (pin) => {
      pin.setup(this.info, this.options, (pin) => this.options.onPinClick(this, pin));
      pin.sound = pin.sound ?? this.options.sound;
      pin.muted = pin.muted ?? this.options.muted;
      pinsContainer.addChild(pin.displayObject);
    });
  }

  refresh() {
    if (!this.initialized) return;

    _.each(this.pins, (pin) => {
      pin.selected = _.isNil(this.selectedPin) ? null : pin.id === this.selectedPin.id;
      pin.debug = this.debug;
      pin.editing = this.editing;
      pin.refresh(this.info, this.options);
    });
  }

  unload() {
    this.displayObject.destroy(true);
    this.pinsContainer.destroy(true);
    this.destroy(true);

    _.each(this.pins, (pin) => {
      pin.unload();
    });

    this.pins = [];
  }
}
