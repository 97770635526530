import _ from "lodash";
import * as PIXI from "pixi.js";

export default class TextureLoader {
  imageUrl = null;
  callbacks = [];

  constructor(imageUrl, callback) {
    this.imageUrl = imageUrl;
    this.callbacks.push(callback);
  }

  load() {
    const loader = new PIXI.Loader();

    // Force loading as an image to handle extension-less URLs properly
    // http://stackoverflow.com/a/35747210
    const loaderOptions = {
      loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
      xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB,
    };

    loader.add(this.imageUrl, this.imageUrl, loaderOptions);

    loader.load((loader, resources) => {
      const texture = resources[this.imageUrl].texture;
      _.each(this.callbacks, (callback) => callback(texture));
    });
  }
}
