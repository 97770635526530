import _ from "lodash";
import Log from "../helpers/log";
import { v4 as uuidv4 } from "uuid";

function indexBy(nodes, keySelector) {
  return _.reduce(
    nodes,
    (obj, node) => {
      obj[keySelector(node)] = node;
      return obj;
    },
    {}
  );
}

const Data = function (nodes, clientId) {
  this.allNodes = nodes;
  this.nodes = _.filter(nodes, (n) => n.isVisible());
  this.id = uuidv4(); // Each time a new Data is created, it has a new ID
  this._indexedById = indexBy(nodes, (n) => n.id);

  this.root = this._indexedById[clientId];
  this.texts = _.flatMap(nodes, (n) => n.texts);

  this.requiredNode = (id) => {
    const node = this.optionalNode(id);
    if (node === null) throw new Error(`Required node '${id}' not found`);
    return node;
  };

  this.wantedNode = (id) => {
    const node = this.optionalNode(id);
    if (node === null) Log.warn(`Wanted node '${id}' not found`);
    return node;
  };

  this.optionalNode = (id) => {
    // eslint-disable-next-line react/no-this-in-sfc
    const second = this._indexedById[id];
    if (second) return second;
    return null;
  };

  this.find;
};

export default Data;
