import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Env from "./env";
import Log from "./log";
import Interaction from "./interaction";

const digitals = {};

function stateEventNameForPin(pinNumber) {
  return `gpio-state-${pinNumber}`;
}

export default class GPIO {
  static _isConnected = false;

  // Constants
  static Pin = {
    NORMALLY_LOW: 0,
    NORMALLY_HIGH: 1,
  };

  static _isNotContainedInREC() {
    if (!Env.isREC) return true;
    return false;
  }

  static _isNotConnected() {
    if (!GPIO._isConnected) {
      Log.warn("Cannot perform operation because GPIO is not connected");
      return true;
    }
    return false;
  }

  static _isPinAnInput(pinNumber) {
    if (digitals[pinNumber]) {
      Log.warn(`Cannot perform output operation because pin ${pinNumber} is registered as an input`);
      return true;
    }
    return false;
  }

  /** Our implementation assumes that the pin is "on" when in HIGH state (voltage applied)
   * and is "off" when in low state (no voltage). Depending on the circuit or type of switch installed,
   * (normally open, normally closed), it might make sense to treat a pin in HIGH state as "off".
   * To choose which normal state to specify for a pin, think of the voltage that the pin receives
   * when the physical switch is off (in "normal" state). If the pin receives voltage when the switch is off, use
   * `GPIO.Pin.NORMALLY_HIGH`. If the pin receives voltage when the switch is on, use `GPIO.Pin.NORMALLY_OPEN`. */
  static _isPinStateReversed(pinNormalState) {
    return pinNormalState === GPIO.Pin.NORMALLY_HIGH;
  }

  // Setup

  /** Connects to the GPIO board. Required for all other GPIO calls. */
  static _connect(portName, onConnect) {
    if (GPIO._isNotContainedInREC()) return;
    GPIO._isConnected = true;
    Env._subscribeToReceiveFromContainer("gpio-info-message", (info) => {
      Log.info(info);
    });
    Env._subscribeToReceiveFromContainer("gpio-error-message", (error) => {
      Log.error(error);
    });
    Env._subscribeToReceiveFromContainer("gpio-connected", (initialConnection) => {
      if (onConnect) onConnect(initialConnection);
    });
    Env._sendToContainer("gpio-connect", portName);
  }

  // Digital Output

  /** Set the specified pin to digital output mode and set it to high state */
  static set(pinNumber) {
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected() || GPIO._isPinAnInput(pinNumber)) return;
    Env._sendToContainer("gpio-set", pinNumber);
  }

  /** Set the specified pin to digital output mode and set it to low state */
  static clear(pinNumber) {
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected() || GPIO._isPinAnInput(pinNumber)) return;
    Env._sendToContainer("gpio-clear", pinNumber);
  }

  // Digital Input

  /** Subscribe to the specified pin as a toggle switch. Triggers when voltage is applied and unapplied, and exposes the
   * new state as a boolean).
   * @param pinNumber The number of the pin to subscribe to.
   * @param pinNormalState To choose the proper pin normal state, refer to the GPIO._isPinStateReversed doc comment.
   * @param onStateChange A callback to call when the switch changes state.
   * @param options Options for the subscription. Example: `{ priority: 1 }`
   * */
  static toggleSwitchSubscribe(pinNumber, pinNormalState, onStateChange, options) {
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected()) return;

    const callback = (...args) => {
      Interaction.emit(); // A physical switch state change counts as a user interaction
      onStateChange(...args);
    };

    return GPIO._digitalStateSubscribe(
      pinNumber,
      (_persisted, newState) => callback(GPIO._isPinStateReversed(pinNormalState) ? !newState : newState),
      options
    );
  }

  /** Unsubscribe a toggle switch subscription. The subscription object that must be provided to this function is
   *  obtained by storing the return value of a `toggleSwitchSubscribe` call. */
  static toggleSwitchUnsubscribe(subscription) {
    if (!subscription) return;
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected()) return;
    GPIO._digitalStateUnsubscribe(subscription);
  }

  /** Subscribe to the specified pin as a push button. Triggers when the pin state is toggled back and forth once.
   * @param pinNumber The number of the pin to subscribe to.
   * @param pinNormalState To choose the proper pin normal state, refer to the GPIO._isPinStateReversed doc comment.
   * @param options Options for the subscription. Example: `{ priority: 1 }`
   * */
  static pushButtonSubscribe(pinNumber, pinNormalState, onPress, options) {
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected()) return;

    const callback = (...args) => {
      Interaction.emit(); // A push button press counts as a user interaction
      onPress(...args);
    };

    return GPIO._digitalStateSubscribe(
      pinNumber,
      (persisted, newState) => {
        const reversed = GPIO._isPinStateReversed(pinNormalState);
        const lastState = persisted.lastState || (reversed ? true : false);
        if (!reversed && !lastState && newState) callback(newState);
        if (reversed && lastState && !newState) callback(newState);
        persisted.lastState = newState;
      },
      options
    );
  }

  /** Unsubscribe a push button subscription. The subscription object that must be provided to this function is
   *  obtained by storing the return value of a `pushButtonSubscribe` call. */
  static pushButtonUnsubscribe(subscription) {
    if (!subscription) return;
    if (GPIO._isNotContainedInREC() || GPIO._isNotConnected()) return;
    GPIO._digitalStateUnsubscribe(subscription);
  }

  // Private

  static _digitalStateSubscribe(pinNumber, onStateChange, { priority = 0 } = {}) {
    const subscriptionId = uuidv4();

    const existingDigital = digitals[pinNumber];

    if (!existingDigital) {
      // Enable digital state change events for the pin
      Env._sendToContainer("gpio-digital-state-subscribe", pinNumber);

      // Subscribe for pin state change events and call all subscriber callbacks when the state changes
      const receiveSubscription = Env._subscribeToReceiveFromContainer(
        stateEventNameForPin(pinNumber),
        (newDigitalState) => {
          const digital = digitals[pinNumber];
          if (!digital) return; // This can happen because the actual unsubscribe occurs asynchronously

          const maxPriority = _.max(_.map(digital.callbacks, (c) => c.priority));
          const maxPriorityCallbacks = _.filter(digital.callbacks, (c) => c.priority === maxPriority);

          _.each(maxPriorityCallbacks, (callback) => {
            // In the rare case where one callback unmounts a component that also
            // has a callback registered for the same pin, the callbacks array can
            // be modified during iteration, resulting in an undefined callback here.
            // Just ignore the callback if it was unsubscribed while we were iterating.
            if (callback) callback.onStateChange(digital.persisted, newDigitalState);
          });
        }
      );

      // Create a new object to represent the digital state subscription
      digitals[pinNumber] = {
        callbacks: { [subscriptionId]: { onStateChange, priority } },
        receiveSubscription,
        persisted: {},
      };
    } else {
      // Add the additional callback to the existing switch
      existingDigital.callbacks[subscriptionId] = { onStateChange, priority };
    }

    // Return the subscription (which the user needs to unsubscribe)
    return { pinNumber, subscriptionId };
  }

  static _digitalStateUnsubscribe(subscription) {
    const existingDigital = digitals[subscription.pinNumber];
    if (!existingDigital) return;

    delete existingDigital.callbacks[subscription.subscriptionId];

    if (Object.keys(existingDigital.callbacks).length === 0) {
      delete digitals[subscription.pinNumber];
      Env._sendToContainer("gpio-state-unsubscribe", subscription);
      Env._unsubscribeToReceiveFromContainer(
        stateEventNameForPin(subscription.pinNumber),
        existingDigital.receiveSubscription
      );
    }
  }
}
