import _ from "lodash";
import * as PIXI from "pixi.js";
import { Promise } from "es6-promise";
import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import Hammer from "hammerjs";
import { TweenLite, Power3 } from "gsap/all";
import Sequencer from "sequencer.js";

import Point from "../../../types/point";
import Size from "../../../types/size";
import Rect from "../../../types/rect";
import Classes from "../../../helpers/classes";
import Styles from "../../../helpers/styles";
import Maths from "../../../helpers/maths";
import Log from "../../../helpers/log";

import PixiStage from "../pixi-stage";
import Layer from "./logic/layer";
import Tileset from "./logic/tileset";

export default class TiledMap extends PureComponent {
  // The TiledMap API is exposed as statics on the TiledMap itself
  // Use like this: `new TiledMap.TilesetSource()`

  // Sources
  static TilesetSource = require("./api/tileset-source").default;
  static ImageSource = require("./api/image-source").default;
  static TransparentSource = require("./api/transparent-source").default;

  // Decorations
  static PinsDecoration = require("./api/decorations/pins-decoration").default;
  static DecorationBase = require("./api/decorations/decoration-base").default;

  // Helpers
  static DecorationHelper = require("./api/decoration-helper").default;
  static LoadManager = require("./api/load-manager").default;

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    transparent: PropTypes.bool,
    backgroundColor: PropTypes.number, // Background color as an hexadecimal number (e.g. `0xffffff`)
    sources: PropTypes.array.isRequired,
    currentSource: PropTypes.object.isRequired,
    levelSwitchingRatio: PropTypes.number,
    preloadPreviousLevels: PropTypes.number,
    preloadNextLevels: PropTypes.number,
    onSourceWillChange: PropTypes.func,
    onSourceDidChange: PropTypes.func,
    getApi: PropTypes.func,
  };

  static defaultProps = {
    transparent: false,
    backgroundColor: 0x000000,
    levelSwitchingRatio: 0.5,
    preloadPreviousLevels: 1,
    preloadNextLevels: 1,
  };

  constructor(props) {
    super(props);

    this.element = createRef();

    this.layers = [];
    this.operationsRequestedBeforeStartup = [];

    this.state = {
      zoom: 1,
      position: new Point(0, 0),
    };
  }

  render() {
    // We're calling this in `render` because the registration occurs in `render` at the call site,
    // leading to an expectation that the function should run for each render.
    if (this.props.getApi) this.props.getApi(this.createApi());

    return (
      <div
        ref={this.element}
        className={Classes.build("ripple-tiled-map", this.props.className)}
        style={Styles.merge(this.props.style)}
        onWheel={this.onMouseWheel}
      >
        <PixiStage
          onStageReady={this.onStageReady}
          autoPreventDefault={false}
          backgroundColor={this.props.backgroundColor}
          transparent={this.props.transparent}
        />
        {this.renderDebugInfo()}
      </div>
    );
  }

  renderDebugInfo() {
    return (
      <div className="debug-info debug-visible">
        <div className="state">
          <ul>
            <li>
              <b>Zoom:</b> {this.state.zoom.toFixed(2)} / {this.getMaxZoom().toFixed(2)}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.sequencer = new Sequencer();
    this.registerTouchHandlers();
  }

  componentDidUpdate() {
    if (!this.hasSetupCompleted) return;

    if (this.props.currentSource !== null && this.props.currentSource !== this.previousSource)
      this.refreshCurrentLayer(/* Initial: */ false);

    this.refresh();
  }

  componentWillUnmount() {
    this.unregisterTouchHandlers();
    this.cancelViewportAnimation();
    this.unload();
  }

  onStageReady = (stage) => {
    this.stageSize = new Size(stage.width, stage.height);

    // Root container for "everything"
    this.rootContainer = new PIXI.Container();
    stage.root.addChild(this.rootContainer);

    // This is the part that must be scaled for zooming
    this.mapContainer = new PIXI.Container();
    this.rootContainer.addChild(this.mapContainer);

    // Contains all tilesets
    this.tilesetsContainer = new PIXI.Container();
    this.mapContainer.addChild(this.tilesetsContainer);

    // Contains all decorations
    this.decorationsContainer = new PIXI.Container();
    this.mapContainer.addChild(this.decorationsContainer);

    this.setupLayers(() => {
      this.refreshCurrentLayer(/* Initial: */ true);
      this.refresh();
      this.hasSetupCompleted = true;
      _.each(this.operationsRequestedBeforeStartup, (operation) => operation());
    });
  };

  setupLayers = (done) => {
    // Load tilesets from the provided sources
    const tilesetLoadPromises = _.map(this.props.sources, (source) => {
      const options = {
        levelSwitchingRatio: this.props.levelSwitchingRatio,
        preloadPreviousLevels: this.props.preloadPreviousLevels,
        preloadNextLevels: this.props.preloadNextLevels,
      };
      return this.loadTileset(source, options);
    });

    // When all tilesets are loaded, perform some final checks and set them up in the stage
    Promise.all(tilesetLoadPromises)
      .then((tilesets) => {
        this.ensureTilesetsAreOfEqualSize(tilesets);
        this.ensureTilesetSourcesHaveDifferentIdentifiers(tilesets);

        _.each(tilesets, (tileset, index) => {
          const source = this.props.sources[index];

          // Tileset
          this.tilesetsContainer.addChild(tileset.displayObject);

          // Decorations (always laid out in the deepest level's coordinate system
          const decoration = source.decoration;
          const levelNumber = tileset.getMaxLevelNumber();
          decoration.setupInternal(this.getDecorationInfo(tileset));
          decoration.displayObject.scale.set(tileset.getScale(levelNumber));
          decoration.displayObject.visible = false;
          this.decorationsContainer.addChild(decoration.displayObject);

          this.layers.push(new Layer(source, tileset));
        });

        done();
      })
      .catch((error) => {
        Log.error(error);
      });
  };

  loadTileset = (source, options) => {
    // Wrap tileset loading in a promise to simplify loading and waiting
    // for multiple tilesets simultaneously.
    return new Promise((resolve, reject) => {
      const tileset = new Tileset(source, options);
      tileset.load(this.stageSize, (ts) => resolve(ts));
    });
  };

  ensureTilesetsAreOfEqualSize = (tilesets) => {
    const expectedSize = tilesets[0].getInitialSize();
    _.each(tilesets, (ts) => {
      const testedSize = ts.getInitialSize();
      if (testedSize.width !== expectedSize.width || testedSize.height !== expectedSize.height)
        throw new Error(
          `Additional tileset '${ts.source}' is not of same size (expected ${expectedSize.width}x${expectedSize.height})`
        );
    });
  };

  ensureTilesetSourcesHaveDifferentIdentifiers = (tilesets) => {
    const identifiers = _.map(tilesets, (ts) => ts.source.identifier);
    if (_.uniq(identifiers).length !== tilesets.length)
      throw new Error("All tiled map sources must have distinct identifiers!");
  };

  // Helpers

  getWorldViewportBounds = () => {
    return { minX: 0, minY: 0, maxX: this.stageSize.width, maxY: this.stageSize.height };
  };

  getRelativePositionInComponent = (absolutePosition) => {
    const bounds = this.element.current.getBoundingClientRect();
    return new Point(absolutePosition.x - bounds.left, absolutePosition.y - bounds.top);
  };

  getMapSize = () => {
    return this.layers[0].tileset.getInitialSize();
  };

  getMaxZoom = () => {
    const firstLayer = this.layers[0];
    if (!firstLayer) return 0;
    return firstLayer.tileset.getMaxZoom();
  };

  getPointInMapFromRelativePoint = (relativePoint) => {
    const mapSize = this.getMapSize();
    return new Point(relativePoint.x * mapSize.width, relativePoint.y * mapSize.height);
  };

  getFrameInMapFromRelativeFrame = (relativeFrame) => {
    const mapSize = this.getMapSize();
    return new Rect(
      relativeFrame.x * mapSize.width,
      relativeFrame.y * mapSize.height,
      relativeFrame.width * mapSize.width,
      relativeFrame.height * mapSize.height
    );
  };

  getMapZoomInfoToFit = (frameInMap) => {
    // We work in the map's unscaled coordinate system (1:1 size, the size of level 0)
    const mapSize = this.getMapSize();

    const viewportRect = new Rect(0, 0, this.stageSize.width, this.stageSize.height);

    // CAUTION: Assuming that the map *fills* the viewport (as is the case since TiledMap was made to be "responsive")
    const initialViewportFrameInMap = viewportRect.fillIn(new Rect(0, 0, mapSize.width, mapSize.height));

    // This may seem backwards, but by filling `frameInMap` with viewportRect,
    // we get a frame that *fits* `frameInMap`, *in `frameInMap`'s coordinate system*
    const newViewportFrameInMap = viewportRect.fillIn(frameInMap);
    const zoom = initialViewportFrameInMap.width / newViewportFrameInMap.width;

    return { zoom: zoom, viewportFrameInMap: newViewportFrameInMap };
  };

  getMapPositionToCenter = (pointInMap, zoom) => {
    const mapSize = this.getMapSize();
    return (
      // Negate the point to get a translation offset instead of a position in the map
      pointInMap // [(0,0)..mapSize] coordinate system
        // [-mapSize/2..mapSize/2] coordinate system
        .minus(mapSize.scaledBy(0.5))
        // Adjust for the zoom
        .scaledBy(zoom)
        // Adjust for the downscaling ratio applied when the map is bigger than the viewport
        .scaledBy(this.fillOrFitRatio).negated
    );
  };

  clampPosition = (position, zoom) => {
    const halfStageWidth = this.stageSize.width / 2.0;
    const halfStageHeight = this.stageSize.height / 2.0;

    // 1. As a first step, we make sure that we can only see what we could see in the viewport on load
    let minX = halfStageWidth + -halfStageWidth * zoom;
    let maxX = -halfStageWidth + halfStageWidth * zoom;
    let minY = halfStageHeight + -halfStageHeight * zoom;
    let maxY = -halfStageHeight + halfStageHeight * zoom;

    // 2. Then we compensate for the map's size (so that we can view the whole map if it was cropped by the viewport on mount)
    const mapSize = this.getMapSize();
    const mapSizeCompensationX = ((mapSize.width * this.fillOrFitRatio - this.stageSize.width) / 2.0) * zoom;
    const mapSizeCompensationY = ((mapSize.height * this.fillOrFitRatio - this.stageSize.height) / 2.0) * zoom;
    minX -= mapSizeCompensationX;
    maxX += mapSizeCompensationX;
    minY -= mapSizeCompensationY;
    maxY += mapSizeCompensationY;

    const clampedX = Maths.clamp(position.x, minX, maxX);
    const clampedY = Maths.clamp(position.y, minY, maxY);

    return new Point(clampedX, clampedY);
  };

  getDecorationInfo(tileset) {
    const containerSize = tileset.getSize(tileset.getMaxLevelNumber());
    return { containerSize, currentZoom: this.state.zoom, maxZoom: tileset.getMaxZoom() };
  }

  // Mouse controls

  onMouseWheel = (event) => {
    const newZoom = Maths.clamp(this.state.zoom + event.deltaY / 1000, 1, this.getMaxZoom());
    const worldZoomPoint = new PIXI.Point(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    this.performZoom(newZoom, worldZoomPoint);
  };

  // Touch controls

  registerTouchHandlers = () => {
    // NOTE: These also implement most mouse interactions
    // (everything but mouse wheel zoom)
    this.hammer = new Hammer(this.element.current, { domEvents: true });

    const pan = new Hammer.Pan();
    const pinch = new Hammer.Pinch();

    pan.recognizeWith(pinch);
    pinch.recognizeWith(pan);

    this.hammer.add(pan);
    this.hammer.on("panstart", this.onPanStart);
    this.hammer.on("panmove", this.onPanMove);

    this.hammer.add(pinch);
    this.hammer.on("pinchstart", this.onPinchStart);
    this.hammer.on("pinch", this.onPinch);
  };

  unregisterTouchHandlers = () => {
    if (this.hammer) {
      this.hammer.destroy();
      this.hammer = null;
    }
  };

  onPinchStart = (event) => {
    this.pinchStartScale = this.state.zoom;
    this.previousPinchEventPosition = this.getRelativePositionInComponent(event.center);
  };

  onPinch = (event) => {
    event.srcEvent.stopPropagation();
    const eventPosition = this.getRelativePositionInComponent(event.center);

    // Workaround for hammer.js pinchstart (seemingly) sometimes not being fired
    if (!this.previousPinchEventPosition) return;

    // Translate
    const deltaX = eventPosition.x - (this.previousPinchEventPosition.x || eventPosition.x);
    const deltaY = eventPosition.y - (this.previousPinchEventPosition.y || eventPosition.y);
    this.performTranslation(deltaX, deltaY);

    // Scale
    const newZoom = Maths.clamp(this.pinchStartScale * event.scale, 1, this.getMaxZoom());
    const worldZoomPoint = new PIXI.Point(eventPosition.x, eventPosition.y);
    this.performZoom(newZoom, worldZoomPoint);

    this.previousPinchEventPosition = eventPosition;
  };

  onPanStart = (event) => {
    this.previousPanEventPosition = this.getRelativePositionInComponent(event.center);
  };

  onPanMove = (event) => {
    if (event.srcEvent.altKey) return;

    event.srcEvent.stopPropagation();
    const eventPosition = this.getRelativePositionInComponent(event.center);

    // Translate
    const deltaX = eventPosition.x - (this.previousPanEventPosition.x || eventPosition.x);
    const deltaY = eventPosition.y - (this.previousPanEventPosition.y || eventPosition.y);
    this.performTranslation(deltaX, deltaY);

    this.previousPanEventPosition = eventPosition;
  };

  // Entry point for translation

  performTranslation = (worldDeltaX, worldDeltaY) => {
    this.updateMapByTranslatingBy(worldDeltaX, worldDeltaY);
  };

  // Entry point for zoom

  performZoom = (zoom, worldZoomPoint) => {
    this.updateMapBySettingZoom(zoom, worldZoomPoint);
  };

  // Internal state updating functions

  updateMapBySettingZoom = (newZoom, worldZoomPoint) => {
    const oldScale = this.state.zoom;
    const scaleDifference = newZoom - oldScale;

    // Find the zoom point in map coordinates from the world
    // coordinate system (where viewport and mouse events occur)
    const zoomPoint = this.mapContainer.toLocal(worldZoomPoint);

    // Offset the map so that the zoom point stays centered
    // Explanation: https://stackoverflow.com/a/30410948/167983
    const newX = this.state.position.x - zoomPoint.x * scaleDifference;
    const newY = this.state.position.y - zoomPoint.y * scaleDifference;
    const newPosition = this.clampPosition(new Point(newX, newY), newZoom);

    // Apply the new scale and the scale-derived translation
    this.setState({ zoom: newZoom, position: newPosition });
  };

  updateMapByTranslatingBy = (worldOffsetX, worldOffsetY) => {
    // Here we're working in world coordinates only
    const zoom = this.state.zoom;
    const newX = this.state.position.x + worldOffsetX;
    const newY = this.state.position.y + worldOffsetY;
    const newPosition = this.clampPosition(new Point(newX, newY), zoom);
    this.setState({ position: newPosition });
  };

  updateMapBySettingZoomAndPosition = (newZoom, position) => {
    const clampedPosition = this.clampPosition(position, newZoom);
    this.setState({ zoom: newZoom, position: clampedPosition });
  };

  // Refresh the stage from the state and model

  refresh = () => {
    const zoom = this.state.zoom;

    // Zoom and scale all tilesets regardless of their contents
    this.mapContainer.scale.set(zoom);
    this.mapContainer.position.x = this.state.position.x;
    this.mapContainer.position.y = this.state.position.y;

    // We scaled the map in the stage but the tilesets themselves
    // do not yet know anything about this. Tell them to refresh so that tiles
    // are loaded and unloaded based on the zoom and their position in the viewport.
    _.each(this.layers, (layer) => {
      if (!layer.isEnabled) return;
      layer.tileset.refresh(zoom, this.getWorldViewportBounds());
      layer.decoration.refreshInternal(this.getDecorationInfo(layer.tileset));
    });
  };

  refreshCurrentLayer = (initial) => {
    const newSource = this.props.currentSource;
    const previousSource = this.previousSource;
    this.previousSource = newSource;
    if (newSource === previousSource) return;
    if (!this.tilesetsContainer) return;

    const previousLayer = _.find(
      this.layers,
      (layer) => previousSource && layer.source.identifier === previousSource.identifier
    );
    const newLayer = _.find(this.layers, (layer) => layer.source.identifier === newSource.identifier);
    const otherLayers = _.difference(this.layers, [newLayer]);

    // Notify interested parties that the source will change (before it has visually changed)
    this.sequencer.do(() => {
      if (this.props.onSourceWillChange) this.props.onSourceWillChange(previousSource, newSource, initial);
    });

    // Hide the previous layer's decoration
    if (previousLayer) {
      this.sequencer.doWaitForRelease((release) => previousLayer.decoration.willDisappear(release));
      this.sequencer.do(() => (previousLayer.decoration.displayObject.visible = false));
      this.sequencer.doWaitForRelease((release) => previousLayer.decoration.didDisappear(release));
    }

    // Bring the new tileset to the front and enable it so it loads
    this.sequencer.do((release) => {
      this.tilesetsContainer.removeChild(newLayer.tileset.displayObject);
      this.tilesetsContainer.addChild(newLayer.tileset.displayObject);
      newLayer.tileset.refresh(this.state.zoom, this.getWorldViewportBounds()); // Refresh the tileset now to load its tiles
      newLayer.isEnabled = true; // Mark the layer as enabled so that future refreshes affect it

      // Scale things to fill the viewport if too big
      const logicalSize = new Size(newLayer.tileset.meta.viewportSize.width, newLayer.tileset.meta.viewportSize.height);
      const fillOrFitRatio = logicalSize.scaleFactorToFillProportionallyIn(this.stageSize);
      this.tilesetsContainer.scale.set(fillOrFitRatio, fillOrFitRatio);
      this.decorationsContainer.scale.set(fillOrFitRatio, fillOrFitRatio);
      this.fillOrFitRatio = fillOrFitRatio;
    });

    // Wait a reasonable amount of time for the new source's tiles to appear
    this.sequencer.doWait(400);

    // Show the new layer's decoration
    this.sequencer.doWaitForRelease((release) => newLayer.decoration.willAppear(release));
    this.sequencer.do(() => (newLayer.decoration.displayObject.visible = true));
    this.sequencer.doWaitForRelease((release) => newLayer.decoration.didAppear(release));

    // Notify interested parties that the source has changed (after it has visually changed)
    this.sequencer.do(() => {
      if (this.props.onSourceDidChange) this.props.onSourceDidChange(previousSource, newSource, initial);
    });

    // Wait until we're sure the new layer's tileset has loaded before hiding the previous one
    this.sequencer.doWait(1000);

    // Disable all other tilesets
    this.sequencer.do(() => {
      _.each(otherLayers, (layer) => {
        layer.isEnabled = false;
      });
    });
  };

  // API

  // Where `relativeFrame` is a Point object describing relative coordinates [0..1] in the map
  // Offset is specified in world coordinates
  setViewportToCenter = (relativePoint, offset = new Point(0, 0)) => {
    if (!this.hasSetupCompleted) {
      this.operationsRequestedBeforeStartup.push(() => this.setViewportToCenter(relativePoint, offset));
      return;
    }

    const pointInMap = this.getPointInMapFromRelativePoint(relativePoint);
    const mapPosition = this.getMapPositionToCenter(pointInMap, this.state.zoom).plus(offset);
    this.updateMapBySettingZoomAndPosition(this.state.zoom, mapPosition);
  };

  // Where `relativeFrame` is a Point object describing relative coordinates [0..1] in the map
  // Offset is specified in world coordinates
  animateViewportToCenter = (relativePoint, offset = new Point(0, 0), duration = 750) => {
    if (!this.hasSetupCompleted) {
      this.operationsRequestedBeforeStartup.push(() => this.animateViewportToCenter(relativePoint, offset, duration));
      return;
    }

    this.cancelViewportAnimation();

    const pointInMap = this.getPointInMapFromRelativePoint(relativePoint);
    const newPosition = this.getMapPositionToCenter(pointInMap, this.state.zoom).plus(offset);

    const initialPosition = this.state.position;

    // As a convenience, a duration of zero results in an insta-set
    if (Math.abs(duration) < 0.0001) {
      this.setViewportToCenter(relativePoint, offset);
      return;
    }

    const progress = { value: 0 };
    const targetValues = { value: 1 };
    const tweenParameters = {
      ease: Power3.easeInOut,
      onUpdate: () => {
        const interpolatedPosition = new Point(
          Maths.interpolate(progress.value, initialPosition.x, newPosition.x),
          Maths.interpolate(progress.value, initialPosition.y, newPosition.y)
        );
        this.updateMapBySettingZoomAndPosition(this.state.zoom, interpolatedPosition);
      },
    };
    this.startViewportAnimation(progress, targetValues, duration / 1000, tweenParameters);
  };

  // Where `relativeFrame` is a Rect object describing relative coordinates [0..1] in the map
  setViewportToFit = (relativeFrame) => {
    if (!this.hasSetupCompleted) {
      this.operationsRequestedBeforeStartup.push(() => this.setViewportToFit(relativeFrame));
      return;
    }

    const frameInMap = this.getFrameInMapFromRelativeFrame(relativeFrame);
    const mapZoomInfo = this.getMapZoomInfoToFit(frameInMap);
    const mapPosition = this.getMapPositionToCenter(mapZoomInfo.viewportFrameInMap.center, mapZoomInfo.zoom);
    this.updateMapBySettingZoomAndPosition(mapZoomInfo.zoom, mapPosition);
  };

  // Where `relativeFrame` is a Rect object describing relative coordinates [0..1] in the map
  animateViewportToFit = (relativeFrame, duration = 2000) => {
    if (!this.hasSetupCompleted) {
      this.operationsRequestedBeforeStartup.push(() => this.animateViewportToFit(relativeFrame, duration));
      return;
    }

    this.cancelViewportAnimation();

    // As a convenience, a duration of zero results in an insta-set
    if (Math.abs(duration) < 0.0001) {
      this.setViewportToFit(relativeFrame);
      return;
    }

    const initialZoom = this.state.zoom;
    const initialPosition = this.state.position;

    const frameInMap = this.getFrameInMapFromRelativeFrame(relativeFrame);
    const mapZoomInfo = this.getMapZoomInfoToFit(frameInMap);
    const mapPosition = this.getMapPositionToCenter(mapZoomInfo.viewportFrameInMap.center, mapZoomInfo.zoom);

    const progress = { value: 0 };
    const targetValues = { value: 1 };
    const tweenParameters = {
      ease: Power3.easeInOut,
      onUpdate: () => {
        const interpolatedZoom = Maths.interpolate(progress.value, initialZoom, mapZoomInfo.zoom);
        const interpolatedPosition = new Point(
          Maths.interpolate(progress.value, initialPosition.x, mapPosition.x),
          Maths.interpolate(progress.value, initialPosition.y, mapPosition.y)
        );
        this.updateMapBySettingZoomAndPosition(interpolatedZoom, interpolatedPosition);
      },
    };
    this.startViewportAnimation(progress, targetValues, duration / 1000, tweenParameters);
  };

  startViewportAnimation = (object, target, duration, tweenParameters) => {
    const standardTweenParameters = {
      onComplete: () => {
        delete this.currentViewportTween;
      },
    };

    this.currentViewportTween = TweenLite.to(object, duration, {
      ...target,
      ...tweenParameters,
      ...standardTweenParameters,
    });
  };

  cancelViewportAnimation = () => {
    if (this.currentViewportTween) {
      this.currentViewportTween.kill();

      delete this.currentViewportTween;
    }
  };

  unload = () => {
    // Unload Layers
    _.each(this.layers, (layer) => {
      layer.isEnabled = false;
      layer.tileset.unload();
      layer.tileset = null;
    });

    // Clean pixi containers
    if (this.rootContainer) {
      this.tilesetsContainer.destroy(true);
      this.decorationsContainer.destroy(true);
      this.mapContainer.destroy(true);
      this.rootContainer.destroy(true);
    }
  };

  createApi = () => {
    return {
      setViewportToCenter: this.setViewportToCenter,
      animateViewportToCenter: this.animateViewportToCenter,
      setViewportToFit: this.setViewportToFit,
      animateViewportToFit: this.animateViewportToFit,
      cancelViewportAnimation: this.cancelViewportAnimation,
    };
  };
}
