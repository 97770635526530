import * as SignalR from "@microsoft/signalr";
import Log from "./log";
import Config from "./config";

export default class Signal {
  static _url = null;
  static _connection = null;

  static async _start() {
    if (Signal._connection === "Connected") return;

    try {
      await Signal._connection.start();

      // If the connection ever drops, reconnect automatically (we use this instead of
      // `withAutomaticReconnect` because https://github.com/SignalR/SignalR/issues/1962)
      Signal._connection.onclose(async () => await Signal._start());

      Signal._connection.on("receive", Signal._receive);

      Log.info(`Successfully connected to signaling server at ${Signal._url}`);
    } catch (error) {
      Log.error(`Failed to connect to signaling server at ${Signal._url}`, error);
      setTimeout(() => Signal._start(), 5000);
    }
  }

  static _connect(url) {
    Signal._url = url;
    Signal._connection = new SignalR.HubConnectionBuilder()
      .withUrl(`${url}/hub?group=${Config.signaling.group}&name=${Config.signaling.name}`, {
        // When SignalR deems it necessary, it obtains an access token using this factory
        accessTokenFactory: async () => {
          return await fetch(
            `${url}/auth?username=${Config.signaling.server.username}&password=${Config.signaling.server.password}`
          ).then((response) => response.text());
        },
      })
      .configureLogging(SignalR.LogLevel.Information)
      .build();

    Signal._start();
  }

  static _receive(message) {
    Log.info("Received Signal", JSON.parse(message));
  }

  static send(target, data) {
    Signal._connection
      .invoke("send", Config.signaling.group, target, JSON.stringify(data))
      .catch((error) => {
        Log.error("Failed to send message through signaling server");
      })
      .catch((error) => {
        Log.error("Exception occurred while trying to send message through signaling server", error);
      });
  }
}
