import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { parse as parseQueryString } from "query-string";
import LocationHelper from "../../helpers/internal/location-helper";
import Classes from "../../helpers/classes";
import Navigator from "../../helpers/navigator";
import FlagsActions from "../../redux/actions/local/flags-actions";

function toNavigationObject(url) {
  const components = url.split("?");
  const path = components[0];
  const query = components[1] ? parseQueryString(components[1]) : {};
  return {
    path: path,
    replaceQuery: query,
  };
}

class PathBar extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    flags: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input = createRef();
  }

  render() {
    const active = this.props.flags.pathBar;
    const url = LocationHelper.urlFromLocation(this.props.location);

    return (
      <div className={Classes.build("path-bar", { active })}>
        <input
          ref={this.input}
          key={url}
          type="text"
          defaultValue={url}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          disabled={!active}
          autoComplete="off"
          autoCapitalize="none"
        />
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.flags.pathBar && this.props.flags.pathBar) {
      this.input.current.focus();
      this.input.current.setSelectionRange(0, this.input.current.value.length);
    } else if (prevProps.flags.pathBar && !this.props.flags.pathBar) {
      this.input.current.blur();
    }
  }

  onKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "ù") {
      Navigator.navigate(toNavigationObject(event.target.value));
      this.props.dispatch(FlagsActions.togglePathBar());
    }
  };

  onKeyDown = (event) => {
    if (event.key === "Escape") {
      this.props.dispatch(FlagsActions.togglePathBar());
    }
  };
}

const mapStateToProps = (state) => ({ flags: state.flags, location: state.router.location });

export default connect(mapStateToProps)(PathBar);
