import { memo } from "react";
import PropTypes from "prop-types";

import { Classes } from "../../../ripple";

const SafeArea = memo(({ className, children, style, ...rest }) => {
  return (
    <div className={Classes.build("ripple-safe-area", className)} style={style}>
      <div className="safe-area-content">{children}</div>
    </div>
  );
});

SafeArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default SafeArea;
