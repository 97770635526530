import _ from "lodash";
import Config from "./config";
import Analytics from "./analytics";
import Interaction from "./interaction";
import NavConfigurator from "../core/nav-configurator";
import Paths from "../core/paths";
import { push, replace, goBack, goForward } from "connected-react-router";

function slashPrefix(path) {
  return path.startsWith("/") ? path : `/${path}`;
}

function stripHtml(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || null;
}

function navigate(store, checkIfRootPage, options) {
  const location = store.getState().router.location || {};
  const currentPath = location.pathname;
  const currentQuery = location.query;

  // If the path is unspecified, we keep the current one.
  // Prefixing helps reducing duplicates when analyzing events by comparing paths.
  const finalPath = slashPrefix(options.path || currentPath);

  // Build the query based on the current query and the provided options.
  // We support using both "replaceQuery" and "mergeQuery", which will be
  // applied in that order. See `doc/navigator.md` for details on this logic.
  let finalQuery = currentQuery || {};
  if (options.replaceQuery) finalQuery = options.replaceQuery;
  if (options.mergeQuery) _.assign(finalQuery, options.mergeQuery);
  if (!options.replaceQuery && !options.mergeQuery) finalQuery = {};

  // Special Case: Merge-in config overrides (if any) so that they are always part of the URL across navigations.
  // This will add them if the query was replaced and will overwrite them if it was merged (redundant but OK).
  if (currentQuery.config) finalQuery.config = currentQuery.config;

  // Choose how we'll handle the browser history
  const routerMethod =
    {
      push: push,
      replace: replace,
    }[options.historyMethod] || push;

  if (!Paths.isCorePath(finalPath)) {
    Analytics.track("navigation", {
      path: finalPath,
      query: finalQuery,
      root: checkIfRootPage(finalPath),
    });
  }

  if (
    Config.interaction.navigation.autoBlock &&
    // Skip blocking if overridden
    !options.disableNavigationAutoBlock &&
    // Auto-block if the path changed or as soon as a path is specified.
    // If we specify the same path we're most likely navigating to
    // another instance of the same page, so we want auto-block to occur.
    (finalPath !== currentPath || options.path)
  ) {
    Interaction.blockFor("forward-navigation", Config.interaction.navigation.autoBlockDuration);
  }

  // Navigate!
  store.dispatch(routerMethod({ pathname: finalPath, query: finalQuery, state: options.state }));
}

export default class Navigator {
  static _store = null; // Must be set early on so that we can read the current state and dispatch actions
  static _checkIfRootPage = null;

  /**
   * Perform a navigation in a way that Ripple fully knows about.
   * See implementation for details.
   */
  static navigate(options) {
    let routeInfo = NavConfigurator.routeInfo(options);

    // If the route info returns something, it's guaranteed to be an object.
    // We want route info to override options, not remove unspecified options, though.
    // Thus we merge the route info-returned values with the initially-provided options.
    routeInfo = routeInfo ? { ...options, ...routeInfo } : options;

    // Even though we don't use the node to generate a path here, by convention we know
    // that a node might be present in the options object and we track a node view here
    // instead of doing it in the custom app.
    if (routeInfo.node) {
      const node = routeInfo.node;
      Analytics.track("node", {
        id: node.id,
        semantic: node.semantic,
        name: stripHtml(node.optionalText("Title", Config.language.default).value) || node.name || "[untitled]",
      });
    }

    navigate(Navigator._store, Navigator._checkIfRootPage, {
      path: routeInfo.path,
      replaceQuery: routeInfo.replaceQuery,
      mergeQuery: routeInfo.mergeQuery,
      historyMethod: routeInfo.historyMethod,
      state: routeInfo.state || {},
    });
  }

  /**
   * Navigate forward in a fully router-compatible way.
   */
  static goBack(options = {}) {
    if (Config.interaction.navigation.autoBlock && !options.disableNavigationAutoBlock)
      Interaction.blockFor("backward-navigation", Config.interaction.navigation.autoBlockDuration);

    Navigator._store.dispatch(goBack());
  }

  /**
   * Navigate forward in a fully router-compatible way.
   */
  static goForward(options = {}) {
    if (Config.interaction.navigation.autoBlock && !options.disableNavigationAutoBlock)
      Interaction.blockFor("backward-navigation", Config.interaction.navigation.autoBlockDuration);

    Navigator._store.dispatch(goForward());
  }

  /**
   * A simple helper to track a "fake" navigation, for analytics purposes
   * in apps where multiple distinct "pages" are in the same component
   * or set of components but represent distinct locations.
   */
  static fake(fakePath, fakeQuery = {}) {
    // If we fake a navigation, we most probably want to block the interactivity as well!
    if (Config.interaction.navigation.autoBlock)
      Interaction.blockFor("fake-navigation", Config.interaction.navigation.autoBlockDuration);

    Analytics.track("navigation", { path: slashPrefix(fakePath), query: fakeQuery, root: false, fake: true });
  }
}

window.ripple = { ...(window.ripple || {}), navigator: Navigator };
