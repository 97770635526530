import { useKeyboardShortcut } from "./use-keyboard-shortcut";
import { usePushButton } from "./use-push-button";

/**
 * Register a keyboard shortcut and a GPIO push button to run some code.
 */
export const useInput = (description, keyAndPinNumber, callback) => {
  if (isNaN(keyAndPinNumber)) throw new Error("Key/pin number must be a number!");
  useKeyboardShortcut(description, keyAndPinNumber.toString(), callback);
  usePushButton(description, keyAndPinNumber, callback);
};
