import _ from "lodash";
import { PureComponent } from "react";

import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Droppable from "../../../components/drop/droppable";
import Dropzone from "../../../components/drop/dropzone";
import DragPreview from "../../../components/drop/drag-preview";
import Classes from "../../../../helpers/classes";
import Button from "../../../components/button";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

const createRenderItem = (label, isDragging = false) => () => {
  return <div className={Classes.build("item", "droppable", { dragging: isDragging })}>{label}</div>;
};

const createRenderPlaceholder = (label) => () => {
  return <div className="item placeholder">{label}</div>;
};

class Demo1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { drops: {} };
  }

  render() {
    return (
      <div className="row">
        <div className="droppables">
          {this.renderDroppable("A")}
          {this.renderDroppable("B")}
        </div>
        <div className="dropzones">
          {this.renderDropzone("B")}
          {this.renderDropzone("A")}
        </div>
        <Button className="reset" onClick={this.reset}>
          <i className="fas fa-undo-alt" />
        </Button>
      </div>
    );
  }

  renderDroppable(id) {
    return <Droppable id={id} hasDropped={this.hasDropped(id)} renderForegroundLayer={createRenderItem(id)} />;
  }

  renderDropzone(id) {
    return (
      <Dropzone
        id={id}
        accepts={id}
        hasDropped={this.hasDropped(id)}
        renderEmptyLayer={createRenderPlaceholder(id)}
        renderForegroundLayer={createRenderItem(id)}
        onDrop={this.onDrop}
      />
    );
  }

  onDrop = (dropzoneId, droppableId) => {
    this.setState((prevState) => ({ drops: { ...prevState.drops, [dropzoneId]: droppableId } }));
  };

  hasDropped = (id) => {
    return !!this.state.drops[id];
  };

  reset = () => {
    this.setState({ drops: {} });
  };
}

class Demo2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { drops: {} };
  }

  render() {
    return (
      <div className="row">
        <div className="droppables">
          {this.renderDroppable("1st")}
          {this.renderDroppable("2nd")}
        </div>
        <div className="dropzones">
          {this.renderDropzone("A")}
          {this.renderDropzone("B")}
        </div>
        <Button className="reset" onClick={this.reset}>
          <i className="fas fa-undo-alt" />
        </Button>
      </div>
    );
  }

  renderDroppable(id) {
    return (
      <Droppable
        id={id}
        group="demo2"
        hasDropped={_.values(this.state.drops).includes(id)}
        renderForegroundLayer={createRenderItem(id)}
      />
    );
  }

  renderDropzone(id) {
    return (
      <Dropzone
        id={id}
        group="demo2"
        hasDropped={_.keys(this.state.drops).includes(id)}
        renderEmptyLayer={createRenderPlaceholder("Any")}
        renderForegroundLayer={createRenderItem(this.state.drops[id])}
        onDrop={this.onDrop}
      />
    );
  }

  onDrop = (dropzoneId, droppableId) => {
    this.setState((prevState) => ({ drops: { ...prevState.drops, [dropzoneId]: droppableId } }));
  };

  reset = () => {
    this.setState({ drops: {} });
  };
}

class Demo3 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { drops: [] };
  }

  render() {
    return (
      <div className="row">
        <div className="droppables">
          {this.renderDroppable("A")}
          {this.renderDroppable("B")}
        </div>
        <div className="dropzones">
          <Dropzone
            group="demo3"
            hasDropped={this.state.drops.length >= 1}
            renderEmptyLayer={createRenderPlaceholder("Any")}
            renderForegroundLayer={this.renderDroppedItems}
            onDrop={this.onDrop}
          />
        </div>
        <Button className="reset" onClick={this.reset}>
          <i className="fas fa-undo-alt" />
        </Button>
      </div>
    );
  }

  renderDroppable(id) {
    return (
      <Droppable
        id={id}
        group="demo3"
        hasDropped={this.state.drops.includes(id)}
        renderForegroundLayer={createRenderItem(id)}
      />
    );
  }

  renderDroppedItems = () => {
    return (
      <div className="dropped-items">
        {_.map(this.state.drops, (i) => (
          <div key={i} className="dropped-item">
            {i}
          </div>
        ))}
      </div>
    );
  };

  onDrop = (dropzoneId, droppableId) => {
    this.setState((prevState) => ({ drops: [...prevState.drops, droppableId] }));
  };

  reset = () => {
    this.setState({ drops: [] });
  };
}

class Demo4 extends PureComponent {
  static defaultState = { drops: [[], []] };

  constructor(props) {
    super(props);
    this.state = Demo4.defaultState;
  }

  render() {
    return (
      <div className="row demo4">
        <div className="droppables">
          {this.renderDroppable("A")}
          {this.renderDroppable("B")}
        </div>
        <div className="dropzones">
          {this.renderDropzone(0)}
          {this.renderDropzone(1)}
        </div>
        <Button className="reset" onClick={this.reset}>
          <i className="fas fa-undo-alt" />
        </Button>
      </div>
    );
  }

  renderDroppable(id) {
    return <Droppable id={id} group="demo3" hasDropped={false} renderForegroundLayer={createRenderItem(id)} />;
  }

  renderDropzone = (index) => {
    return (
      <Dropzone
        group="demo3"
        id={index}
        hasDropped={this.state.drops[index].length > 0}
        renderEmptyLayer={createRenderPlaceholder("Any")}
        renderForegroundLayer={this.renderDroppedItems(index)}
        onDrop={this.onDrop}
      />
    );
  };

  renderDroppedItems = (index) => () => {
    return (
      <div className="dropped-items">
        {_.map(this.state.drops[index], (id, index) => (
          <div key={index} className="dropped-item">
            {id}
          </div>
        ))}
      </div>
    );
  };

  onDrop = (dropzoneId, droppableId) => {
    this.setState((prevState) => {
      const newDrops = _.cloneDeep(prevState.drops);
      if (newDrops[dropzoneId].length < 12) newDrops[dropzoneId].push(droppableId);
      return { drops: newDrops };
    });
  };

  reset = () => {
    this.setState(Demo4.defaultState);
  };
}

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="demo drop ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          <Scroller
            className="page"
            innerClassName="page"
            startFadeRatio={0.1}
            endFadeRatio={0.04}
            scrollbarSideInset={3}
          >
            <Text className="title">Drop</Text>
            <p className="standard description">
              Dropzone usage scenarios include but are <i>not limited to the cases below</i>. There are lots of
              possibilities because the &quot;dropped&quot; state is not maintainted by the dropzones themselves but
              rather by their parent component.
            </p>
            <div className="cases">
              <div className="case">
                <h2 className="standard">Case 1: Dropzones that accept only specific items</h2>
                <Demo1 />
              </div>
              <div className="case">
                <h2 className="standard">Case 2: Dropzones that accept anything</h2>
                <Demo2 />
              </div>
              <div className="case">
                <h2 className="standard">Case 3: A dropzone that accepts anything, with custom rendering</h2>
                <Demo3 />
              </div>
              <div className="case">
                <h2 className="standard">Case 4: Buckets that accumulate whatever is dropped inside them</h2>
                <Demo4 />
              </div>
            </div>
          </Scroller>
          {Env.isRCC && <DemoBackButton />}
        </SafeArea>
        <DragPreview render={this.renderDragPreview} />
      </Page>
    );
  }

  renderDragPreview(id) {
    return createRenderItem(id, true)();
  }
}

export default PageComponent;
