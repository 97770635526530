import Random from "../../../helpers/random";

const ToastActions = {
  SHOW_TOAST: "@@local/SHOW_TOAST",
  HIDE_TOAST: "@@local/HIDE_TOAST",
  notify: function (message, level = "info", duration) {
    return function (dispatch) {
      const uniqueId = Random.identifier();
      dispatch({ type: ToastActions.SHOW_TOAST, toast: { id: uniqueId, message: message, level: level } });
      setTimeout(() => dispatch({ type: ToastActions.HIDE_TOAST, id: uniqueId }), duration);
    };
  },
};

export default ToastActions;
