import { useEffect } from "react";
import GPIO from "../../helpers/gpio";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Register a GPIO toggle switch that triggers each time the switch state changes.
 * Ideally, the callback must be memoized to avoid needless re-subscriptions at each render.
 */
export const useToggleSwitch = (description, pinNumber, onStateChange) => {
  const mostRecentCallbackRef = useLatestRef(onStateChange);

  useEffect(() => {
    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    const subscription = GPIO.toggleSwitchSubscribe(pinNumber, GPIO.Pin.NORMALLY_HIGH, mostRecentCallback);
    return () => GPIO.toggleSwitchUnsubscribe(subscription);
  }, [description, pinNumber, mostRecentCallbackRef]);
};
