import { memo } from "react";

import Page from "../../components/page";
import Strings from "../../../helpers/strings";

const PageComponent = memo(() => {
  return (
    <Page className="analytics-home ripple-dashboard" pauseTimeout="reset">
      <h1>{Strings.localized("AnalyticsHomeTitle").value}</h1>
      <p>{Strings.localized("AnalyticsHomeMessage").value}</p>
    </Page>
  );
});

export default PageComponent;
