import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import ShortcutsActions from "../redux/actions/local/shortcuts-actions";
import Mousetrap from "mousetrap";

function mousetrapKeys(keyEquivalent) {
  // Support multiple shortcuts at the source
  if (keyEquivalent instanceof Array) return _.flatMap(keyEquivalent, (ke) => mousetrapKeys(ke));

  const ctrlcmdRegex = /(ctrl\|cmd)|(cmd\|ctrl)/;
  if (ctrlcmdRegex.test(keyEquivalent)) {
    return [keyEquivalent.replace(ctrlcmdRegex, "ctrl"), keyEquivalent.replace(ctrlcmdRegex, "command")];
  }
  return [keyEquivalent];
}

export default class Keyboard {
  static _store = null;
  static _shortcuts = {};

  static shortcutSubscribe(description, keyEquivalent, handler, { priority = 0, type: type = "custom" } = {}) {
    if (typeof keyEquivalent !== "string" && !(keyEquivalent instanceof Array))
      throw new Error("Only strings or arrays of strings are supported as key equivalents");

    const subscriptionId = uuidv4();

    const existingShortcut = Keyboard._shortcuts[keyEquivalent];

    if (!existingShortcut) {
      const keys = mousetrapKeys(keyEquivalent);
      Mousetrap.bind(keys, () => {
        const shortcut = Keyboard._shortcuts[keyEquivalent];
        if (!shortcut) return; // This can happen because the actual unsubscribe occurs asynchronously
        const maxPriority = _.max(_.map(shortcut.callbacks, (c) => c.priority));
        const maxPriorityCallbacks = _.filter(shortcut.callbacks, (c) => c.priority === maxPriority);

        _.each(maxPriorityCallbacks /* MaxPriorityCallbacks */, (callback) => {
          // In the rare case where one callback unmounts a component that also
          // has a callback registered for the same shortcut, the callbacks array can
          // be modified during iteration, resulting in an undefined callback here.
          // Just ignore the callback if it was unsubscribed while we were iterating.
          if (callback) callback.handler();
        });

        return false; // Prevent default browser behaviour
      });

      Keyboard._shortcuts[keyEquivalent] = {
        callbacks: { [subscriptionId]: { handler, priority, description } },
      };
    } else {
      existingShortcut.callbacks[subscriptionId] = { handler, priority };
    }

    // Display the shortcut in the I/O debug panel
    Keyboard._store.dispatch(ShortcutsActions.addShortcut(subscriptionId, keyEquivalent, type, description, handler));

    return { keyEquivalent, subscriptionId };
  }

  static shortcutUnsubscribe(subscription) {
    if (!subscription) return;

    const existingShortcut = Keyboard._shortcuts[subscription.keyEquivalent];
    if (!existingShortcut) return;

    delete existingShortcut.callbacks[subscription.subscriptionId];

    if (Object.keys(existingShortcut.callbacks).length === 0) {
      Mousetrap.unbind(mousetrapKeys(subscription.keyEquivalent));
      delete Keyboard._shortcuts[subscription.keyEquivalent];
    }

    // Remove the shortcut from the I/O debug panel
    Keyboard._store.dispatch(ShortcutsActions.removeShortcut(subscription.subscriptionId));
  }

  static trigger(keyEquivalent) {
    Mousetrap.trigger(keyEquivalent);
  }
}

window.ripple = { ...(window.ripple || {}), keyboard: Keyboard };
