import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import Impetus from "impetus";
import Classes from "../../../helpers/classes";
import Styles from "../../../helpers/styles";
import Props from "../../../helpers/props";
import Env from "../../../helpers/env";

class Scroller extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string, // Allows distinguishing the inner divs of embedded scrollers in CSS
    style: PropTypes.object,
    orientation: PropTypes.oneOf(["horizontal", "vertical"]),
    fadeRatio: PropTypes.number, // The percentage of total length where the content fades in and out (can be overriden with more specific fade props below)
    startFadeRatio: PropTypes.number, // The percentage of total length where the content fades in (from left or top, depending on orientation)
    endFadeRatio: PropTypes.number, // The percentage of total length where the content fades out (from right or bottom, depending on orientation)¸
    showScrollbar: PropTypes.bool,
    scrollbarPosition: PropTypes.oneOf(["start", "end"]), // "start" : either top or left, "end" : either bottom or right (depending on orientation)
    scrollbarInset: PropTypes.number, // Offsets the position of the scrollbar inwards in all directions (specific insets can be overriden with the props below)
    scrollbarSideInset: PropTypes.number, // Offsets the position of the scrollbar sideways (inwards) by X pixels
    scrollbarStartInset: PropTypes.number, // Offsets the start of the scrollbar by X pixels inwards
    scrollbarEndInset: PropTypes.number, // Offsets the end of the scrollbar by X pixels inwards
    scrollbarAutoHide: PropTypes.bool, // Whether to auto-hide the scrollbar shortly after scrolling
    scrollbarAutoHideDelay: PropTypes.number, // The delay after a scroll after which the scroll bar will be auto-hidden (if enabled)
    disablePropagation: PropTypes.bool, // When this is set to true, touching inside a child scroller will disable scrolling of the parent scroller. When set to false, both scrollers will be scrollable at the same time.
    stopPropagationOnScroll: PropTypes.bool, // Whether to stop propagating scroll events to parent scrollers after scrolling starts (respects `startThreshold`)
    startThreshold: PropTypes.number, // The amount of pixels of drag, in the specified orientation, before which the scroller will start scrolling with the pointer
    onScrollStart: PropTypes.func, // Called when the user starts scrolling
    onScroll: PropTypes.func, // Called each time the scroller's position updates
    onScrollEnd: PropTypes.func, // Called when the user releases the scroller (before the scroll momentum ends)
  };

  static defaultProps = {
    orientation: "vertical",
    fadeRatio: 0,
    showScrollbar: true,
    scrollbarPosition: "end",
    scrollbarInset: 0,
    scrollbarAutoHide: Env.isMobile ? true : false,
    scrollbarAutoHideDelay: 1000,
    disablePropagation: false,
    stopPropagationOnScroll: false,
    startThreshold: 0,
  };

  constructor(props) {
    super(props);

    this.element = createRef();
    this.scrollable = createRef();
    this.scrollbar = createRef();
    this.trackContents = createRef();
    this.thumbContainer = createRef();

    this.state = { hideScrollbar: false };

    this.updateOrientation();
  }

  updateOrientation = () => {
    const vertical = this.isVertical();
    const scrollbarAtStart = this.props.scrollbarPosition === "start";

    this.startLenghtCssProperty = vertical ? "top" : "left";
    this.startBreadthCssProperty = vertical ? "left" : "top";
    this.startMarginCssProperty = vertical ? "marginTop" : "marginLeft";
    this.startPaddingCssProperty = vertical ? "paddingTop" : "paddingLeft";
    this.endLengthCssProperty = vertical ? "bottom" : "right";
    this.endBreadthCssProperty = vertical ? "right" : "bottom";
    this.endMarginCssProperty = vertical ? "marginBottom" : "marginRight";
    this.endPaddingCssProperty = vertical ? "paddingBottom" : "paddingRight";
    this.breadthCssProperty = vertical ? "width" : "height";
    this.minBreadthCssProperty = vertical ? "minWidth" : "minHeight";
    this.lengthCssProperty = vertical ? "height" : "width";
    this.positionOnBreadthCssProperty = scrollbarAtStart ? this.startBreadthCssProperty : this.endBreadthCssProperty;
    this.positionOnLengthCssProperty = scrollbarAtStart ? this.startLenghtCssProperty : this.endLengthCssProperty;
  };

  render() {
    const className = Classes.build("ripple-scroller", "debug-show-bounds", this.props.className);

    const orientations = {
      vertical: { overflow: "Y", perpendicularOverflow: "X", gradientStart: "top" },
      horizontal: { overflow: "X", perpendicularOverflow: "Y", gradientStart: "left" },
    };

    const orientation = orientations[this.props.orientation];

    const startFadeRatio = Math.round((this.props.startFadeRatio || this.props.fadeRatio) * 100.0) + "%";
    const endFadeRatio = Math.round(100.0 - (this.props.endFadeRatio || this.props.fadeRatio) * 100.0) + "%";

    const innerStyle = {
      position: "relative",
      width: "100%",
      height: "100%",
    };

    // Note: Content is not absolutely positioned, so that the scroller can expand with it, avoiding weird layout issues.
    // To force the scroller to work like a scroller (!), specify fixed (or bounded) dimensions for it in CSS.
    const scrollableStyle = {
      width: "100%",
      height: "100%",
      [`overflow${orientation.overflow}`]: "auto",
      [`overflow${orientation.perpendicularOverflow}`]: "hidden",
    };

    if (startFadeRatio !== "0%" && endFadeRatio !== "0%") {
      scrollableStyle.WebkitMaskImage = `-webkit-linear-gradient(${orientation.gradientStart}, transparent 0%, black ${startFadeRatio}, black ${endFadeRatio}, transparent 100%)`;
    }

    // Disable the browser's automatic scroll view pan in the appropriate direction.
    // If we don't do this, we stop receiving the pan events as soon as the browser's pan kicks in.
    // NOTE: We implement scrolling manually so that it works in multi-touch (multiple scrollers being manipulated at the same time)
    scrollableStyle["touchAction"] = this.isVertical() ? "pan-x" : "pan-y";

    const contentStyle = {
      [this.breadthCssProperty]: "100%",
    };

    const scrollbarStyle = {
      display: this.props.showScrollbar ? "block" : "none",
      position: "absolute",
      opacity: this.state.hideScrollbar ? 0 : 1,
      transition: "opacity 200ms ease-in-out",
      [this.lengthCssProperty]: "100%",
      [this.positionOnLengthCssProperty]: 0,
      [this.positionOnBreadthCssProperty]: this.props.scrollbarSideInset || this.props.scrollbarInset,
      [this.startPaddingCssProperty]: this.props.scrollbarStartInset || this.props.scrollbarInset,
      [this.endPaddingCssProperty]: this.props.scrollbarEndInset || this.props.scrollbarInset,
    };

    const trackStyle = {
      position: "relative",
      display: "flex",
      flexDirection: this.isVertical() ? "column" : "row",
      justifyContent: "stretch",
      alignItems: "stretch",
      [this.lengthCssProperty]: "100%",
      [this.minBreadthCssProperty]: "2px",
    };

    const trackContentsStyle = {
      flexGrow: 1,
      display: "flex",
      flexDirection: this.isVertical() ? "column" : "row",
      alignItems: "stretch",
    };

    const thumbContainerStyle = {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "stretch",
    };

    const thumbStyle = {
      flexGrow: 1,
    };

    return (
      <div ref={this.element} className={className} style={Styles.merge({ overflow: "hidden" }, this.props.style)}>
        <div style={innerStyle}>
          <div ref={this.scrollable} style={scrollableStyle} onScroll={this.onScroll} onWheel={this.onWheel}>
            <div className={Classes.build("scroller-content", this.props.innerClassName)} style={contentStyle}>
              {this.props.children}
            </div>
          </div>
          <div ref={this.scrollbar} style={scrollbarStyle}>
            <div className={Classes.build("scroller-track", this.props.innerClassName)} style={trackStyle}>
              <div ref={this.trackContents} style={trackContentsStyle}>
                <div ref={this.thumbContainer} style={thumbContainerStyle}>
                  <div className={Classes.build("scroller-thumb", this.props.innerClassName)} style={thumbStyle} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // Ensure the track visibility is updated when the scroller content loads
    // (such as images and any other collapsing element). We really have no choice because
    // we can only watch for scroll height changes by polling.
    this.updatePeriodically();

    // Auto-hide the scrollbar for the first time (scroll bar appears momentarily on mount)
    this.revealAndAutoHideScrollbar();

    // Store the initial scroll height to avoid systematically
    // triggering scroll-to-top on first update
    this.previousScrollableContentLength = this.getScrollableContentLength();
  }

  componentDidUpdate(prevProps) {
    this.updateOrientation();

    const scrollable = this.scrollable.current;
    const areChildrenEqual = Props.areImmediateChildrenEqual(this.props.children, prevProps.children);

    // Auto-scroll to top in certain conditions
    if (!areChildrenEqual && this.previousScrollableContentLength !== this.getScrollableContentLength()) {
      this.setScroll(0);
      this.previousScrollableContentLength = scrollable.scrollHeight;
    }
  }

  componentWillUnmount() {
    clearInterval(this.periodicUpdateToken);
    clearTimeout(this.autoHideScrollbarTimeout);
    this.unmounting = true;
    this.destroyImpetus();
  }

  updatePeriodically = () => {
    this.update();
    clearInterval(this.periodicUpdateToken);
    this.periodicUpdateToken = setInterval(() => this.update(), 100);
  };

  update = () => {
    this.updateScrollbar();

    // Only reset impetus if the max scroll changed (avoids jumps when
    // periodically updating while content loads).
    const newMaxScroll = this.getMaxScroll();
    if (newMaxScroll !== this.previousMaxScroll) {
      this.previousMaxScroll = newMaxScroll;
      this.resetImpetus();
    }
  };

  updateScrollbar = () => {
    const scrollable = this.scrollable.current;
    const scrollbar = this.scrollbar.current;
    const trackContents = this.trackContents.current;
    const thumbContainer = this.thumbContainer.current;
    const verticalOrientation = this.isVertical();

    const scrollableContentLength = this.getScrollableContentLength();
    const scrollerLength = this.getScrollerLength();
    const trackLength = verticalOrientation ? trackContents.offsetHeight : trackContents.offsetWidth;
    const scroll = this.isVertical() ? scrollable.scrollTop : scrollable.scrollLeft;

    const scrollRatio = scroll / this.getMaxScroll();
    const thumbLength = (scrollerLength / scrollableContentLength) * trackLength;
    const thumbOffset = (trackLength - thumbLength) * scrollRatio;

    scrollbar.style.visibility = scrollableContentLength > scrollerLength ? "visible" : "hidden";

    thumbContainer.style[this.startMarginCssProperty] = thumbOffset + "px";
    thumbContainer.style[this.lengthCssProperty] = thumbLength + "px";
  };

  isVertical = () => {
    return this.props.orientation === "vertical";
  };

  getScrollableContentLength = () => {
    const scrollable = this.scrollable.current;
    return this.isVertical() ? scrollable.scrollHeight : scrollable.scrollWidth;
  };

  getScrollerLength = () => {
    const scrollable = this.scrollable.current;
    return this.isVertical() ? scrollable.offsetHeight : scrollable.offsetWidth;
  };

  getMaxScroll = () => {
    return this.getScrollableContentLength() - this.getScrollerLength();
  };

  getScroll = () => {
    const scrollable = this.scrollable.current;
    return this.isVertical() ? scrollable.scrollTop : scrollable.scrollLeft;
  };

  setScroll = (scroll) => {
    const scrollable = this.scrollable.current;
    this.isVertical() ? (scrollable.scrollTop = scroll) : (scrollable.scrollLeft = scroll);
  };

  // Auto-hide

  revealAndAutoHideScrollbar = () => {
    if (!this.props.scrollbarAutoHide) return;

    this.setState({ hideScrollbar: false });
    clearTimeout(this.autoHideScrollbarTimeout);
    this.autoHideScrollbarTimeout = setTimeout(
      () => this.setState({ hideScrollbar: true }),
      this.props.scrollbarAutoHideDelay
    );
  };

  // Touch Controls

  resetImpetus = () => {
    this.destroyImpetus();

    const vertical = this.isVertical();
    const maxX = vertical ? 0 : this.getMaxScroll();
    const maxY = vertical ? this.getMaxScroll() : 0;
    const maxValue = vertical ? maxY : maxX;
    const initialX = vertical ? 0 : maxValue - this.getScroll();
    const initialY = vertical ? maxValue - this.getScroll() : 0;

    let scrollOnTouchDown;
    this.impetus = new Impetus({
      source: this.element.current,
      initialValues: [initialX, initialY],
      boundX: [0, maxX],
      boundY: [0, maxY],
      bounce: false,
      disablePropagation: this.props.disablePropagation,
      startThreshold: { x: vertical ? 0 : this.props.startThreshold, y: vertical ? this.props.startThreshold : 0 },
      down: () => {
        scrollOnTouchDown = this.getScroll();
        if (this.props.onScrollStart) this.props.onScrollStart();
      },
      update: (x, y) => {
        const rawValue = vertical ? y : x;
        const inverted = Math.abs(rawValue - maxValue);

        // Always set the visible scroll when there is an update
        if (!this.unmounting) this.setScroll(inverted);

        const scrollDistance = Math.abs(inverted - scrollOnTouchDown);
        if (this.props.stopPropagationOnScroll && scrollDistance > this.props.startThreshold)
          this.impetus.forceStopPropagation();

        if (this.props.onScroll) this.props.onScroll(this.getScroll());
      },
      up: () => {
        if (this.props.onScrollEnd) this.props.onScrollEnd();
      },
    });
  };

  destroyImpetus = () => {
    if (this.impetus) this.impetus.destroy();
  };

  // Fires when modifying scrollTop or scrollLeft
  onScroll = (event) => {
    this.updateScrollbar();
    this.revealAndAutoHideScrollbar();
  };

  // Fires when using an actual scroll wheel (or trackpad scroll)
  // instead of pointer-dragging with impetus. As soon as we use
  // the mouse wheel, we cancel whatever impetus we have and let
  // the wheel take the wheel...
  onWheel = (event) => {
    this.resetImpetus();
  };
}

export default Scroller;
