import { useEffect, useContext } from "react";
import { useLatestRef } from "./internal/use-latest-ref";
import RippleContext from "../../core/ripple-context";

/**
 * Runs a callback each time an IPC action is received.
 * Use this to react to discrete actions sent by other IPC clients.
 * */
export const useIPCAction = (type = null, callback) => {
  const context = useContext(RippleContext);
  const mostRecentCallbackRef = useLatestRef(callback);

  useEffect(() => {
    const subscription = context.observables.ipc.actions$.subscribe((action) => {
      if (action.type !== type) return;
      const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
      mostRecentCallback(action);
    });

    return () => subscription.unsubscribe();
  }, [type, context, mostRecentCallbackRef]);
};
