import LanguageActions from "../../actions/local/language-actions";
import Localization from "../../../helpers/localization";

const languageReducer = function (state = Localization.getDefaultLanguage(), action) {
  switch (action.type) {
    case LanguageActions.LANGUAGE_CHANGE:
      return action.language;
    default:
      return state;
  }
};

export default languageReducer;
