import { PureComponent } from "react";

import Page from "../../components/page";
import Button from "../../components/button";
import Env from "../../../helpers/env";
import DemoBackButton from "../demos/components/demo-back-button";
import SafeArea from "../../components/safe-area";
import Paths from "../../../core/paths";

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="demos ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          {Env.isRCC && <DemoBackButton />}
          <h1>Demos</h1>
          <p>
            These demonstrate some of the more complex Ripple components and serve as a testing area to ensure
            everything keeps working as expected over time.
          </p>
          <div className="row">
            <Button className="standard" navigate={{ path: Paths.corePaths.transitionsDemoPath }}>
              <span className="icon">🎥</span>&nbsp;Transitions
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.staggerDemoPath }}>
              <span className="icon">🎞</span>&nbsp;Stagger
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.audioDemoPath }}>
              <span className="icon">🎧</span>&nbsp;Audio
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.videoDemoPath }}>
              <span className="icon">🎬</span>&nbsp;Video
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.textDemoPath }}>
              <span className="icon">✏</span>&nbsp;Text
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.gridDemoPath }}>
              <span className="icon">🁒</span>&nbsp;Grid
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.selectorDemoPath }}>
              <span className="icon">📗</span>&nbsp;Selector
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.maximizerDemoPath }}>
              <span className="icon">🖥</span>&nbsp;Maximizer
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.dropDemoPath }}>
              <span className="icon drop">❐</span>&nbsp;&nbsp;Drop
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.scrollerDemoPath }}>
              <span className="icon">📜</span>&nbsp;Scroller
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.tiledMapDemoPath }}>
              <span className="icon">🗺</span>&nbsp;Tiled Map
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.panoramaDemoPath }}>
              <span className="icon">⚫️</span>&nbsp;Panorama
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.filePickerDemoPath }}>
              <span className="icon">📁</span>&nbsp;File Picker
            </Button>
            <Button className="standard" navigate={{ path: Paths.corePaths.mailDemoPath }}>
              <span className="icon">✉️</span>&nbsp;Mail
            </Button>
            <Button
              disabled={Env.isRCC /* Unfortunately getUserMedia is not supported in WKWebView... :( */}
              className="standard"
              navigate={{ path: Paths.corePaths.cameraDemoPath }}
            >
              <span className="icon">📸</span>&nbsp;Camera
            </Button>
            <Button
              disabled={!Env.isDesktop}
              className="standard"
              navigate={{ path: Paths.corePaths.virtualKeyboardDemoPath }}
            >
              <span className="icon">⌨️</span>&nbsp;Virtual Keyboard
            </Button>
            <Button disabled={!Env.isDesktop} className="standard" navigate={{ path: Paths.corePaths.gpioDemoPath }}>
              <span className="icon">⚡️</span>&nbsp;GPIO
            </Button>
            <Button disabled={!Env.isRCC} className="standard" navigate={{ path: Paths.corePaths.beaconsDemoPath }}>
              <span className="icon">📻</span>&nbsp;Beacons
            </Button>
            <Button disabled={!Env.isREC} className="standard" navigate={{ path: Paths.corePaths.apiDemoPath }}>
              <span className="icon">☁️</span>&nbsp;HTTP API
            </Button>
          </div>
        </SafeArea>
      </Page>
    );
  }
}

export default PageComponent;
