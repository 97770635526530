import _ from "lodash";
import ToastActions from "../../actions/local/toast-actions";

const toastReducer = function (state = [], action) {
  switch (action.type) {
    case ToastActions.SHOW_TOAST:
      return state.concat(action.toast);
    case ToastActions.HIDE_TOAST:
      return _.filter(state, (t) => t.id !== action.id);
    default:
      return state;
  }
};

export default toastReducer;
