import { memo, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { VideoTexture } from "@babylonjs/core/Materials/Textures/videoTexture";
import { SphereBuilder } from "@babylonjs/core/Meshes/Builders/sphereBuilder";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Color3 } from "@babylonjs/core/Maths/math.color";

import Classes from "../../../helpers/classes";
import Canvas from "../canvas";
import getSrcType from "../../../helpers/internal/get-src-type";
import { MediaSrcPropType } from "../../../logic/prop-types";
import MediaInfo from "../../../logic/info/media-info";

function createImageTexture(scene, src) {
  return new Texture(src, scene, true);
}

function createVideoTexture(scene, src) {
  return new VideoTexture("video", src, scene);
}

const Panorama = memo(({ className, src, muted, ...rest }) => {
  const canvasRef = useRef(null);
  const sceneRef = useRef(null);

  const [video, setVideo] = useState(null);

  // Engine and scene
  useEffect(() => {
    const canvasElement = canvasRef.current.getCanvasElement();
    const engine = new Engine(canvasElement, true, { preserveDrawingBuffer: true, stencil: true });

    const scene = new Scene(engine);
    scene.clearColor = Color3.Black();

    const camera = new ArcRotateCamera("camera", Math.PI, Math.PI / 2.0, 20, new Vector3(0, 0, 0), scene);
    camera.setTarget(Vector3.Zero());
    camera.attachControl(canvasElement, true);
    camera.lowerRadiusLimit = 0.01;
    camera.upperRadiusLimit = 50;

    // Reverse the default ArcRotateCamera input's direction to "follow the finger"
    camera.angularSensibilityX = -3000;
    camera.angularSensibilityY = -3000;

    engine.runRenderLoop(() => scene.render());

    sceneRef.current = scene;

    return () => {
      scene.dispose();
      engine.stopRenderLoop();
      engine.dispose();
    };
  }, []);

  // Skybox
  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene) return;

    const actualSrc = src instanceof MediaInfo ? src.url : src;
    if (!actualSrc) return;

    const type = src instanceof MediaInfo ? src.type.toLowerCase() : getSrcType(src);
    if (!type) return; // Can happen if the src is null, for example

    const texture = type === "image" ? createImageTexture(scene, actualSrc) : createVideoTexture(scene, actualSrc);
    if (texture instanceof VideoTexture) setVideo(texture.video);
    texture.coordinatesMode = Texture.FIXED_EQUIRECTANGULAR_MIRRORED_MODE;

    const material = new StandardMaterial("skybox", scene);
    material.backFaceCulling = false;
    material.reflectionTexture = texture;
    material.disableLighting = true;

    const skybox = SphereBuilder.CreateSphere("skybox", { segments: 10, diameter: 100.0 }, scene);
    skybox.material = material;

    return () => {
      setVideo(null);
      skybox.dispose();
      material.dispose();
      texture.dispose();
    };
  }, [src]);

  // Video muting
  useEffect(() => {
    if (!video) return;
    video.muted = muted;
  }, [video, muted]);

  return (
    <div {...rest} className={Classes.build("ripple-panorama", className)}>
      <Canvas ref={canvasRef} />
    </div>
  );
});

Panorama.propTypes = {
  className: PropTypes.string,
  src: MediaSrcPropType,
  muted: PropTypes.bool,
};

export default Panorama;
