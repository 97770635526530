import _ from "lodash";
import { useLoader } from "react-three-fiber";
import * as THREE from "three";
import { TextureLoader } from "three";

export const useTextures = (urls) => {
  const textures = useLoader(TextureLoader, urls);
  _.each(textures, (t) => {
    // Necessary to prevent color artifacts at intersection
    // between opaque and transparent pixels
    t.magFilter = THREE.NearestFilter;
    t.minFilter = THREE.NearestFilter;
  });
  return textures;
};
