import IpcClient from "ripple.ipc-client";
import Config from "./config";
import Log from "./log";

/** Following a weird (and quite hard to track!) bug where remote state
 * update was performed in the wrong order, it was observed that, somehow,
 * emits seem to be performed synchronously when the client and server reside
 * in the same app (which is the case for the REC IPC master). This meant
 * that any IPC emits performed as side-effects in shared store epics would
 * be performed before the initial actions that caused the epic to run had a
 * chance to update the remote state themselves. In practice, this resulted
 * in the remote state being updated in the wrong order. This is especially
 * bad if performing a state restore in an epic because the restored state
 * was "overwritten" with old state. Ensuring that IPC actions are emitted
 * asynchronously like this makes the IPC action-receiving code in app
 * bootstrap run fully before the IPC action is dispatched, which restores
 * the expected remote state update operation ordering.
 *
 * Note that we're doing this in the IPC helper because it only makes sense to
 * do this for emits which originate from an epic (which should always be
 * performed through this helper). */
function performAsynchronously(func) {
  setTimeout(func, 1 /* Yes, 1ms! */);
}

function ipcDisabled() {
  if (!Config.ipc.enabled) {
    Log.warn("IPC operations are no-ops when IPC is disabled");
    return true;
  }
  return false;
}

export default class IPC {
  /** Dispatch an IPC action that will potentially affect the shared state
   * and other connected instances.
   */
  static dispatch(action) {
    if (ipcDisabled()) return;
    Log.info("Dispatching IPC action", action);
    IpcClient.dispatch(action);
  }

  /** Call this to undo the last remote state update, in essence resetting all
   * clients to the previous remote state.
   */
  static undoLastRemoteStateUpdate() {
    if (ipcDisabled()) return;
    Log.info("Undoing last remote state update");
    IpcClient.undoLastRemoteStateUpdate();
  }

  /** Reset the remote state of all clients to the first state in the history. */
  static resetToInitialRemoteState() {
    if (ipcDisabled()) return;
    Log.info("Resetting to first remote state");
    performAsynchronously(() => IpcClient.resetToInitialRemoteState());
  }

  /** Call this to indicate that undo history should be cleared now. Use this to
   * indicate that apps should not be able to undo remote state past this point.
   * A concrete example would be to prevent apps from going back to a state
   * where the master didn't know about individual instances (before they sent
   * "connect" actions to add themselves to the shared state).
   * */
  static clearRemoteStateHistory() {
    if (ipcDisabled()) return;
    Log.info("Clearing remote state history");
    performAsynchronously(() => IpcClient.clearRemoteStateHistory());
  }
}
