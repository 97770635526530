import Rect from "../../../../types/rect";
import Point from "../../../../types/point";

export default class DecorationHelper {
  static toAbsolutePosition(normalizedPosition, containerSize) {
    const containerRect = new Rect(0, 0, containerSize.width, containerSize.height);
    return normalizedPosition.convertNormalizedToAbsoluteIn(containerRect).minus(containerSize.scaledBy(0.5));
  }

  static toAbsoluteFrame(normalizedFrame, containerSize) {
    const containerRect = new Rect(0, 0, containerSize.width, containerSize.height);
    return normalizedFrame.convertNormalizedToAbsoluteIn(containerRect).offsetBy(containerSize.scaledBy(0.5).negated());
  }

  static toNormalizedPosition(absolutePosition, containerSize) {
    const containerRect = new Rect(0, 0, containerSize.width, containerSize.height);
    return absolutePosition.convertAbsoluteToNormalizedIn(
      containerRect.offsetBy(containerSize.scaledBy(0.5).negated())
    );
  }

  static configureClickHandler(sprite, action, altAction = () => {}) {
    let downPoint = null;

    sprite.interactive = true;

    sprite.on("pointerdown", (event) => {
      downPoint = new Point(event.data.global.x, event.data.global.y);
    });

    sprite.on("pointerup", (event) => {
      const upPoint = new Point(event.data.global.x, event.data.global.y);
      if (downPoint && downPoint.distanceTo(upPoint) < 20) {
        if (event.data.originalEvent.altKey) {
          altAction();
        } else {
          action();
        }
      }
    });
  }
}
