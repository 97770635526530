import { PureComponent } from "react";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import VideoPlayer from "../../../components/video-player";
import resource from "../../../../helpers/resource";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="demo video ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          <Scroller
            className="page"
            innerClassName="page"
            startFadeRatio={0.1}
            endFadeRatio={0.04}
            scrollbarSideInset={3}
          >
            <Text className="title">Video</Text>
            <p className="standard description">
              The <code>VideoPlayer</code> component is a fully styleable video player with controls and time display.
              It posesses all of the capabilities of the <code>Video</code> component, including scaling modes,
              auto-play, looping and more. Keep in mind that the examples below demonstrate only <i>some</i> of the
              possible setting combinations.
            </p>
            <div className="cases">
              <div className="case">
                <h2 className="standard">Default Style</h2>
                <p className="standard">
                  This is how the component appears with it&apos;s default styles. Like <code>Video</code>, it supports
                  displaying a still frame before the video plays.
                </p>
                <VideoPlayer
                  src={resource("video/demo/video.mp4")}
                  thumbnail={resource("images/demo/video-still.jpg")}
                  scaling="fill"
                />
              </div>
              <div className="case">
                <h2 className="standard">Hidden Controls</h2>
                <p className="standard">
                  Controls can be hidden individually for a simpler appearance or to prevent the user from controlling
                  playback. In addition, seeking can be disabled while still displaying progress.
                </p>
                <VideoPlayer
                  className="hidden-controls"
                  src={resource("video/demo/video.mp4")}
                  showPlayButton={false}
                  showTimeLabel={false}
                  enableSeeking={false}
                  showExtraButton={true}
                  onExtraButtonClick={this.onExtraButtonClick}
                  autoPlay
                  loop
                />
              </div>
              <div className="case">
                <h2 className="standard">Paused Overlay</h2>
                <p className="standard">
                  This demonstrates hidden controls and the default paused overlay. You can customize the default paused
                  overlay using styles or replace it using the <code>renderPausedOverlay</code> render prop.
                </p>
                <VideoPlayer
                  src={resource("video/demo/video.mp4")}
                  scaling="fill"
                  controlsMode="visible-during-playback"
                  showPlayButton={false}
                  showPausedOverlay={true}
                />
              </div>
              <div className="case">
                <h2 className="standard">Custom Style</h2>
                <p className="standard">
                  This demonstrates the kind of customization that can be performed through styles and props, without
                  any modification to the component itself. Buttons use icon fonts (FontAwesome by default).
                </p>
                <VideoPlayer
                  className="custom-style"
                  src={resource("video/demo/video.mp4")}
                  scaling="fill"
                  controlsPosition="top"
                />
              </div>
              <div className="case">
                <h2 className="standard">Auto-Hide Controls</h2>
                <p className="standard">
                  Controls can be set to auto-hide themselves after a fixed duration when the video is playing, much
                  like the video controls on mobile devices.
                </p>
                <VideoPlayer src={resource("video/demo/video.mp4")} controlsMode="auto-hide" scaling="fill" />
              </div>
            </div>
          </Scroller>
          {Env.isRCC && <DemoBackButton />}
        </SafeArea>
      </Page>
    );
  }

  onExtraButtonClick = () => {
    alert(
      'Extra button clicked! Put a VideoPlayer in a Maximizer and toggle the maximizer with this button to implement a "view in fullscreen" feature.'
    );
  };
}

export default PageComponent;
