import NullDecoration from "./decorations/null-decoration";
import Load from "../../../../helpers/load";
import Size from "../../../../types/size";

const ImageSource = function (identifier, imageUrl, decoration = new NullDecoration()) {
  if (!imageUrl) throw new Error("ImageSource : Image not provided!");

  this.identifier = identifier;
  this.imageUrl = imageUrl;
  this.decoration = decoration; // An object that conforms to the TiledMap decoration API
};

// Here, we provide metadata with a single level configured for a single tile (with same schema as a generated tileset's
// meta.json file). Combined with `getTileUrl` (below), this allows the tileset to be comprised of a single image.
ImageSource.prototype.getMeta = function (stageSize) {
  return Load.image(this.imageUrl).then((image) => {
    // Calculate the max zoom required to zoom the image to 1:1 resolution (pixel-perfect)
    // assuming that the root will be scaled to fill the viewport with the image.
    const imageSize = new Size(image.width / window.devicePixelRatio, image.height / window.devicePixelRatio);
    const maxZoomToReachPixelPerfect = 1 / imageSize.scaleFactorToFillProportionallyIn(stageSize);
    const maxZoom = Math.max(1, maxZoomToReachPixelPerfect);

    return {
      maxZoom: maxZoom,
      viewportSize: {
        width: image.width,
        height: image.height,
      },
      levels: [
        {
          size: {
            width: image.width,
            height: image.height,
          },
          number: 0,
          viewportScale: 1,
          tile: {
            size: {
              width: image.width,
              height: image.height,
            },
            subdivisions: {
              horizontal: 1,
              vertical: 1,
            },
          },
          sourceScale: 1,
        },
      ],
    };
  });
};

// We ignore the level, row and column because we only support a single tile
ImageSource.prototype.getTileUrl = function (level, row, column) {
  return this.imageUrl;
};

export default ImageSource;
