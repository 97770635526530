import * as PIXI from "pixi.js";
import { Promise } from "es6-promise";

import TextureLoader from "../logic/texture-loader";
import Log from "./log";

const textureLoaders = {}; // Pending loads, indexed by image URL

export default class PixiTools {
  static createSprite(imageUrl) {
    return new Promise((resolve) => {
      const resolveSprite = (texture) => resolve(new PIXI.Sprite(texture));

      // First, check if the texture has already been loaded
      const existingTexture = PIXI.utils.TextureCache[imageUrl];
      if (existingTexture) {
        resolveSprite(existingTexture);
        return;
      }

      // Check if the texture is currently loading
      const existingTextureLoader = textureLoaders[imageUrl];
      if (existingTextureLoader) {
        existingTextureLoader.callbacks.push(resolveSprite);
        return;
      }

      // Start new load
      const newTextureLoader = new TextureLoader(imageUrl, (texture) => {
        resolveSprite(texture);
        delete textureLoaders[imageUrl];
      });
      textureLoaders[imageUrl] = newTextureLoader;
      newTextureLoader.load();
    }).catch((error) => {
      Log.error(error);
    });
  }

  static createRectangle(size, color) {
    return new Promise((resolve) => {
      const graphics = new PIXI.Graphics();
      if (color) graphics.beginFill(color); // Not specifying a color results in a transparent sprite of the specified size
      graphics.drawRect(-size.width / 2, -size.height / 2, size.width, size.height);

      const sprite = new PIXI.Sprite();
      sprite.addChild(graphics);

      // We keep the same callback API as the rest of PixiTools
      // even if the code is asynchronous here.
      resolve(sprite);
    }).catch((error) => {
      Log.error(error);
    });
  }
}
