import { Subject, merge, timer } from "rxjs";
import { scan, map, tap, switchAll, bufferCount } from "rxjs/operators";
import Config from "../helpers/config";
import Log from "../helpers/log";

const TimeoutObservables = {
  paused: false,
  initialize: function (interactions$) {
    const timeoutInterval = Config.timeout.duration;

    // This observable emits for each second elapsed in the grace period, and on reset.
    const grace$ = new Subject();

    // This allows manually resetting the timeout timer without having to emit an interaction
    const manualReset$ = new Subject();

    // We use a subject for the actual reset because we must subscribe multiple times
    const reset$ = new Subject();
    reset$.subscribe(() => grace$.next(null));

    // This is the final muxing of resets. This must be only subscribed to once!
    // The timeout counter is reset both when an interaction is emitted and
    // when we manually ask for a reset to be performed
    const internalReset$ = merge(interactions$, manualReset$);
    internalReset$.subscribe((...args) => reset$.next(...args));

    // Each time an interaction value arrives, create a new timer that fires after timeoutInterval.
    // If the timeout interval is zero or timeout is paused, we return an observable
    // that we'll never trigger instead. This has the effect of disabling timeout completely.
    // Note that we use rxjs' `scan` operator to maintain some observable-local state, mostly like redux!
    // See this for details: http://rudiyardley.com/redux-single-line-of-code-rxjs/
    const initialState = { mode: "running", resetDate: null, pauseDate: null, duration: null };
    const automaticTimeout$ = reset$.pipe(
      scan((state, action) => {
        if (action === "reset-state") return initialState;

        const mode = state.mode;

        const now = new Date();
        const resetTime = (state.resetDate || now).getTime();

        if (mode === "running" && action === "pause") {
          // If we're currently running and someone asks for a timeout pause,
          // we enter paused mode and store the current pause date for later reference.
          return { ...state, mode: "paused", pauseDate: now };
        } else if (mode === "paused" && action === "resume-and-continue") {
          // When resuming, the duration is either:
          //
          // - The time that remained before timeout when timeout was paused, or
          //   the minimum duration after resume, whichever is greatest.
          //
          //   Ex: Given a timeout duration of 60 seconds and a minimum resume
          //   duration of 15s, if the user interacted 20 seconds before timeout
          //   pause, when resuming the timeout duration will be 40 seconds.
          //
          //   ---*---20s---|--------------|--------40s---------x---->
          //      ^         ^              ^                    ^
          //    reset     pause          resume              timeout
          //
          //   Ex: Given a timeout duration of 60 seconds and a minimum resume
          //   duration of 15s, if the user interacted 50 seconds before timeout
          //   pause, when resuming the timeout duration would have been `60s -
          //   50s = 10s` but in this case it will be 15s (the minimum resume
          //   duration).
          //
          //   ---*------------50s------------|--------------|-----15s-----x---->
          //      ^                           ^              ^             ^
          //    reset                       pause          resume       timeout
          //
          //   Note that this simplified behavior means that all interactions
          //   emitted during a pause are ignored; only the remaining time on
          //   pause is considered when resuming.

          const duration = (() => {
            const pauseTime = state.pauseDate.getTime();
            const calculatedTimeoutInterval = timeoutInterval - Math.min(pauseTime - resetTime, timeoutInterval);
            return Math.max(calculatedTimeoutInterval, Config.timeout.minimumDurationAfterResume);
          })();

          Log.info(`Timeout resumed by continuing with duration: ${duration}ms`);

          return {
            ...state,
            mode: "running",
            pauseDate: null,
            duration: duration,
          };
        } else if (mode === "paused" && action === "resume-and-reset") {
          Log.info(`Timeout resumed by resetting to full duration: ${timeoutInterval}ms`);

          return {
            ...state,
            mode: "running",
            pauseDate: null,
            duration: timeoutInterval,
          };
        } else if (mode === "paused") {
          // When paused, resets do not update the resetDate so that we
          // can calculate the proper duration on resume.
          return state;
        } else {
          // When running, any reset causes the timeout to be
          // reset with the full timeout duration.
          return { ...state, resetDate: now, duration: timeoutInterval };
        }
      }, initialState),
      map((state) => {
        const initial = state.duration === null;
        const paused = state.mode === "paused";
        const zeroDurationConfigured = Math.abs(timeoutInterval) <= 0.0001;
        const disabled = !Config.timeout.enabled;

        // If conditions are not met for timeout to be enabled, return
        // a Subject that we'll never emit with, in essence stopping timeout.
        if (initial || paused || disabled || zeroDurationConfigured) return new Subject();

        // Else return an observable that emits after the specified duration
        const totalSeconds = Math.round(state.duration / 1000);
        const gracePeriodSeconds = Math.min(Math.round(Config.timeout.grace / 1000), totalSeconds);
        return timer(1000, 1000).pipe(
          map((elapsed) => totalSeconds - 1 - elapsed),
          tap((tick) => {
            if (tick <= gracePeriodSeconds) grace$.next(tick);
          }),
          bufferCount(totalSeconds), // Avoid emitting until the total number of seconds has elapsed
          map(() => ({ forced: false }))
        );
      }),
      switchAll(),
      tap(() => manualReset$.next("reset-state")) // When a timeout occurs, reset our internal state to start anew
    );

    // A timeout is emitted both when the timeout duration elapses
    // or when a timeout is manually requested. Forced timeouts should
    // ALWAYS occur regardless of "paused" status.
    const manualTimeout$ = new Subject();
    manualTimeout$.pipe(tap(() => manualReset$.next("reset-state"))); // When a manual timeout occurs, reset the automatic timeout state

    // We use a subject to avoid multiple subscriptions triggering multiple executions
    const timeout$ = new Subject();

    // This is the final muxing of timeouts. This must only be subscribed to internally, once.
    const internalTimeout$ = merge(automaticTimeout$, manualTimeout$);
    internalTimeout$.subscribe((...args) => timeout$.next(...args));

    return {
      timeout$: timeout$,
      grace$: grace$,
      forceTimeout: () => manualTimeout$.next({ forced: true }),
      pause: () => {
        manualReset$.next("pause");
      },
      resumeAndContinue: () => {
        manualReset$.next("resume-and-continue");
      },
      resumeAndReset: () => {
        manualReset$.next("resume-and-reset");
      },
    };
  },
};

export default TimeoutObservables;
