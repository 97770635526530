import _ from "lodash";

import { useDebug } from "../use-debug";
import { useEditing } from "../use-editing";
import { useConstant } from "../use-constant";

import PinsDecoration from "../../components/tiled-map/api/decorations/pins-decoration";

const NodePin = PinsDecoration.NodePin;

/**
 * This hook sets up a PinsDecoration instance with pins for each
 * children in the provided node, with debugging mode and data save support.
 * */
export const useNodePinsDecoration = (mapNode, options, pinOptions) => {
  // Get pins from the map node if provided (we assume that children are pins)
  const pins = useConstant(() => (mapNode ? _.map(mapNode.children, (node) => new NodePin(node, pinOptions)) : []));

  const ConcreteDecorationType = options.type ? options.type : PinsDecoration;
  const decoration = useConstant(() => new ConcreteDecorationType(pins, options));

  // Setup map refresh on debug mode toggle
  useDebug((debug) => {
    decoration.debug = debug;
    decoration.refresh();
  });

  // Setup map refresh on editing mode toggle
  useEditing((editing) => {
    decoration.editing = editing;
    decoration.refresh();
  });

  return decoration;
};
