import { ResizeObserver } from "@juggle/resize-observer";
import reactSpringUseMeasure from "react-use-measure";

/**
 * Just forward the react-spring useMeasure hook as-is,
 * but pre-configure the polyfill for simplicity at the point of use.
 * https://github.com/react-spring/react-use-measure
 */
export const useMeasure = (options = {}) => {
  return reactSpringUseMeasure({ ...options, polyfill: ResizeObserver });
};
