import { useEffect } from "react";

import SerialPort from "../../helpers/serial-port";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Register for data coming from a specific serial port.
 * Connects the serial port automatically on demand.
 */
export const useSerialPortData = (portName, callback) => {
  const mostRecentCallbackRef = useLatestRef(callback);

  useEffect(() => {
    const serialPort = SerialPort.get(portName);
    if (!serialPort) return;

    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    const subscription = serialPort.readSubscribe(mostRecentCallback);
    return () => serialPort.readUnsubscribe(subscription);
  }, [mostRecentCallbackRef, portName]);
};
